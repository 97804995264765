import { takeEvery, put, call, all } from "redux-saga/effects";
import apiCall from "../services";
import { saveAs } from "file-saver";

const apiUrl = process.env.REACT_APP_API_URL;

function* getAttractionsTours(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/tours?page=" + actions.page
        : apiUrl + "/tours?" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "ATTRACTIONS_TOURS_RESPONSE_GET", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTIONS_TOURS_RESPONSE_GET", value: error });
  }
}

function* attractionTour(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/tours/"
        : apiUrl + "/tours/" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "ATTRACTION_TOURS_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTION_TOURS_RESPONSE", value: error });
  }
}

function* addAttractionTours(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(apiCall, "POST", apiUrl + "/tours", requestOptions);
    yield put({ type: "ATTRACTIONS_TOURS_CREATE", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTIONS_TOURS_CREATE", value: error });
  }
}

function* editAttractionTours(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data.form)
    };

    const createComments = actions.data.comments?.create.map(comment => {
      return call(add_Comments, { access: actions.access, data: comment });
    }) || [];

    const editComments = actions.data.comments?.edit.map(comment => {
      return call(edit_Comments, { access: actions.access, commentId: comment.commentId, data: comment });
    }) || [];

    const createAudits = actions.data.audits?.map(audit => {
      return call(addAudit, { access: actions.access, data: audit });
    }) || [];

    const response = yield all([
      call(apiCall, "put", apiUrl + "/tours/" + actions.id, requestOptions),
      ...createComments,
      ...editComments,
      ...createAudits,
    ])

    

    yield put({ type: "ATTRACTIONS_TOURS_EDIT", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTIONS_TOURS_EDIT", value: error });
  }
}

function* comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/comments?objectType=tours&objectId=" + actions.objectId,
      requestOptions
    );
    yield put({ type: "COMMENTS_RESPONSE_LIST_TOURS", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_LIST_TOURS", value: error });
  }
}

function* add_Comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/comments",
      requestOptions
    );

    yield put({ type: "COMMENTS_RESPONSE_POST_TOURS", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_POST_TOURS", value: error });
  }
}

function* edit_Comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "put",
      apiUrl + "/comments/" + actions.commentId,
      requestOptions
    );

    yield put({ type: "COMMENTS_RESPONSE_POST_TOURS", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_POST_TOURS", value: error });
  }
}

function* history(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/changelogs?objectType=" +
        actions.objectType +
        "&objectId=" +
        actions.objectId,
      requestOptions
    );

    yield put({ type: "LOGGINGS_RESPONSE_LIST_TOURS", value: response });
  } catch (error) {
    yield put({ type: "LOGGINGS_RESPONSE_LIST_TOURS", value: error });
  }
}

function* destinations(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/destinations?pagination=false",
      requestOptions
    );

    yield put({ type: "DESTINATION_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "DESTINATION_RESPONSE_LIST", value: error });
  }
}

function* partners(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/autocomplete/partners",
      requestOptions
    );

    yield put({ type: "PARTNERS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "PARTNERS_RESPONSE_LIST", value: error });
  }
}

function* guides(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/autocomplete/guides",
      requestOptions
    );

    yield put({ type: "GUIDES_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "GUIDES_RESPONSE_LIST", value: error });
  }
}

function* audit(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/audits?objectType=" +
        actions.objectType +
        "&objectId=" +
        actions.objectId,
      requestOptions
    );

    yield put({ type: "AUDIT_RESPONSE_LIST_TOURS", value: response });
  } catch (error) {
    yield put({ type: "AUDIT_RESPONSE_LIST_TOURS", value: error });
  }
}

function* addAudit(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/audits",
      requestOptions
    );

    yield put({ type: "AUDIT_RESPONSE_POST_TOURS", value: response });
  } catch (error) {
    yield put({ type: "AUDIT_RESPONSE_POST_TOURS", value: error });
  }
}

function* searchTourAttractionsList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let query = actions.destination
      ? "/attractions?name="
      : "/attractions?destination=";
    const response = yield call(
      apiCall,
      "get",
      apiUrl + query + actions.data,
      requestOptions
    );

    yield put({ type: "TOUR_ATTRACTIONS_RESPONSE_SEARCH", value: response });
  } catch (error) {
    yield put({ type: "TOUR_ATTRACTIONS_RESPONSE_SEARCH", value: error });
  }
}

function* get_Guides(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    let url = apiUrl + "/autocomplete/users?name=" + actions.data;

    const response = yield call(apiCall, "get", url, requestOptions);
    yield put({ type: "GUIDES_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "GUIDES_RESPONSE", value: error });
  }
}

function* downloadreport_Tour(actions) {

  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    const response = yield fetch(
      apiUrl + `/tours-export`,
      requestOptions
    ).then(response => response.blob());

    let name = 'Tour_Report_' + actions.date + '.xlsx';
    saveAs(response, name);
} catch (error) {}
}



export function* getDestinationsAttractionsTours() {
  yield takeEvery("GET_DESTINATIONS_ATTRACTIONS_TOURS", destinations);
}

export function* getPartnersTours() {
  yield takeEvery("GET_PARTNERS_TOURS", partners);
}

export function* getGuidesTours() {
  yield takeEvery("GET_GUIDES_TOURS", guides);
}

/*TOURS*/

export function* addAttractionTour() {
  yield takeEvery("ADD_ATTRACTION_TOUR", addAttractionTours);
}

export function* editAttractionTour() {
  yield takeEvery("EDIT_ATTRACTION_TOUR", editAttractionTours);
}

export function* getAttractionTour() {
  yield takeEvery("GET_ATTRACTION_TOUR", attractionTour);
}

export function* getAttractionsToursList() {
  yield takeEvery("GET_ATTRACTIONS_TOURS", getAttractionsTours);
}

export function* getToursCommentsList() {
  yield takeEvery("GET_TOURS_COMMENTS", comments);
}

export function* getToursHistoryList() {
  yield takeEvery("GET_TOURS_HISTORY_LIST", history);
}

export function* getToursAuditList() {
  yield takeEvery("GET_TOURS_AUDIT_LIST", audit);
}

export function* searchTourAttractions() {
  yield takeEvery("SEARCH_TOUR_ATTRACTION", searchTourAttractionsList);
}

export function* getGuides() {
  yield takeEvery("GET_GUIDES", get_Guides);
}

export function* downloadreportTour() {
  yield takeEvery("DOWNLOADREPORTTOUR", downloadreport_Tour);
}






