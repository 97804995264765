import { takeEvery, put, call, all } from "redux-saga/effects";
import { saveAs } from "file-saver";
import apiCall from "../services";

const apiUrl = process.env.REACT_APP_API_URL;

function* getLocationsAsync(actions) {
  try {
    const data = yield call(apiCall, "get", apiUrl + actions.location);
    const dataTours = yield call(
      apiCall,
      "get",
      apiUrl + "/tours?destinationId=" + data.id
    );

    yield put({
      type: "GET_RESULTS_LOCATIONS",
      user: actions.user,
      value: data,
      tours: dataTours,
      locationName: actions.location
    });
  } catch (error) {
    yield put({
      type: "GET_RESULTS_LOCATIONS",
      value: error,
      user: actions.user
    });
  }
}

function* getToursByLocation(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    let locationByTour = [];
    let locationFilter = [
      {
        id: "0",
        key: 0,
        label: "All cities",
        selected: true,
        count: 0,
        partialCount: 0
      }
    ];

    let locationByTourCount = 0;
    yield all(
      actions.locations.map(function*(location) {
        const dataTours = yield call(
          apiCall,
          "get",
          apiUrl +
            "/tours?destinationId=" +
            location.id +
            "&pagination=" +
            false +
            "&published=" +
            true,
          requestOptions
        );

        location["tours"] = dataTours["hydra:member"];
        locationByTour[location.id] = dataTours["hydra:member"];
        locationByTourCount =
          locationByTourCount + dataTours["hydra:totalItems"];
        locationFilter.push({
          id: location.id,
          key: 1,
          label: location.name,
          selected: false
        });
      })
    );

    yield put({
      type: "GET_RESULTS_TOURS_BY_LOCATION",
      locationByTourCount: locationByTourCount,
      locations: actions.locations,
      filters: locationFilter,
      user: actions.user
    });
  } catch (error) {
    yield put({
      type: "GET_RESULTS_TOURS_BY_LOCATION",
      value: error,
      user: actions.user
    });
  }
}

function* getToursAsync(actions) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${actions.cookie}`
  };

  const requestOptions = {
    headers: headers
  };
  let userId = actions.user["@id"].split("/users/")[1];

  const dataMyTours = yield call(
    apiCall,
    "get",
    apiUrl + "/tours?userId=" + userId + "&pagination=false",
    requestOptions
  );
  let tours = dataMyTours["hydra:member"];

  try {
    yield put({ type: "GET_RESULTS_TOURS", tours: tours, user: actions.user });
  } catch (error) {
    yield put({ type: "GET_RESULTS_TOURS", value: error, user: actions.user });
  }
}

function* updateTour(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const toursRequest = {
      tours: actions.tours
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(toursRequest)
    };

    const user = yield call(
      apiCall,
      "put",
      apiUrl + actions.user["@id"],
      requestOptions
    );
    actions.user = user;

    yield getToursAsync(actions);
  } catch (error) {
    yield put({ type: "ERROR_TOUR", value: error });
  }
}

function* getLocationsAll(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    const data = yield call(
      apiCall,
      "get",
      apiUrl + "/destinations?pagination=" + false,
      requestOptions
    );

    yield put({ type: "GET_RESULTS_LOCATIONS_ALL", value: data });
  } catch (error) {
    yield put({ type: "GET_RESULTS_LOCATIONS_ALL", value: error });
  }
}

function* getAddLocation(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const locationsRequest = {
      destinations: actions.locations
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(locationsRequest)
    };

    let responseUser = yield call(
      apiCall,
      "put",
      apiUrl + actions.user["@id"],
      requestOptions
    );

    actions.locations = responseUser.destinations;

    yield getToursByLocation(actions);

    yield put({ type: "GET_ADD_TOUR", value: responseUser });

  } catch (error) {
    yield put({ type: "GET_ADD_TOUR", value: error });
  }
}

function* getDeleteLocation(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const locationsRequest = {
      destinations: actions.locations
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(locationsRequest)
    };

    const responseUser = yield call(
      apiCall,
      "put",
      apiUrl + actions.user["@id"],
      requestOptions
    );
    actions.locations = responseUser.destinations;

    yield getToursByLocation(actions);
    yield put({ type: "GET_DELETE_LOCATION", value: actions });

  } catch (error) {
    yield put({ type: "GET_DELETE_LOCATION", value: error });
  }
}

function* get_Sidebar(actions) {
  try {
    yield put({ type: "SIDEBAR_OPEN_CLOSE", value: actions.access });
  } catch (error) {
    yield put({ type: "SIDEBAR_OPEN_CLOSE", value: error });
  }
}

function* get_BookingExpenses(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/bookings?userId=" + actions.userId + "&page=1"
        : apiUrl + actions.query;

    const data = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "GET_RESULTS_BOOKING_EXPENSES_LIST", value: data });
  } catch (error) {
    yield put({ type: "GET_RESULTS_LOCATIONS_ALL", value: error });
  }
}

function* add_BookingExpenses(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers,
      body: actions.data
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/booking-expenses/upload",
      requestOptions
    );

    yield put({ type: "EXPENSES_PRELOAD", value: response });
  } catch (error) {
    yield put({ type: "EXPENSES_PRELOAD", value: error });
  }
}

function* download_ExpensesFile(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield fetch(
      apiUrl + "/booking-expenses/" + actions.expensesId + "/download",
      requestOptions
    ).then(response => response.blob());

    saveAs(response, actions.nameDocument);
  } catch (error) {
    yield put({ type: "GET_RESPONSE_DOWNLOAD_EXPENSES", value: error });
  }
}

function* delete_Expenses(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    yield call(
      apiCall,
      "DELETE",
      apiUrl + "/booking-expenses/" + actions.id,
      requestOptions
    );
  } catch (error) {}
}

function* edit_Expenses(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "PUT",
      apiUrl + "/booking-expenses/" + actions.id,
      requestOptions
    );

    yield put({ type: "EXPENSES_PRELOAD", value: response });
  } catch (error) {
    yield put({ type: "EXPENSES_PRELOAD", value: error });
  }
}
function* submitForReview_Status(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.bookingId)
    };

    const response = yield call(
      apiCall,
      "PUT",
      apiUrl + "/booking-report/" + actions.id,
      requestOptions
    );
    yield put({ type: "EXPENSES_PRELOAD", value: response });
  } catch (error) {
    yield put({ type: "EXPENSES_PRELOAD", value: error });
  }
}
function* add_PaymentMethod(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/guides/payment-method",
      requestOptions
    );

    yield put({ type: "PAYMENTMETHOD_ADD", value: response });
  } catch (error) {
    yield put({ type: "PAYMENTMETHOD_ADD", value: error });
  }
}

function* get_BookingPaymentMethod(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    const data = yield call(
      apiCall,
      "GET",
      apiUrl + "/guides/payment-method",
      requestOptions
    );

    yield put({ type: "GET_RESULTS_BOOKING_PAYMENTMETHOD", value: data });
  } catch (error) {
    yield put({ type: "GET_RESULTS_BOOKING_PAYMENTMETHOD", value: error });
  }
}

function* add_RequestPayouts(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/bookings/request-payments",
      requestOptions
    );

    yield put({ type: "EXPENSES_PRELOAD", value: response });
  } catch (error) {
    yield put({ type: "EXPENSES_PRELOAD", value: error });
  }
}

function* get_GuidePayments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    const data = yield call(
      apiCall,
      "GET",
      apiUrl + "/guides/payments",
      requestOptions
    );

    yield put({ type: "GET_RESULTS_GUIDE_PAYMENTS", value: data });
  } catch (error) {
    yield put({ type: "GET_RESULTS_GUIDE_PAYMENTS", value: error });
  }
}

function* edit_PaymentMethod(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "PUT",
      apiUrl + "/guides/payment-method/" + actions.id,
      requestOptions
    );
    yield put({ type: "PAYMENTMETHOD_ADD", value: response });
  } catch (error) {
    yield put({ type: "PAYMENTMETHOD_ADD", value: error });
  }
}

function* upload_Avatar(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: actions.data
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/guides/profile-picture",
      requestOptions
    );

    yield put({ type: "GUIDE_PROFILE_PIC_UPLOADED_DATA", value: response });
  } catch (error) {
    yield put({ type: "GUIDE_PROFILE_PIC_UPLOADED_DATA", value: error });
  }
}


function* clean_PaymentMethod(actions) {
  try {
    yield put({ type: "CLEANPAYMENTMETHOD", value: true });
  } catch (error) {
    yield put({ type: "CLEANPAYMENTMETHOD", value: error });
  }
}

function* status(actions) {
  yield put({ type: "UPDATE_STATUS_TOURS", status: actions.status });
}

export function* tours() {
  yield takeEvery("GET_TOURS", getToursAsync);
}

export function* deleteTour() {
  yield takeEvery("DELETE_TOUR", updateTour);
}

export function* addTour() {
  yield takeEvery("ADD_TOUR", updateTour);
}

export function* locations() {
  yield takeEvery("GET_LOCATIONS", getLocationsAsync);
}

export function* toursByLocation() {
  yield takeEvery("GET_TOURS_BY_LOCATION", getToursByLocation);
}

export function* locationsAll() {
  yield takeEvery("GET_LOCATIONS_ALL", getLocationsAll);
}

export function* deleteLocation() {
  yield takeEvery("DELETE_LOCATION", getDeleteLocation);
}

export function* addLocation() {
  yield takeEvery("ADD_LOCATION", getAddLocation);
}

export function* updateStatus() {
  yield takeEvery("UPDATE_STATUS", status);
}

export function* getSidebar() {
  yield takeEvery("GET_SIDEBAR", get_Sidebar);
}

export function* getBookingExpenses() {
  yield takeEvery("GET_BOOKINGEXPENSES", get_BookingExpenses);
}

export function* addBookingExpenses() {
  yield takeEvery("ADD_BOOKING_EXPENSES", add_BookingExpenses);
}

export function* downloadExpensesFile() {
  yield takeEvery("DOWNLOAD_EXPENSESFILE", download_ExpensesFile);
}

export function* deleteExpenses() {
  yield takeEvery("DELETE_EXPENSES", delete_Expenses);
}

export function* editExpenses() {
  yield takeEvery("EDIT_EXPENSES", edit_Expenses);
}

export function* submitForReviewStatus() {
  yield takeEvery("SUBMIT_FOR_REVIEW_STATUS", submitForReview_Status);
}

export function* addPaymentMethod() {
  yield takeEvery("ADD_PAYMENTMETHOD", add_PaymentMethod);
}

export function* getBookingPaymentMethod() {
  yield takeEvery("GET_BOOKING_PAYMENT_METHOD", get_BookingPaymentMethod);
}

export function* addRequestPayouts() {
  yield takeEvery("ADD_REQUESTPAYOUTS", add_RequestPayouts);
}

export function* getGuidePayments() {
  yield takeEvery("GET_GUIDE_PAYMENTS", get_GuidePayments);
}
export function* editPaymentMethod() {
  yield takeEvery("EDIT_PAYMENTMETHOD", edit_PaymentMethod);
}

export function* guideUploadAvatar() {
  yield takeEvery("UPLOAD_AVATAR", upload_Avatar);
}

export function* cleanPaymentMethod() {
  yield takeEvery("CLEAN_PAYMENT_METHOD", clean_PaymentMethod);
}


