const initialState = {
  resultsDestinationsList: [],
  resultDestination: [],
  resultsDestinationMultimedia: [],
  resultsCommentsList: [],
  resultsLoggingsList: [],
  resultsToursList: [],
  resultAttraction: {},
  responseAttractionsListDestinations: [],

  preload: false
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "DESTINATIONS_RESPONSE_EDIT":
      newState.preload = false;
      newState.back = true;
      break;
    case "DESTINATIONS_RESPONSE_CREATE":
      newState.preload = false;
      newState.back = true;
      break;
    case "DESTINATIONS_RESPONSE_LIST":
      newState.resultsDestinationsList = action.value;
      newState.preload = false;
      break;
    case "DESTINATION_RESPONSE":
      newState.preload = false;
      newState.resultDestination = action.value;
      break;
    case "COMMENTS_RESPONSE_LIST":
      newState.resultsCommentsList = action.value;
      newState.preload = false;
      break;
    case "COMMENTS_RESPONSE_POST":
      newState.preload = false;
      break;
    case "LOGGINGS_RESPONSE_LIST":
      newState.resultsLoggingsList = action.value;
      newState.preload = false;
      break;
    case "TOURS_RESPONSE_LIST":
      newState.resultsToursList = action.value;
      newState.preload = false;
      break;
    case "ATTRACTION_RESPONSE_DESTINATIONS_LIST":
      newState.responseAttractionsListDestinations = action.value;
      newState.preload = false;
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
