import React, { Component, Fragment, useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Icon,
  Collapse,
  Typography
} from "@material-ui/core";
import sidebar from "../../../config/sidebar.json";
import {
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  ExpandLess
} from "@material-ui/icons/";
import clsx from "clsx";
import { enabledPermissions } from "../../../services/enabledPermissions";

const SidebarNavigationItems = props => {
  let useSiderStatus = sessionStorage.siderbarO === "true" ? true : false;
  const [openSidebar, setOpenSidebar] = React.useState(useSiderStatus);
  const [enabledSidebarItems, setEnabledSidebarItems] = useState([]);

  useEffect(() => {
    if (window.localStorage.getItem("userData") !== undefined) {
      const permissionsItems = JSON.parse(window.localStorage.getItem("userData")).roles;
      setEnabledSidebarItems(enabledPermissions(props.sidebar, permissionsItems));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userData]);

  let urlRouting = props.routting.split("dashboard/")[1];
  let urlRouting0 = "";

  if (urlRouting.split("?")[1]) {
    urlRouting0 = "/" + urlRouting.split("?")[0];

    if (urlRouting.split("/")[0]) {
      urlRouting0 = "/" + urlRouting0.split("/")[1];
    }
  } else {
    urlRouting0 = "/" + urlRouting.split("/")[0];
  }

  urlRouting = urlRouting === undefined ? "dashboard/" : "dashboard" + urlRouting0;

  const handleDrawerClose = () => {
    setOpenSidebar(!openSidebar);

    sessionStorage.setItem("siderbarO", !openSidebar);
  };

  const SidebarItem = props => {
    const [collapsed, setCollapsed] = React.useState(true);
    const {
      listItemText: { primary },
      id,
      group,
      submenu,
      routing,
      icon,
      onClick: onClickProp
    } = props.item;

    function toggleCollapse() {
      setCollapsed(prevValue => !prevValue);
    }

    let expandIcon;

    if (Array.isArray(submenu) && submenu.length) {
      expandIcon = !collapsed ? <ExpandMore /> : <ExpandLess />;
    }

    const handleListItemClick = (event, id, group) => {
      if (Array.isArray(submenu)) {
        toggleCollapse();
      }
      if (onClickProp) {
        onClickProp(event, props.item);
      }
    };

    return (
      <Fragment>
        {routing !== "" ? (
          <ListItem
            button
            component="a"
            href={`/${routing}`}
            selected={routing === urlRouting ? true : false}
            onClick={event => handleListItemClick(event, id, group)}
          >
            <ListItemIcon>
              <Icon>{icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={primary} disableTypography />
            <Typography variant="body2">{expandIcon}</Typography>
          </ListItem>
        ) : (
          <ListItem
            button
            selected={routing === urlRouting ? true : false}
            onClick={event => handleListItemClick(event, id, group)}
            {...props}
          >
            <ListItemIcon>
              <Icon>{icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={primary} disableTypography />
            <Typography variant="body2">{expandIcon}</Typography>
          </ListItem>
        )}

        {Array.isArray(submenu) ? (
          <Collapse
            in={collapsed}
            timeout="auto"
            unmountOnExit
            className={"nested"}
          >
            <List component="div" disablePadding>
              {submenu.map((subItem, index) => (
                <SidebarItem key={index} item={subItem} />
              ))}
            </List>
          </Collapse>
        ) : null}
      </Fragment>
    );
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx("drawerPaper", !openSidebar && "drawerPaperClose")
      }}
      open={openSidebar}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className="sidebarnav-list"
        style={{ direction: "ltr" }}
      >
        {enabledSidebarItems.map((item, i) => (
          <SidebarItem item={item} key={i} />
        ))}
      </List>
      <IconButton className="toolbarDrawerButton" onClick={handleDrawerClose}>
        {!openSidebar ? <ChevronRight /> : <ChevronLeft />}
      </IconButton>
    </Drawer>
  );
};

export default class SidebarNavigation extends Component {
  render() {
    let routting = window.location.href;

    return <SidebarNavigationItems sidebar={sidebar} routting={routting} />;
  }
}
