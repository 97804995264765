import React, { Component, Fragment } from "react";
import Cookies from "js-cookie";
import Theme from "../../assets/Theme";
import style from "./../../config/style.json";
import config from "./../../config/config.json";
import clsx from "clsx";
import {
  Toolbar,
  AppBar,
  Typography,
  IconButton,
  Container,
  Icon,
  Avatar,
  Collapse,
  Link
} from "@material-ui/core";
import SidebarNavigation from "./partials/SidebarNavigation";
import { getUser } from "../../services/CardsService";
import MenuDropdown from "./partials/MenuDropdown";
import { Redirect } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Logo from "../../assets/images/logo-white.png";
import LogoMobile from "../../assets/images/logo-white-mobile.png";
import Hidden from "@material-ui/core/Hidden";
import qs from "query-string";

class MainAdmin extends Component {
  constructor(props) {
    super(props);

    this.user = window.localStorage.getItem("user");
    this.environmentIdentifier = window.localStorage.getItem(
      "environmentIdentifier"
    );

    let currentEnvironment = Cookies.get("env");
    if (
      currentEnvironment !== null &&
      currentEnvironment !== this.environmentIdentifier
    ) {
      this.environmentIdentifier = currentEnvironment;
      window.localStorage.setItem(
        "environmentIdentifier",
        this.environmentIdentifier
      );
    }

    this.state = {
      open: true,
      mode: 'ADMIN',
      userData: {},
      statusLogin: false,
      cards: config.cards,
      responseForgot: "",
      openAlert: true,
      showSidebar: null,
      title: ""
    };
  }

  UNSAFE_componentWillMount = () => {
    this.webservice();
  };

  webservice = async () => {
    await getUser(this.user)
      .then(res => {
        if (res === false) {
          this.user = null;
          window.localStorage.clear();
        }
        let dataTitle = "Admin Panel";
        let dataMode = 'ADMIN';
        let show = true;

        if (res.roles.includes("ROLE_GUIDE")) {
          show = false;
          dataTitle = "Guide Portal";
          dataMode = 'GUIDE';
        } else if (res.roles.includes("ROLE_ADMIN")) {
          dataTitle = "Admin Panel";
          dataMode = 'ADMIN';
        }

        this.setState({ title: dataTitle, mode: dataMode });

        window.localStorage.setItem("userData", JSON.stringify(res));

        this.setState({ userData: res, showSidebar: show });
      })
      .catch(err => {
        this.user = null;
        this.setState({ userData: false });
      });
  };

  callbackCard = indiceCard => {
    this.state.cards.map((value, i) => {
      if (i === indiceCard) {
        value.expanded = true;
        value.visibility = true;
      } else {
        value.expanded = false;
        value.visibility = false;
      };
      return value;
    });

    this.setState({ cards: this.state.cards });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  callbackForgot = res => {
    this.setState({ responseForgot: res });
  };

  collapseAlert = () => {
    this.setState({ openAlert: false });
  };

  render() {

    let queryString = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    // Unlogged user
    if (this.user === null) {

      let loginUrl = '/login';

      // Environment parsing and propagation
      let envSet = false;
      if (queryString["env"] !== undefined) {
        envSet = true;
        loginUrl = loginUrl + "?env=" + queryString["env"];
      }

      // Redirect parameter
      if (window.location.pathname !== '/') {
        loginUrl = loginUrl + (envSet ? '&' : '?') + "redirect=" + window.location.pathname + window.location.search +  window.location.hash;
      }

      return (
        <Fragment>
          <Redirect to={loginUrl} />
        </Fragment>
      );
    }

    if (this.state.mode === 'GUIDE' && window.location.pathname === '/dashboard/') {
      let redirectUrl = '/dashboard/guide-portal';

      if (queryString["env"] !== undefined) {
        redirectUrl = redirectUrl + "?env=" + queryString["env"];
      }

      return (
        <Fragment>
          <Redirect to={redirectUrl} />
        </Fragment>
      );
    }

    if (this.state.showSidebar === null) {
      return (<div></div>);
    }

    return (
      <Fragment>
          <Theme>
            <div
              styles={style}
              className={
                this.environmentIdentifier === "andy"
                  ? `wrapper MainLayout_wrapper environmentAndy`
                  : this.environmentIdentifier === "staging"
                  ? `wrapper MainLayout_wrapper environmentStaging`
                  : `wrapper MainLayout_wrapper environmentProduction`
              }
            >
              {/* START HEADER */}
              <AppBar
                position="absolute"
                className={clsx("appBar", this.state.open && "appBarShift")}
              >
                <Toolbar
                  className={
                    this.environmentIdentifier === "andy"
                      ? `toolbar_Appbar environmentAndy`
                      : this.environmentIdentifier === "staging"
                      ? `toolbar_Appbar environmentStaging`
                      : `toolbar_Appbar environmentProduction`
                  }
                >
                  <Link href="/dashboard/">
                  <Hidden xsDown>
                    <img
                      className={clsx(
                        "toolbarLogo",
                        this.state.open && "toolbarLogoShow"
                      )}
                      src={Logo}
                      alt="Travel Curious"
                    />
                  </Hidden>
                  <Hidden smUp>
                    <img
                      className={clsx(
                        "toolbarLogo",
                        this.state.open && "toolbarLogoShow"
                      )}
                      src={LogoMobile}
                      alt="Travel Curious"
                    />
                  </Hidden>
                  </Link>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerOpen}
                    className={clsx(
                      "menuButton",
                      this.state.open && "menuButtonHidden"
                    )}
                  >
                    <Icon>menu</Icon>
                  </IconButton>

                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className="title_Dashboard"
                  >
                    {this.state.title}
                  </Typography>

                  {Object.keys(this.state.userData).length > 0 ? (
                    <MenuDropdown
                      user={this.state.userData.email}
                      buttonStartIcon={
                        <Avatar className="MenuHeader_Avatar">
                          <Icon>person</Icon>
                        </Avatar>
                      }
                      buttonEndIcon={<Icon>expand_more</Icon>}
                      buttonText={
                        <Typography component="span" color="inherit" noWrap>
                          {this.state.userData.firstName}
                        </Typography>
                      }
                      callbackForgot={this.callbackForgot}
                    />
                  ) : (
                    ""
                  )}
                </Toolbar>
              </AppBar>
              {/* END HEADER */}

              {/* START SIDEBAR */}
              {this.state.showSidebar && <SidebarNavigation />}
              {/* END SIDEBAR */}

              <main className="main_admin">
                <div className="app_bar_spacer" />

                {this.state.responseForgot !== "" && (
                  <Collapse in={this.state.openAlert}>
                    <Container maxWidth={false} className="container pb-0">
                      <Alert
                        severity="info"
                        variant="filled"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.collapseAlert(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.state.responseForgot}
                      </Alert>
                    </Container>
                  </Collapse>
                )}

                <Container
                  maxWidth={false}
                  className="container mainLayout_container"
                >
                  {this.props.children}
                </Container>
              </main>
            </div>
          </Theme>
      </Fragment>
    );
  }
}

export default MainAdmin;
