import React, { Component, Suspense, Fragment } from "react";
import ContentLoader from "react-content-loader";
import { IconButton, Collapse, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

class DashboardCardViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showAlert: true,
      userData: {},
      statusLogin: false,
      card: []
    };
  }

  dashboardCardType = () => {
    let card =
      this.props.match.params.card.charAt(0).toUpperCase() +
      this.props.match.params.card.slice(1);

    if (card === "TourBookings" && this.props.match.params.views === "create") {
      card += "Create";
    }
    let Component = React.lazy(() => import(`../../forms/View${card}`));
    return (
      <Suspense
        fallback={
          <div className="preload">
            <div className="responsive">
              <ContentLoader
                speed={2}
                backgroundColor="#e8e8e8"
                foregroundColor="#f0f0f0"
                width={385}
                height={200}
                viewBox="0 0 385 200"
              >
                <rect x="0" y="0" rx="10" ry="10" width="385" height="200" />
              </ContentLoader>
            </div>
          </div>
        }
      >
        <Component
          card={this.props.match.params.card}
          views={this.props.match.params.views}
          id={this.props.match.params.id}
        />
      </Suspense>
    );
  };

  collapseAlert = () => {
    this.setState({ open: !this.state.open, showAlert: false });
  };
  componentDidMount() {
    // Google Tag Manager
    const scriptGoogle = document.createElement("script");
    scriptGoogle.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER}');`;
    document.head.appendChild(scriptGoogle);

    const noscriptGoogleTagManager = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id="+ process.env.REACT_APP_GOOGLE_TAG_MANAGER;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscriptGoogleTagManager.appendChild(iframe);
    document.head.appendChild(noscriptGoogleTagManager);
  }

  render() {
    const cardName =
      this.props.match.params.card.charAt(0).toUpperCase() +
      this.props.match.params.card.slice(1)
        ? this.props.match.params.card.charAt(0).toUpperCase() +
          this.props.match.params.card.slice(1)
        : "";

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <div
              className={`detail_dashboard dashboard_card_expanded DashboardCardViews ${cardName}`}
            >
              <div className={`content_detail_dashboard `}>
                {this.props.location.state !== undefined &&
                  this.props.location.state.stepFinalizado !== undefined &&
                  this.props.location.state.view !== "show" && (
                    <Collapse
                      in={this.state.open}
                      className="alertTopCardContent"
                    >
                      <Alert
                        severity="info"
                        variant="filled"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={this.collapseAlert}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {this.props.location.state.view === "edit"
                          ? "El producto se actualizó correctamente"
                          : "El producto se creó correctamente"}
                      </Alert>
                    </Collapse>
                  )}
                {this.state.showAlert &&
                  this.props.location.state !== undefined &&
                  this.props.location.state.responseEdit !== undefined && (
                    <Alert
                      severity="info"
                      variant="filled"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={this.collapseAlert}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {this.props.location.state.responseEdit}
                    </Alert>
                  )}
                {this.dashboardCardType()}
              </div>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default DashboardCardViews;
