import React, { Component, Fragment } from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export default class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: props.errorText,
      error: props.error,
      defaultValue: props.defaultValue,
      focus: false
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    if (next_props.initial !== undefined && next_props.initial) {
      this.setState({
        error: false,
        errorText: "",
        defaultValue: next_props.defaultValue
      });
    } else {
      this.setState({
        error: next_props.error,
        errorText: next_props.errorText
      });
    }
  }
  handleChange = event => {
    this.setState({ defaultValue: event.target.value });

    if (event.target.value === "") {
      if (this.props.noRequired) {
        this.setState({ errorText: "", error: false });
      } else {
        this.setState({ errorText: "Enter an email address", error: true });
      }
    } else {
      if (
        event.target.value.match(
          /^([A-Za-z0-9+_.-]+)@([\da-zA-Z.-]+)\.+([a-zA-Z.]{2,6})$/
        )
      ) {
        this.setState({ errorText: "", error: false });
      } else {
        this.setState({ errorText: "Invalid format", error: true });
      }
    }
  };

  onBlur() {
    setTimeout(() => {
      if (this.state.focus) {
        this.setState({ focus: false });
      }
    }, 0);
  }

  onFocus() {
    if (!this.state.focus) {
      this.setState({ focus: true });
    }
  }

  render() {
    const OnFocusHideFixedElements = withStyles({
      "@global": {
        "body .headroom": {
          "@media (max-width: 960px)": {
            transform: "translate3d(0px, -100%, 0px)!important"
          }
        }
      }
    })(() => null);

    return (
      <Fragment>
        {this.state.focus && <OnFocusHideFixedElements />}
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          className={`TextField-Email ${this.props.classNameCustom}`}
          size={this.props.size}
          variant="outlined"
          error={this.state.error}
          id={this.props.id}
          label={this.props.label}
          placeholder={this.props.placeholder}
          helperText={this.state.errorText}
          onChange={this.handleChange}
          value={this.state.defaultValue}
          name={this.props.name}
          disabled={this.props.disabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          required={this.props.required}
          autoComplete={this.props.autoComplete}
          onKeyPress={this.props.onKeyPress}
        />
      </Fragment>
    );
  }
}
