import React, { Component, Suspense, Fragment } from "react";
import config from "./../../../config/config.json";
import ContentLoader from "react-content-loader";
import { IconButton, Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Hidden from '@material-ui/core/Hidden';

class DashboardCardExpanded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      userData: {},
      statusLogin: false,
      card: []
    };
  }

  componentWillMount = async () => {
    let card=  (this.props.match.params.card !== 'login') ? this.props.match.params.card : 'guide-portal'
    let cardFilter = config.cards.filter(e => e.routing === card);
    this.setState({ statusLogin: false, card: cardFilter[0] });
  };

  dashboardCardType = () => {
    let Component = React.lazy(() =>
      import(`./sections/DashboardCard${this.state.card.config.type}`)
    );
    return (
      <Suspense
        fallback={
          <div className="preload">
            <div className="responsive">
              <ContentLoader
                speed={2}
                backgroundColor="#e8e8e8"
                foregroundColor="#f0f0f0"
                width={385}
                height={200}
                viewBox="0 0 385 200"
              >
                <rect x="0" y="0" rx="10" ry="10" width="385" height="200" />
              </ContentLoader>
            </div>
          </div>
        }
      >
        <Component
          data={this.state.card}
          className={this.state.card.config.type}
        />
      </Suspense>
    );
  };

  collapseAlert = () => {
    this.setState({ open: !this.state.open });
  };

  getAlertComponents = () => {
    return <>
      {this.props.location.state !== undefined &&
        this.props.location.state.responseDelete !== undefined && (
          <Collapse in={this.state.open}>
            <Alert
              severity="info"
              variant="filled"
              className="alert-info mb-3"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.collapseAlert}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {this.props.location.state.responseDelete}
            </Alert>
          </Collapse>
        )
      }
      {this.props.location.state !== undefined &&
        this.props.location.state.responseCreate !== undefined && (
          <Collapse in={this.state.open}>
            <Alert
              severity="info"
              variant="filled"
              className="alert-info mb-3"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.collapseAlert}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {this.props.location.state.responseCreate}
            </Alert>
          </Collapse>
        )
      }
      {this.props.location.state !== undefined &&
        this.props.location.state.responseEdit !== undefined && (
          <Collapse in={this.state.open}>
            <Alert
              severity="info"
              variant="filled"
              className="alert-info mb-3"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={this.collapseAlert}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {this.props.location.state.responseEdit}
            </Alert>
          </Collapse>
        )
      }
    </>
  }
  componentDidMount() {
    // Google Tag Manager
    const scriptGoogle = document.createElement("script");
    scriptGoogle.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER}');`;
    document.head.appendChild(scriptGoogle);

    const noscriptGoogleTagManager = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id="+ process.env.REACT_APP_GOOGLE_TAG_MANAGER;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscriptGoogleTagManager.appendChild(iframe);
    document.head.appendChild(noscriptGoogleTagManager);
  }

  render() {
    return (
      <Fragment>

        <div className={`detail_dashboard dashboard_card_expanded`}>
          <Hidden smDown>
            <div className={this.state.card.config.type === 'TabsDefault' ?  `content_detail_dashboard_guide DashboardCard${this.state.card.config.type}` : `content_detail_dashboard DashboardCard${this.state.card.config.type}`}>
              {this.getAlertComponents()}
              {this.dashboardCardType()}
            </div>
          </Hidden>

          <Hidden mdUp>
            <div className={`content_detail_dashboard_Mobile DashboardCard${this.state.card.config.type}`}>
              {this.getAlertComponents()}
              {this.dashboardCardType()}
            </div>
          </Hidden>
        </div>
      </Fragment>
    );
  }
}

export default DashboardCardExpanded;
