const initialState = {
  resultsBookingExpensesList: [],
  resultsTours: [],
  resultsToursView: [],
  resultsLocations: [],
  init: false,
  locationsAll: [],
  locationByTour: [],
  locationByTourCount: 0,
  locationFilter: [],
  user: {},
  addingRemoving: false,
  addedRemoved: false,
  resultsDocuments: [],
  resultsDocumentsList: [],
  resultsInterests: [],
  addInterestValue: "",
  preload: false,
  expenseReportId: 0,
  resultsMyToursById: [],
  downloadExpenses: null,
  resultPaymentMethodCheck: [],
  resultBookingPaymentMethod: [],
  resultBookingPaymentMethodAdd: [],
  guidePaymentsList: [],
  resultUserData: [],
  guideProfilePicData: [],
  resultOpportunities: [],
  resultUpcoming: [],
  resultAddDestinations: [],
  preloadDestinations: false,
  preloadDestinationsDelete: false,

};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {


    case "PRELOAD":
      newState.preload = false;
      break;

    case "CLEANPAYMENTMETHOD":
      newState.resultBookingPaymentMethodAdd = [];
    break;

    case "RESULTS_UPCOMING":
      newState.resultUpcoming = action.value;

      break;
    case "RESULTS_OPPORTUNITIES":
      newState.resultOpportunities = action.value;

      break;

    case "USER_DATA":
      newState.resultUserData = action.value;

      break;
    case "PAYMENTMETHOD_ADD":
      newState.resultBookingPaymentMethodAdd = action.value;
      break;
    case "PAYMENTMETHOD_PRELOAD":
      newState.preload = false;
      break;

    case "GET_RESULTS_BOOKING_PAYMENTMETHOD":
      newState.resultBookingPaymentMethod = action.value;
      newState.preload = true;
      break;

    case "PAYMENTMETHOD_CHECK":
      newState.resultPaymentMethodCheck = action.value;
      newState.preload = true;
      break;

    case "EXPENSES_PRELOAD":
      newState.preload = false; 
      newState.expenseReportId = action.value.expenseReportId;
      break;

    case "GET_RESULTS_BOOKING_EXPENSES_LIST":
      newState.resultsBookingExpensesList = action.value;
      newState.preload = true; 

      break;
    case "GET_RESULTS_LOCATIONS":
      action.value["tours"] = action.tours["hydra:member"];
      newState.locationByTour[action.locationName] =
        action.tours["hydra:member"];
      newState.locationByTourCount =
        newState.locationByTourCount +
        Object.keys(action.tours["hydra:member"]).length;
      newState.resultsLocations = [...newState.resultsLocations, action.value];

      newState.locationFilter.push({
        id: action.value["@id"],
        key: 1,
        label: action.value.name,
        selected: false
      });
      newState.init = true;
      newState.user = action.user;
      newState.addingRemoving = false;
      newState.addedRemoved = true;
      newState.preload = false;
      break;
    case "GET_RESULTS_TOURS":
      let tourGrouyByLocation = [];
      let locations = [];
      let tours = [];
      action.tours.forEach(tour => {
        if (locations.includes(tour.geo.destination)) {
          let resuno = tourGrouyByLocation.filter(
            e => e.location === tour.geo.destination
          );
          resuno[0].tours.push(tour);
        } else {
          tours = [];
          locations.push(tour.geo.destination);
          tours.push(tour);
          tourGrouyByLocation.push({
            location: tour.geo.destination,
            tours: tours,
            count: Object.keys(tours).length
          });
        }
      });
      newState.resultsToursView = tourGrouyByLocation;
      newState.resultsTours = action.tours;
      newState.user = action.user;
      newState.addingRemoving = false;
      newState.addedRemoved = true;
      break;
    case "GET_DELETE_LOCATION":
      newState.preloadDestinationsDelete = true;
      newState.resultAddDestinations = action.value.destinations;

      let pos = "";
      let locationsNew = [];
      pos = newState.resultsLocations
        .map((e) => e["@id"])
        .indexOf(action.locationId);
      newState.resultsLocations.forEach((val, i) => {
        if (i !== pos) {
          locationsNew.push(val);
        }
      });
      newState.resultsLocations = locationsNew;
      break;
    case "GET_ADD_TOUR":
      newState.preloadDestinations = true;
      newState.resultAddDestinations = action.value.destinations;
      break;
    case "GET_RESULTS_LOCATIONS_ALL":
      newState.locationsAll = action.value["hydra:member"];
    break;
    case "UPDATE_LOCATIONS":
      newState.resultsLocations = action.locations;
      break;
    case "UPDATE_STATUS_TOURS":
      newState.addingRemoving = action.status;
      newState.addedRemoved = false;
      break;
    case "GET_RESULTS_TOURS_BY_LOCATION":
      newState.locationByTourCount = action.locationByTourCount;
      newState.resultsLocations = action.locations;
      newState.locationFilter = action.filters;
      newState.init = true;
      newState.user = action.user;
      newState.addingRemoving = false;
      newState.addedRemoved = true;
      break;
    case "GET_RESULTS_MY_TOURS_BY_ID":
      newState.locationByTourCount = action.locationByTourCount;
      newState.resultsMyToursById = action.locations;
      newState.locationFilter = action.filters;
      newState.init = true;
      newState.user = action.user;
      newState.addingRemoving = false;
      newState.addedRemoved = true;
      break;
    case "GET_RESPONSE_DOWNLOAD_DOCUMENT":
      newState.downloadDocument = action.value;
      break;
    case "GET_RESPONSE_DOWNLOAD_EXPENSES":
      newState.downloadExpenses = action.value;
      break;
    case "GET_RESULTS_ADD_DOCUMENTS":
      newState.resultsDocuments = action.files;
      break;
    case "RESULTS_INTERESTS":
      newState.resultsInterests = action.value["hydra:member"];
      break;

    case "RESULTS_INTEREST":
      let newInterest = [];
      newState.resultsInterests.forEach(int => {
        newInterest.push(int);
      });
      let some = newState.resultsInterests.some(
        e => e.name === action.value.name
      );
      if (some === false) {
        newInterest.push(action.value);
      }
      newState.resultsInterests = newInterest;
      newState.addInterestValue = action.value;
      break;
    case "GET_RESULTS":
      newState.resultsDocumentsList = action.value;
      newState.preload = true;
      break;

    case "GET_RESULTS_GUIDE_PAYMENTS":
      newState.guidePaymentsList = action.value;
      break;

    case "GUIDE_PROFILE_PIC_UPLOADED_DATA":
      newState.guideProfilePicData = action.value;
      break;

    default:
      break;
  }

  return newState;
};

export default reducer;
