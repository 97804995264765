import React, { Component } from "react";
import { Grid, Typography, Button, Link, Container } from "@material-ui/core";
import Email from "../../common/fields/Email";
import { forgot } from "../../../services/LoginService";
import { Alert, AlertTitle } from "@material-ui/lab";

export default class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseForgot: {},
      errors: {
        status: {
          user: false
        },
        text: {
          user: ""
        }
      }
    };
  }

  send = async () => {
    let form = {};
    let countValid = 0;
    let errors = [];

    if (this.user && this.user !== null) {
      if (this.user.state.defaultValue !== "") {
        countValid++;
        form[this.user.props.name] = this.user.state.defaultValue;
      }
    }

    errors = {
      status: {
        user:
          this.user &&
          this.user.state.defaultValue !== "" &&
          this.user.state.errorText === ""
            ? false
            : true
      },
      text: {
        user:
          this.user &&
          this.user.state.defaultValue !== "" &&
          this.user.state.errorText === ""
            ? ""
            : "Enter an email adress"
      }
    };

    this.setState({ errors: errors });

    if (1 === countValid) {
      await forgot(form)
        .then(res => {
          this.setState({ responseForgot: res });
        })
        .catch(err => {
          this.setState({ responseForgot: false });
        });
    }
  };

  render() {
    return this.state.responseForgot &&
      Object.keys(this.state.responseForgot).length > 0 ? (
      <Container maxWidth="md" className="mx-auto">
        <div className="login_container">
          <div className="form-header">
            <Typography component="h1" variant="h4" className="title">
              Check your inbox
            </Typography>
          </div>
          <div className="form-content">
            <Alert severity="info" className="mb-4">
              {this.state.responseForgot}
            </Alert>
          </div>
        </div>
      </Container>
    ) : (
      <Container maxWidth="md" className="mx-auto">
        <div className="login_container">
          <div className="form-header">
            <Typography component="h1" variant="h4" className="title">
              Forgot your Password?
            </Typography>
            <Typography component="h2" variant="h5" className="subtitle">
              Use the form below to recover it.
            </Typography>
          </div>
          <form className="form" noValidate>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <Email
                  ref={element => {
                    this.user = element;
                  }}
                  id="email"
                  label="Email"
                  name="email"
                  placeholder="Email here..."
                  defaultValue={""}
                  disabled={false}
                  error={this.state.errors.status.user}
                  errorText={this.state.errors.text.user}
                  classNameCustom={"textField"}
                  autoComplete="email"
                  required={true}
                />
              </Grid>
            </Grid>
            {this.state.responseForgot === false && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                We could not find an account associated with that email address.
              </Alert>
            )}
            <div>
              <Button
                component={Link}
                href={"/login"}
                variant="text"
                className="mr-1"
                size="large"
              >
                Sign in to an existing account
              </Button>

              <Button
                onClick={() => this.send()}
                variant="contained"
                color="primary"
                className="submit"
                size="large"
              >
                Reset my password
              </Button>
            </div>
          </form>
        </div>
      </Container>
    );
  }
}
