const initialState = {
    imagesGallery: [],
    imageUrl: {},
    reloadGallery: false,
    preload: false
  };
  
  const reducer = (state = initialState, action) => {
    const newState = { ...state };
  
    switch (action.type) {
      case "RESPONSE_GALLERY_LIST":
        newState.imagesGallery = action.value;
        newState.reloadGallery = false;
        newState.preload = true;
        break;
      case "RESPONSE_ADD_MULTIMEDIA_GALLERY":
        newState.reloadGallery = true;
        newState.preload = false;
        break;
      case "RESPONSE_MULTIMEDIA":
        newState.imageUrl = action.value;
        newState.reloadGallery = false;
        newState.preload = false;
        break;
      default:
        break;
    }
    return newState;
  };
  
  export default reducer;