import React , { Component, Fragment } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default class Numbers extends Component {

    constructor(props){
        super(props);
        this.state={
            errorText:props.errorText,
            error:props.error,
            defaultValue:this.props.defaultValue,
            focus: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    UNSAFE_componentWillReceiveProps(next_props) {

        if(next_props.initial !== undefined && next_props.initial){
            this.setState({ error: false, errorText:'',defaultValue:next_props.defaultValue });
        }else{
            this.setState({ error: next_props.error, errorText: next_props.errorText});
        }
    }

    handleChange = (event) =>{

        if(this.props.handleError !== undefined){

            if(this.props.handleError){
                if(event.target.value === ''){
                    this.setState({errorText: this.props.placeholder,error:true});
                }else{
                    this.setState({errorText: '',error:false});
                }
            }
        }else{


            if(event.target.value === ''){

                this.setState({errorText: this.props.placeholder,error:true});
                this.setState({defaultValue:event.target.value});
                this.props.handleData(0)
            }else{
                if (event.target.value.match("^[ 0-9-,.']*$")) {

                    if(this.props.onlyFloat){

                        this.setState({defaultValue:event.target.value});
                        this.setState({errorText: '',error:false});
                        this.props.handleData(event.target.value)

                    }
                    else{
                        if ( this.props.minLength > event.target.value ){
                            this.setState({errorText: "Minimum value is " + this.props.minLength, error:true});

                        } else{

                            if ( this.props.maxLength < event.target.value ) {
                                this.setState({ errorText: "Maximum value is " + this.props.maxLength, error:true});
                            }else{
                                this.setState({defaultValue:event.target.value});
                                this.setState({errorText: '',error:false});
                            }
                        }
                    }
                } else {
                    this.setState({errorText: 'Enter only Numers',error:true, defaultValue: ''});
                }
            }
        }

    }

    onBlur() { setTimeout(() => {if (this.state.focus){this.setState({focus: false});}}, 0) }

    onFocus() { if (!this.state.focus) {this.setState({focus: true});} }

    render() {
        const OnFocusHideFixedElements = withStyles({"@global": {
            "body .headroom":{'@media (max-width: 960px)': {transform: "translate3d(0px, -100%, 0px)!important"}}
        }})(() => null);
        return (
            <Fragment>
            {this.state.focus && <OnFocusHideFixedElements/>}
            <TextField
                fullWidth
                InputLabelProps={{shrink: true}}
                className={`TextField-String ${this.props.classNameCustom}`}
                size={this.props.size}
                variant="outlined"
                error={this.state.error}
                id={this.props.id}
                label={this.props.label}
                placeholder={this.props.placeholder}
                helperText={this.state.errorText}
                onChange={this.handleChange}
                value={this.state.defaultValue}
                name={this.props.name}
                disabled={this.props.disabled}
                required={this.props.required}
                autoComplete={this.props.autoComplete}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyPress={this.props.onKeyPress}
                InputProps={{
                    startAdornment: <Fragment>{this.props.startAdornment && <InputAdornment position="start">{this.props.startAdornment}</InputAdornment>}</Fragment>
                }}
            />
        </Fragment>
        )
    }
}
