import React, { Component, Fragment } from "react";
import config from "./../../../config/config.json";
import { IconButton, Collapse, Container } from "@material-ui/core";
import { enabledPermissions } from "../../../services/enabledPermissions";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      userData: {},
      statusLogin: false,
      cards: [],
      responseForgot: "",
      responseLogin: "",
      openAlert: true
    };
  }

  UNSAFE_componentWillMount = async () => {
    let cardsPermited = [];
    if (window.localStorage.getItem("userData") !== null) {
      let permissionsItems = JSON.parse(window.localStorage.getItem("userData"))
        .roles;
      cardsPermited = enabledPermissions(config.cards, permissionsItems);
      this.setState({ statusLogin: true, cards: cardsPermited });
      this.setState({
        cards: enabledPermissions(config.cards, permissionsItems)
      });
    }

    this.setState({ cards: cardsPermited });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (window.localStorage.getItem("userData") !== null) {
      let permissionsItems = JSON.parse(window.localStorage.getItem("userData"))
        .roles;
      this.setState({
        cards: enabledPermissions(config.cards, permissionsItems)
      });
    }
  }

  collapseAlert = () => {
    this.setState({ openAlert: false });
  };

  componentDidMount() {
    // Google Tag Manager
    const scriptGoogle = document.createElement("script");
    scriptGoogle.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER}');`;
    document.head.appendChild(scriptGoogle);

    const noscriptGoogleTagManager = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = "https://www.googletagmanager.com/ns.html?id="+ process.env.REACT_APP_GOOGLE_TAG_MANAGER;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscriptGoogleTagManager.appendChild(iframe);
    document.head.appendChild(noscriptGoogleTagManager);
  }

  render() {
    return (
      <Fragment>
        <Container maxWidth={"xl"} className="container_Dashboard">
          {this.state.responseForgot !== "" && (
            <Collapse in={this.state.openAlert}>
              <Alert
                severity="info"
                className="mb-4"
                variant="filled"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.collapseAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {this.state.responseForgot}
              </Alert>
            </Collapse>
          )}
          {this.state.responseLogin !== "" && (
            <Collapse in={this.state.openAlert}>
              <Alert
                severity="info"
                className="mb-4"
                variant="filled"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.collapseAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {this.state.responseLogin}
              </Alert>
            </Collapse>
          )}
        </Container>
      </Fragment>
    );
  }
}

export default Dashboard;
