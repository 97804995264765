import { takeEvery, put, call } from "redux-saga/effects";
import { saveAs } from "file-saver";
import apiCall from "../services";

const apiUrl = process.env.REACT_APP_API_URL;

function* get(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    let userId = actions.userId["@id"].split("users/");
    let urlUse = actions.query === null ? "" : "&page=" + actions.query;

    const data = yield call(
      apiCall,
      "get",
      apiUrl + "/documents?userId=" + userId[1] + urlUse,
      requestOptions
    );

    yield put({ type: "GET_RESULTS", value: data });
  } catch (error) {
    yield put({ type: "GET_RESULTS", value: error });
  }
}

function* download(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield fetch(
      apiUrl + "/guide-documents/" + actions.id + "/download",
      requestOptions
    ).then(response => response.blob());

    saveAs(response, actions.nameDocument);
  } catch (error) {
    yield put({ type: "GET_RESPONSE_DOWNLOAD_DOCUMENT", value: error });
  }
}

function* add(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers,
      body: actions.data
    };
    const requestOptionsGet = {
      headers: headers
    };

    yield call(
      apiCall,
      "POST",
      apiUrl + "/guide-documents/upload",
      requestOptions
    );

    const responseList = yield call(
      apiCall,
      "get",
      apiUrl + "/documents?userId=" + actions.userId,
      requestOptionsGet
    );

    yield put({ type: "GET_RESULTS", value: responseList });
  } catch (error) {
    yield put({ type: "GET_RESULTS_ADD_DOCUMENTS", value: error });
  }
}
export function* downloadDocument() {
  yield takeEvery("DOWNLOAD_DOCUMENT", download);
}

export function* getDocuments() {
  yield takeEvery("GET_DOCUMENTS", get);
}

export function* addDocuments() {
  yield takeEvery("ADD_DOCUMENTS", add);
}
