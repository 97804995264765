import { takeEvery, put, call, all } from "redux-saga/effects";
import apiCall from "../services";
import { saveAs } from "file-saver";

const apiUrl = process.env.REACT_APP_API_URL;

function* destination(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/destinations/"
        : apiUrl + "/destinations/" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "DESTINATION_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "DESTINATION_RESPONSE", value: error });
  }
}

function* destinations(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/destinations?status=" + actions.status + "&pagination=false",
      requestOptions
    );

    yield put({ type: "DESTINATIONS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "DESTINATIONS_RESPONSE_LIST", value: error });
  }
}

function* add(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/destinations",
      requestOptions
    );

    yield put({ type: "DESTINATIONS_RESPONSE_CREATE", value: response });
  } catch (error) {
    yield put({ type: "DESTINATIONS_RESPONSE_CREATE", value: error });
  }
}

function* edit(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };


    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data.form !== undefined ? actions.data.form : actions.data)
    };

    const createComments = actions.data.comments?.create.map(comment => {
      return call(add_Comments, { access: actions.access, data: comment });
    }) || [];

    const editComments = actions.data.comments?.edit.map(comment => {
      return call(edit_Comments, { access: actions.access, commentId: comment.commentId, data: comment });
    }) || [];


    const response = yield all([
      call(apiCall, "put", apiUrl + "/destinations/" + actions.id, requestOptions),
      ...createComments,
      ...editComments,
    ])

    yield put({ type: "DESTINATIONS_RESPONSE_EDIT", value: response[0] });
  } catch (error) {
    yield put({ type: "DESTINATIONS_RESPONSE_EDIT", value: error });
  }
}

function* comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/comments?objectType=destinations&objectId=" + actions.objectId,
      requestOptions
    );

    yield put({ type: "COMMENTS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_LIST", value: error });
  }
}

function* add_Comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/comments",
      requestOptions
    );

    yield put({ type: "COMMENTS_RESPONSE_POST", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_POST", value: error });
  }
}

function* edit_Comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "put",
      apiUrl + "/comments/" + actions.commentId,
      requestOptions
    );

    yield put({ type: "COMMENTS_RESPONSE_POST", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_POST", value: error });
  }
}

function* tours(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/tours?destinationId=" +
        actions.destinationId +
        "&pagination=false",
      requestOptions
    );

    yield put({ type: "TOURS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "TOURS_RESPONSE_LIST", value: error });
  }
}

function* attractions(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/attractions?destinationId=" +
        actions.destinationId +
        "&pagination=false",
      requestOptions
    );

    yield put({
      type: "ATTRACTION_RESPONSE_DESTINATIONS_LIST",
      value: response
    });
  } catch (error) {
    yield put({ type: "ATTRACTION_RESPONSE_DESTINATIONS_LIST", value: error });
  }
}


function* downloadreport_Cities(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    const response = yield fetch(
      apiUrl + `/destination-export`,
      requestOptions
    ).then(response => response.blob());

    let name = 'Cities_Report_' + actions.date + '.xlsx';
    saveAs(response, name);
} catch (error) {}
}

function* loggings(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/changelogs?objectType=" +
        actions.objectType +
        "&objectId=" +
        actions.objectId,
      requestOptions
    );

    yield put({ type: "LOGGINGS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "LOGGINGS_RESPONSE_LIST", value: error });
  }
}

export function* getDestination() {
  yield takeEvery("GET_DESTINATION", destination);
}
export function* getDestinationsList() {
  yield takeEvery("GET_DESTINATIONS", destinations);
}

export function* addDestination() {
  yield takeEvery("ADD_DESTINATION", add);
}

export function* editDestination() {
  yield takeEvery("EDIT_DESTINATION", edit);
}

export function* getCommentsList() {
  yield takeEvery("GET_COMMENTS", comments);
}

export function* getToursListDestination() {
  yield takeEvery("GET_TOURS_LIST", tours);
}

export function* getAttractionsListDestination() {
  yield takeEvery("GET_ATTRACTIONS_LIST_DESTINATION", attractions);
}

export function* getLoggingsListDestination() {
  yield takeEvery("GET_LOGGINGS_LIST", loggings);
}

export function* downloadreportCities() {
  yield takeEvery("DOWNLOADREPORTCITIES", downloadreport_Cities);
}
