import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";
import { saveAs } from "file-saver";

const apiUrl = process.env.REACT_APP_API_URL;

function* get_GuidesList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/users?roles=ROLE_GUIDE&page=" + actions.page
        : apiUrl + "/users?roles=ROLE_GUIDE&" + actions.query;

    const data = yield call(apiCall, "GET", url, requestOptions);

    yield put({ type: "RESULTS_GUIDES", value: data });
  } catch (error) {
    yield put({ type: "RESULTS_GUIDES", value: error });
  }
}

function* get_Guide(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const data = yield call(
      apiCall,
      "GET",
      apiUrl + "/users/" + actions.id,
      requestOptions
    );

    yield put({ type: "RESULTS_GUIDE_SHOW", value: data });
  } catch (error) {
    yield put({ type: "RESULTS_GUIDE_SHOW", value: error });
  }
}

function* edit_Guide(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };



    const response = yield call(
      apiCall,
      "PUT",
      apiUrl + "/users/" + actions.id,
      requestOptions
    );
    yield put({ type: "GUIDE_RESPONSE_EDIT", value: response });
  } catch (error) {
    yield put({ type: "GUIDE_RESPONSE_EDIT", value: error });
  }
}
function* getGuide_Opportunities(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

      if (actions.opportunityStatus === 'ALL') {
        actions.opportunityStatus = 'OPEN,PENDING';
    }

    let url = apiUrl + "/bookings?bookingType=opportunities&opportunityStatus=" + actions.opportunityStatus;

    if (actions.finalQuery) {
      url = apiUrl + "/bookings?" + actions.finalQuery;
    } else {
      if (actions.userId) {
        url = url + "&userId=" + actions.userId;
      }
      if (actions.page) {
        url = url + "&page=" + actions.page;
      }
      if (actions.query) {
        url = url + actions.query
      }
    }

    const data = yield call(
      apiCall,
      "GET",
      url,
      requestOptions
    );

    yield put({ type: "RESULTS_OPPORTUNITIES", value: data });
  } catch (error) {
    yield put({ type: "RESULTS_OPPORTUNITIES", value: error });
  }
}

function* getGuide_Upcoming(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    let url = apiUrl + "/bookings?bookingType=upcoming";

    if (actions.finalQuery) {
      url = apiUrl + "/bookings?" + actions.finalQuery;
    } else {
      if (actions.userId) {
        url = url + "&userId=" + actions.userId;
      }
      if (actions.page) {
        url = url + "&page=" + actions.page;
      }
      if (actions.query) {
        url = url + actions.query
      }
    }

    const data = yield call(
      apiCall,
      "GET",
      url,
      requestOptions
    );
    yield put({ type: "RESULTS_UPCOMING", value: data });
  } catch (error) {
    yield put({ type: "RESULTS_UPCOMING", value: error });
  }
}
function* put_OpportunitiesStatus(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.interest)
    };

    const response = yield call(
      apiCall,
      "PUT",
      apiUrl + "/bookings/opportunities/" + actions.id,
      requestOptions
    );
    yield put({ type: "PRELOAD", value: response });
  } catch (error) {
    yield put({ type: "PRELOAD", value: error });
  }
}

function* get_download_file(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    let url = apiUrl + "/bookings/"+actions.bookingId+"/tickets/"+actions.ticketId;

    const response = yield fetch(
      url,
      requestOptions
    ).then(response => response.blob());

    saveAs(response, actions.name);

  } catch (error) {

  }
}

function* interests(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const data = yield call(
      apiCall,
      "get",
      apiUrl + "/interests?userId=" + actions.userId,
      requestOptions
    );

    yield put({ type: "RESULTS_INTERESTS_GUIDE", value: data });
  } catch (error) {
    yield put({ type: "RESULTS_INTERESTS_GUIDE", value: error });
  }
}

function* get_Payments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const data = yield call(
      apiCall,
      "get",
      apiUrl + "/guides/" + actions.userId + "/payment-method",
      requestOptions
    );

    yield put({ type: "RESULTS_PAYMENT_METHOD", value: data });
  } catch (error) {
    yield put({ type: "RESULTS_PAYMENT_METHOD", value: error });
  }
}
function* get_ImpersonateGuide(actions) {

  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    let id = {};
    id['userId'] = actions.id;

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(id)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/impersonate-guide",
      requestOptions
    );

    yield put({ type: "IMPERSONATE_GUIDE", value: response });
  } catch (error) {
    yield put({ type: "IMPERSONATE_GUIDE", value: error });
  }
}
function* get_Destination(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/destinations?status= DRAFT,PUBLISHED,UNPUBLISHED&pagination=false",
      requestOptions
    );


    yield put({ type: "DESTINATION_GUIDE_RESPONSE_LIST", value: response });
  } catch (error) {
   yield put({ type: "DESTINATION_GUIDE_RESPONSE_LIST", value: error });
  }
}
function* download_guides(actions) {

  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield fetch(
      apiUrl +  "/guides/export" + actions.query,

      requestOptions
    ).then(response => response.blob());
    
    saveAs(response, actions.nameDocument + '.xlsx');
    yield put({ type: "EXPORT_GUIDES", value: response });
  } catch (error) {

  }
}
function* get_GuidesHistoryList (actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/changelogs?objectType=" +
        actions.objectType +
        "&objectId=" +
        actions.objectId,
      requestOptions
    );

    yield put({ type: "LOGGINGS_RESPONSE_LIST_GUIDES", value: response });
  } catch (error) {
    yield put({ type: "LOGGINGS_RESPONSE_LIST_GUIDES", value: error });
  }
}


export function* getGuidesList() {
  yield takeEvery("GET_GUIDES_LIST", get_GuidesList);
}

export function* getGuide() {
  yield takeEvery("GET_GUIDE", get_Guide);
}

export function* editGuide() {
  yield takeEvery("EDIT_GUIDE", edit_Guide);
}

export function* getGuideOpportunities() {
  yield takeEvery("GET_GUIDE_OPPORTUNITIES", getGuide_Opportunities);
}

export function* getGuideUpcoming() {
  yield takeEvery("GET_GUIDE_UPCOMING", getGuide_Upcoming);
}

export function* putOpportunitiesStatus() {
  yield takeEvery("PUT_OPPORTUNITIES_STATUS", put_OpportunitiesStatus);
}

export function* downloadFile() {
  yield takeEvery("DOWNLOAD_FILE", get_download_file);
}

export function* getInterestsGuide() {
  yield takeEvery("GET_INTERESTS_GUIDE", interests);
}

export function* getPayments() {
  yield takeEvery("GET_PAYMENTS", get_Payments);
}

export function* getImpersonateGuide() {
  yield takeEvery("GET_IMPERSONATE_GUIDE", get_ImpersonateGuide);
}

export function* getDestinationGuide() {
  yield takeEvery("GET_DESTINATION_TREE", get_Destination);
}

export function* downloadGuides() {
  yield takeEvery("DOWNLOAD_GUIDES", download_guides);
}


export function* getGuidesHistoryList() {
  yield takeEvery("GET_GUIDES_HISTORY_LIST", get_GuidesHistoryList);
}

