import React, { Component, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default class Password extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorText: props.errorText,
            error: props.error,
            defaultValue: this.props.defaultValue,
            focus: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        this.setState({ error: next_props.error, errorText: next_props.errorText });
    }

    handleChange = (event) => {
        this.setState({ defaultValue: event.target.value });
        if (event.target.value === '') {
            this.setState({ errorText: this.props.errorText, error: true });
        } else {
            this.setState({ errorText: '', error: false });
        }
    }

    onBlur() { setTimeout(() => { if (this.state.focus) { this.setState({ focus: false }); } }, 0) }

    onFocus() { if (!this.state.focus) { this.setState({ focus: true }); } }

    render() {
        const OnFocusHideFixedElements = withStyles({
            "@global": {
                "body .headroom": { '@media (max-width: 960px)': { transform: "translate3d(0px, -100%, 0px)!important" } }
            }
        })(() => null);
        return (
            <Fragment>
                {this.state.focus && <OnFocusHideFixedElements />}
                <TextField
                    type="password"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className={`TextField-Password ${this.props.classNameCustom}`}
                    size={this.props.size}
                    variant="outlined"
                    error={this.state.error}
                    id={this.props.id}
                    label={this.props.label}
                    placeholder={this.props.placeholder}
                    helperText={this.state.errorText}
                    onChange={this.handleChange}
                    value={this.state.defaultValue}
                    name={this.props.name}
                    disabled={this.props.disabled}
                    required={this.props.required}
                    autoComplete={this.props.autoComplete}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onKeyPress={this.props.onKeyPress}
                />
            </Fragment>
        )
    }
}
