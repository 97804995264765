import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";
import { saveAs } from "file-saver";

const apiUrl = process.env.REACT_APP_API_URL;

const responseTour = {
  "@context": "/contexts/DistributionTasks",
  "@id": "/partners",
  "@type": "hydra:Collection",
  "hydra:member": [{
      "tourId": 33,
      "tourTitle": "A Morning at the Zoo",
      "tourCity": "Singapore",
      "children": [{
          "id": 43,
          "partnerId": 1,
          "partnerName": "Gatwick Airport",
          "contentFormat": "CSV",
          "contentStatus": "COMPLETE",
          "availabilityFormat": "API",
          "availabilityStatus": "IN PROGRESS",
          "pricingFormat": "CSV",
          "pricingStatus": "PENDING",
          "createdAt": "2023-03-08 18:52:34",
          "updatedAt": null,
          "@id": "/distributiontask/43",
          "@type": "DistributionTask"
      }, {
          "id": 44,
          "partnerId": 2,
          "partnerName": "Gatwick",
          "contentFormat": "CSV",
          "contentStatus": "PENDING",
          "availabilityFormat": "API",
          "availabilityStatus": "COMPLETE",
          "pricingFormat": "CSV",
          "pricingStatus": "IN PROGRESS",
          "createdAt": "2023-03-08 18:52:34",
          "updatedAt": null,
          "@id": "/distributiontask/43",
          "@type": "DistributionTask"
      }]
  }, {
      "tourId": 30,
      "tourTitle": "A Morning",
      "tourCity": "Spain",
      "children": [{
          "id": 43,
          "partnerId": 1,
          "partnerName": "Gatwick Airport",
          "contentFormat": "CSV",
          "contentStatus": "COMPLETE",
          "availabilityFormat": "API",
          "availabilityStatus": "IN PROGRESS",
          "pricingFormat": "CSV",
          "pricingStatus": "PENDING",
          "createdAt": "2023-03-08 18:52:34",
          "updatedAt": null,
          "@id": "/distributiontask/43",
          "@type": "DistributionTask"
      }, {
          "id": 44,
          "partnerId": 2,
          "partnerName": "Gatwick",
          "contentFormat": "CSV",
          "contentStatus": "PENDING",
          "availabilityFormat": "API",
          "availabilityStatus": "COMPLETE",
          "pricingFormat": "CSV",
          "pricingStatus": "IN PROGRESS",
          "createdAt": "2023-03-08 18:52:34",
          "updatedAt": null,
          "@id": "/distributiontask/43",
          "@type": "DistributionTask"
      }]
  }],
  "hydra:totalItems": 3,
  "hydra:view": {
      "@id": "/distribution-tasks?visible=trueu0026page=1",
      "@type": "hydra:PartialCollectionView"
  },
  "hydra:search": {
      "@type": "hydra:IriTemplate",
      "hydra:template": "/partners{?status,status[],name,name[]}",
      "hydra:variableRepresentation": "BasicRepresentation",
      "hydra:mapping": [{
          "@type": "IriTemplateMapping",
          "variable": "status",
          "property": "status",
          "required": false
      }, {
          "@type": "IriTemplateMapping",
          "variable": "status[]",
          "property": "status",
          "required": false
      }, {
          "@type": "IriTemplateMapping",
          "variable": "name",
          "property": "name",
          "required": false
      }, {
          "@type": "IriTemplateMapping",
          "variable": "name[]",
          "property": "name",
          "required": false
      }]
  }
}

const responsePartner = {
  "@context": "/contexts/DistributionTasks",
  "@id": "/partners",
  "@type": "hydra:Collection",
  "hydra:member": [{
      "partnerName": "Travel Curious",
      "partnerId": 1,
      "children": [{
          "id": 1,
          "status": "PENDING",
          "shortCode": "T09EPY",
          "tourTitle": "Off-the-Beaten-Path Florence",
          "tourCity": "Florence",
          "contentFormat": "CSV",
          "contentStatus": "COMPLETE",
          "availabilityFormat": "CSV",
          "availabilityStatus": "PENDING",
          "pricingFormat": "Console",
          "pricingStatus": "IN PROGRESS",
          "createdAt": "2023-03-07",
          "updatedAt": "2023-03-08"
      }, {
          "id": 2,
          "status": "PENDING",
          "shortCode": "T09EPY",
          "tourTitle": "Off-the-Beaten-Path",
          "tourCity": "Florence",
          "contentFormat": "CSV",
          "contentStatus": "PENDING",
          "availabilityFormat": "CSV",
          "availabilityStatus": "PENDING",
          "pricingFormat": "Console",
          "pricingStatus": "IN PROGRESS",
          "createdAt": "2023-03-07",
          "updatedAt": "2023-03-08"
      }]
  }, {
      "partnerName": "Travel Curioussss",
      "partnerId": 2,
      "children": [{
          "id": 1,
          "status": "PENDING",
          "shortCode": "T09EPY",
          "tourTitle": "Off-the-Beaten-Path Florence",
          "tourCity": "Florence",
          "contentFormat": "CSV",
          "contentStatus": "COMPLETE",
          "availabilityFormat": "CSV",
          "availabilityStatus": "PENDING",
          "pricingFormat": "Console",
          "pricingStatus": "IN PROGRESS",
          "createdAt": "2023-03-07",
          "updatedAt": "2023-03-08"
      }, {
          "id": 1,
          "status": "PENDING",
          "shortCode": "T09EPY",
          "tourTitle": "Off-the-Beaten-Path",
          "tourCity": "Florence",
          "contentFormat": "CSV",
          "contentStatus": "PENDING",
          "availabilityFormat": "CSV",
          "availabilityStatus": "PENDING",
          "pricingFormat": "Console",
          "pricingStatus": "IN PROGRESS",
          "createdAt": "2023-03-07",
          "updatedAt": "2023-03-08"
      }]
  }],
  "hydra:totalItems": 3,
  "hydra:view": {
      "@id": "/distribution-tasks?visible=trueu0026page=1",
      "@type": "hydra:PartialCollectionView"
  },
  "hydra:search": {
      "@type": "hydra:IriTemplate",
      "hydra:template": "/partners{?status,status[],name,name[]}",
      "hydra:variableRepresentation": "BasicRepresentation",
      "hydra:mapping": [{
          "@type": "IriTemplateMapping",
          "variable": "status",
          "property": "status",
          "required": false
      }, {
          "@type": "IriTemplateMapping",
          "variable": "status[]",
          "property": "status",
          "required": false
      }, {
          "@type": "IriTemplateMapping",
          "variable": "name",
          "property": "name",
          "required": false
      }, {
          "@type": "IriTemplateMapping",
          "variable": "name[]",
          "property": "name",
          "required": false
      }]
  }
}

///tour-distribution-tasks
function* tourDistribution_Tasks(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "post",
      apiUrl + "/tour-distribution-tasks",
      requestOptions
    )

    yield put({ type: "TOUR_DISTRIBUTION_TASKS_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "TOUR_DISTRIBUTION_TASKS_RESPONSE", value: error });
  }
}

function* partnerUpdates(actions) {
  try {
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/tour-distribution-tasks?page=1"
        : apiUrl + "/tour-distribution-tasks?" + actions.query;

    let response = null;

    if (url.includes("groupBy=tour")) {
      response = responseTour;
    } else if (url.includes("groupBy=partner")) {
      response = responsePartner;
    } else {
      response = yield call(
        apiCall,
        "get",
        url,
        requestOptions
      );
    }

    yield put({ type: "PARTNER_UPDATES_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "PARTNER_UPDATES_RESPONSE_LIST", value: error });
  }
}

function* updatePartnerUpdate(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "PUT",
      apiUrl + "/tour-distribution-tasks/" + actions.taskId,
      requestOptions
    );

    yield put({ type: "UPDATE_PARTNER_UPDATE_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "UPDATE_PARTNER_UPDATE_RESPONSE", value: error });
  }
}

function* toursUnlocking(actions) {
  try {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/locked-tours?activeTasks=true&completedTasks=true&page=" + actions.page
        : apiUrl + "/locked-tours?activeTasks=true&completedTasks=true&" + actions.query;

    const response = yield call(
      apiCall,
      "get",
      url,
      requestOptions
    );

    yield put({ type: "TOURS_UNLOCKING_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "TOURS_UNLOCKING_RESPONSE_LIST", value: error });
  }
}

function* toursNoTasks(actions) {
  try {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/locked-tours?activeTasks=false&page=" + actions.page
        : apiUrl + "/locked-tours?activeTasks=false&" + actions.query;

    const response = yield call(
      apiCall,
      "get",
      url,
      requestOptions
    );

    yield put({ type: "TOURS_NO_TASKS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "TOURS_NO_TASKS_RESPONSE_LIST", value: error });
  }
}

function* unlockTour(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "post",
      apiUrl + "/tours/" + actions.tourId + "/unlock",
      requestOptions
    );

    yield put({ type: "UNLOCK_TOUR_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "UNLOCK_TOUR_RESPONSE", value: error });
  }
}

function* partners(actions) {
  try {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/autocomplete/partners",
      requestOptions
    );

    yield put({ type: "PARTNERS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "PARTNERS_RESPONSE_LIST", value: error });
  }
}

function* download(actions) {
  try {

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actions.access}`
      };

      const requestOptions = {
        headers: headers
      };

      const response = yield fetch(
        apiUrl + "/tour-distribution-sheets?" + actions.query,
        requestOptions
      ).then(response => response.blob());

      saveAs(response, actions.name);
  } catch (error) {}
}

export function* getPartnersUpdatesTourDistribution() {
  yield takeEvery("GET_PARTNERS_UPDATES_TOUR_DISTRIBUTION", partnerUpdates);
}

export function* updatePartnerUpdateTourDistribution() {
  yield takeEvery("UPDATE_PARTNERS_UPDATE_TOUR_DISTRIBUTION", updatePartnerUpdate);
}

export function* getToursUnlockingTourDistribution() {
  yield takeEvery("GET_TOURS_UNLOCKING_TOUR_DISTRIBUTION", toursUnlocking);
}

export function* getToursNoTasksTourDistribution() {
  yield takeEvery("GET_TOURS_NO_TASKS_TOUR_DISTRIBUTION", toursNoTasks);
}

export function* unlockTourDistribution() {
  yield takeEvery("UNLOCK_TOUR_DISTRIBUTION", unlockTour);
}

export function* getPartnersTourDistribution() {
  yield takeEvery("GET_PARTNERS_TOUR_DISTRIBUTION", partners);
}

export function* downloadDistributionSheet() {
  yield takeEvery("DOWNLOAD_SHEET_TOUR_DISTRIBUTION", download);
}

export function* tourDistributionTasks() {
  yield takeEvery("TOUR_DISTRIBUTION_TASKS", tourDistribution_Tasks);
}



