import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";
const apiUrl = process.env.REACT_APP_API_URL;

function* interests(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const data = yield call(
      apiCall,
      "get",
      apiUrl + "/interests?userId=" + actions.userId,
      requestOptions
    );

    yield put({ type: "RESULTS_INTERESTS", value: data });
  } catch (error) {
    yield put({ type: "RESULTS_INTERESTS", value: error });
  }
}

function* interest(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const interestRequest = {
      name: actions.value
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(interestRequest)
    };

    const data = yield call(
      apiCall,
      "post",
      apiUrl + "/interests",
      requestOptions
    );

    yield put({ type: "RESULTS_INTEREST", value: data });
  } catch (error) {
    yield put({ type: "RESULTS_INTEREST", value: error });
  }
}

export function* getInterests() {
  yield takeEvery("GET_INTERESTS", interests);
}

export function* addInterest() {
  yield takeEvery("ADD_INTEREST", interest);
}
