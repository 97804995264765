const initialState = {
  preload: false,
  loadingExport: false,
  resultGuidesList: [],
  resultGuide: [],
  resultsInterests: [],
  resultsPaymentMethod:[],
  resultsImpersonateGuide:[],
  resultsDestinationGuide:[],
  resultsLoggingsListGuides: []


};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "LOGGINGS_RESPONSE_LIST_GUIDES":
      newState.resultsLoggingsListGuides = action.value;
      newState.preload = false;
      break;

    case "DESTINATION_GUIDE_RESPONSE_LIST":
      newState.resultsDestinationGuide = action.value;
      break;

    case "EXPORT_GUIDES":
      newState.loadingExport = true;
      setTimeout(() => {
        newState.loadingExport = false;
      }, 1000);
      break;

    case "IMPERSONATE_GUIDE":
      newState.resultsImpersonateGuide = action.value;
      break;
    case "RESULTS_PAYMENT_METHOD":
      newState.resultsPaymentMethod = action.value;
      break;
    case "RESULTS_INTERESTS_GUIDE":
      newState.resultsInterests = action.value["hydra:member"];
      break;
    case "RESULTS_GUIDES":
      newState.resultGuidesList = action.value;
      break;
    case "RESULTS_GUIDE_SHOW":
      newState.resultGuide = action.value;
      newState.preload =  true;
      break;
    case "GUIDE_RESPONSE_EDIT":
      newState.preload =  false;
      newState.back = true;
      break;
    default:
      break;
  }

  return newState;
};

export default reducer;
