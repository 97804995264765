const initialState = {
  resultPartner: {},
  resultsPartnersUsersList: {},
  resultsPartnersList: [],
  resultsLoggingsListPartners: [],
  preload: false,
  resultsLoggingsListBooking: [],

};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "LOGGINGS_RESPONSE_LIST_PARTNERS":
      newState.resultsLoggingsListPartners = action.value;
      newState.preload = false;
      break;
    case "LOGGINGS_RESPONSE_LIST_BOOKING":
      newState.resultsLoggingsListBooking = action.value;
      newState.preload = false;
      break;
    case "PARTNER_USERS_RESPONSE":
      newState.resultsPartnersUsersList = action.value;
      break;
    case "PARTNER_RESPONSE":
      newState.resultPartner = action.value;
      newState.preload = false;
      break;
    case "PARTNERS_RESPONSE_LIST":
      newState.resultsPartnersList = action.value;
      newState.preload = false;
      break;
    case "PARTNERS_RESPONSE_CREATE":
      newState.preload = false;
      newState.back = true;
      break;
    case "PARTNERS_RESPONSE_EDIT":
      newState.preload = false;
      newState.back = true;
      break;
    case "RESPONSE_MULTIMEDIA":
      newState.preload = false;
      newState.imageUrl = action.value;
      break;
    case "DESTINATION_TREE_RESPONSE_LIST":
      newState.preload = false;
      newState.destinationTreeList = action.value;
      break;
    case "TOURS_DESTINATION_RESPONSE_LIST":
      newState.preload = true;
      newState.tourByDestinationList = action.value;
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
