import { all } from "redux-saga/effects";
import {
  getGuidesList,
  getGuide,
  editGuide,
  getGuideOpportunities,
  getGuideUpcoming,
  putOpportunitiesStatus,
  getInterestsGuide,
  downloadFile,
  getPayments,
  getImpersonateGuide,
  getDestinationGuide,
  downloadGuides,
  getGuidesHistoryList
} from "./sagaGuides";

import {
  getFinanceList,
  addFinanceItem,
  revisionItem,
  getPayoutRunList,
  getPayoutRunTotal,
  changeDate,
  executePayoutRun,
  getbookingsPayoutRunList,
  getbookingsPayoutRunDetail,
  retrybookingPayoutRun,
  getDownloadSummary,
  getDownloadFullList,
  getPayoutRunExcludedList,
  putPayoutRunExcludedList,
  downloadPayoutRunFile
} from "./sagaFinance";

import {
  tours,
  deleteTour,
  addTour,
  locationsAll,
  deleteLocation,
  addLocation,
  updateStatus,
  toursByLocation,
  getBookingExpenses,
  addBookingExpenses,
  downloadExpensesFile,
  deleteExpenses,
  editExpenses,
  submitForReviewStatus,
  addPaymentMethod,
  getBookingPaymentMethod,
  addRequestPayouts,
  getGuidePayments,
  editPaymentMethod,
  guideUploadAvatar,
  cleanPaymentMethod

} from "./sagaGuidePortal";
import { downloadDocument, getDocuments, addDocuments } from "./sagaDocument";
import {
  getDestination,
  getDestinationsList,
  addDestination,
  getLoggingsListDestination,
  editDestination,
  getAttractionsListDestination,
  getCommentsList,
  getToursListDestination,
  downloadreportCities
} from "./sagaDestinations";
import {
  getAttraction,
  getAttractionsList,
  addAttraction,
  getAttractionsAuditList,
  editAttraction,
  addMultimediaAttraction,
  editMultimediaAttraction,
  getAttractionsCommentsList,
  addAttractionsComments,
  editAttractionsComments,
  getDestinationsAttractions,
  getAttractionsHistoryList,
} from "./sagaAttractions";
import { getInterests, addInterest } from "./sagaProfile";
import {
  getDestinationsAttractionsTours,
  getPartnersTours,
  getGuidesTours,
  addAttractionTour,
  editAttractionTour,
  getAttractionTour,
  getToursCommentsList,
  getToursHistoryList,
  getToursAuditList,
  getAttractionsToursList,
  searchTourAttractions,
  getGuides,
  downloadreportTour,
} from "./sagaTours";
import {
  getTicket,
  getTicketList,
  editTicket,
  getDestinationMappingList,
  editDestinationMapping,
  getDestinationsMapping,
  getProvidersList,
  getCategoryList,
  getCategoryMappingList,
  editCategoryMapping,
  getVenueMappingList,
  editVenueMapping,
  getVenuesMapping,
  getBookingList,
  addNewCategory,
  getBooking,
  editBooking,
  getAttractionMapping,
  getBookingSearchList,
  getBookingSearch,
  getPrebooking,
  getBookingComplete,
  getProductsDestination
} from "./sagaTickets";
import {
  addMultimedia,
  getGallery,
  addMultimediaGallery
} from "./sagaMultimedia";
import {
  getPartner,
  getPartnersList,
  addPartner,
  editPartner,
  getDestinationTree,
  getToursByDestination,
  downloadDistributionSheetPartner,
  addMultimediaPartner,
  downloadPartnerTourList,
  getUsersPartner,
  getPartnersHistoryList
} from "./sagaPartners";
import {
  getTourBooking,
  getTourBookingsList,
  addTourBooking,
  editTourBooking,
  uploadFile,
  downloadFile as downloadFileTourBooking,
  downloadBookings,
  getTourBookingMessages,
  getTourBookingMessageContent,
  sendTourBookingMessage,
  getToursTourBooking,
  getPartnerTourBooking,
  getTourBookingsCandidates,
  getBookingHistoryList,
  getPartnersbooking,
  getToursRate
} from "./sagaTourBookings";
import {
  getPartnersUpdatesTourDistribution,
  updatePartnerUpdateTourDistribution,
  getToursUnlockingTourDistribution,
  getToursNoTasksTourDistribution,
  unlockTourDistribution,
  getPartnersTourDistribution,
  downloadDistributionSheet,
  tourDistributionTasks
} from "./sagaTourDistribution";
import { getFulfillmentDashboardList } from "./sagaFulfillmentDashboard";

export default function* rootSaga() {
  yield all([
    tours(),
    deleteTour(),
    addTour(),
    locationsAll(),
    deleteLocation(),
    addLocation(),
    toursByLocation(),
    updateStatus(),
    downloadDocument(),
    getDocuments(),
    addDocuments(),
    getInterests(),
    addInterest(),
    getDestination(),
    getDestinationsList(),
    addDestination(),
    editDestination(),
    getCommentsList(),
    getToursListDestination(),
    getLoggingsListDestination(),
    getAttraction(),
    getAttractionsList(),
    addAttraction(),
    getAttractionsAuditList(),
    editAttraction(),
    getAttractionsListDestination(),
    editMultimediaAttraction(),
    getAttractionsCommentsList(),
    addAttractionsComments(),
    editAttractionsComments(),
    getDestinationsAttractions(),
    addMultimediaAttraction(),
    getAttractionsHistoryList(),
    getToursCommentsList(),
    getToursHistoryList(),
    getToursAuditList(),
    getAttractionsToursList(),
    getAttractionTour(),
    editAttractionTour(),
    addAttractionTour(),
    getDestinationsAttractionsTours(),
    getPartnersTours(),
    getGuidesTours(),
    searchTourAttractions(),
    getTicket(),
    getTicketList(),
    editTicket(),
    getDestinationMappingList(),
    editDestinationMapping(),
    getDestinationsMapping(),
    getProvidersList(),
    getCategoryList(),
    getCategoryMappingList(),
    editCategoryMapping(),
    getVenueMappingList(),
    editVenueMapping(),
    getVenuesMapping(),
    getBookingList(),
    getBooking(),
    editBooking(),
    addNewCategory(),
    getAttractionMapping(),
    getBookingSearchList(),
    getBookingSearch(),
    getPrebooking(),
    getBookingComplete(),
    getProductsDestination(),
    getBookingExpenses(),
    addBookingExpenses(),
    downloadExpensesFile(),
    deleteExpenses(),
    editExpenses(),
    submitForReviewStatus(),
    addPaymentMethod(),
    getFinanceList(),
    addFinanceItem(),
    revisionItem(),
    getPayoutRunList(),
    getPayoutRunTotal(),
    changeDate(),
    getBookingPaymentMethod(),
    addRequestPayouts(),
    executePayoutRun(),
    getbookingsPayoutRunList(),
    getbookingsPayoutRunDetail(),
    retrybookingPayoutRun(),
    getGuidePayments(),
    editPaymentMethod(),
    guideUploadAvatar(),
    cleanPaymentMethod(),
    getGuidesList(),
    getGuide(),
    editGuide(),
    getGuideOpportunities(),
    getGuideUpcoming(),
    putOpportunitiesStatus(),
    getInterestsGuide(),
    getDownloadSummary(),
    getDownloadFullList(),
    getPayoutRunExcludedList(),
    putPayoutRunExcludedList(),
    downloadFile(),
    addMultimedia(),
    getGallery(),
    addMultimediaGallery(),
    getPartner(),
    getPartnersList(),
    addPartner(),
    editPartner(),
    getTourBooking(),
    getTourBookingsList(),
    addTourBooking(),
    editTourBooking(),
    uploadFile(),
    downloadFileTourBooking(),
    downloadBookings(),
    getTourBookingMessages(),
    getTourBookingMessageContent(),
    sendTourBookingMessage(),
    getToursTourBooking(),
    getPartnerTourBooking(),
    getTourBookingsCandidates(),
    getToursRate(),
    getDestinationTree(),
    getToursByDestination(),
    downloadDistributionSheetPartner(),
    addMultimediaPartner(),
    downloadPartnerTourList(),
    getUsersPartner(),
    getPartnersHistoryList(),
    getFulfillmentDashboardList(),
    getPayments(),
    getPartnersUpdatesTourDistribution(),
    updatePartnerUpdateTourDistribution(),
    getToursUnlockingTourDistribution(),
    getToursNoTasksTourDistribution(),
    unlockTourDistribution(),
    getPartnersTourDistribution(),
    downloadDistributionSheet(),
    getImpersonateGuide(),
    downloadPayoutRunFile(),
    getBookingHistoryList(),
    getPartnersbooking(),
    getDestinationGuide(),
    downloadGuides(),
    getGuidesHistoryList(),
    tourDistributionTasks(),
    getGuides(),
    downloadreportTour(),
    downloadreportCities()
  ]);
}