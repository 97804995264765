const initialState = {
  resultsFulfillmentDashboardList: [],
  preload: false
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "FULFILLMENT_DASHBOARD_RESPONSE_LIST":
      newState.resultsFulfillmentDashboardList = action.value;
      newState.preload = false;
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
