const initialState = {
  resultsPartnerUpdatesList: [],
  resultsToursUnlockingsList: [],
  resultsToursNoTasksList: [],
  resultPartnerList: [],
  resultUpdatePartnerUpdate: {},
  resultUnlockTour: {},
  preloadDistribution: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "TOUR_DISTRIBUTION_TASKS_RESPONSE":
      newState.preloadDistribution = true;
      break;
    case "PARTNER_UPDATES_RESPONSE_LIST":
      newState.resultsPartnerUpdatesList = action.value;
      newState.preloadDistribution = false;
      break;
    case "TOURS_UNLOCKING_RESPONSE_LIST":
      newState.resultsToursUnlockingsList = action.value;
      break;
    case "TOURS_NO_TASKS_RESPONSE_LIST":
      newState.resultsToursNoTasksList = action.value;
      break;
    case "PARTNERS_RESPONSE_LIST":
      newState.resultPartnerList = action.value;
      break;
    case "UPDATE_PARTNER_UPDATE_RESPONSE":
      newState.resultUpdatePartnerUpdate = action.value;
      break;
    case "UNLOCK_TOUR_RESPONSE":
      newState.resultUnlockTour = action.value;
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
