export function enabledTableHeaders(headers, response) {
  let result = [];
  let headersResponse = Object.keys(response[0]);

  if (response.length > 0) {
    headers.forEach(header => {
      if (headersResponse.indexOf(header.key) !== -1) {
        result.push(header.label);
      }
    });
  }

  return result;
}

export function isPermissionEnabled(permissionsItems, value = "") {
  var result = "";

  if (value.indexOf("/") !== -1) {
    let permissionStr = value.split("/");
    let permissionFather = permissionStr[0];
    let permissionAction = permissionStr[1];

    result = permissionsItems.filter(function(permission) {
      return Object.keys(permission)[0] === permissionFather;
    });
    if (result.length > 0) {
      if (result[0][permissionFather].indexOf(permissionAction) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    result = permissionsItems.filter(function(permission) {
      return permission === value;
    });

    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}

export function enabledPermissions(configItems, permissionsItems) {
  const enabledItems = [];

  configItems.forEach(item => {
    const flagSubmenu = item.submenu && item.submenu.length > 0
    let submenu = [];
    if (flagSubmenu) {
      submenu = enabledPermissions(item.submenu, permissionsItems);
    }

    const checkSubmenu = (flagSubmenu && (submenu.length > 0 || item.routing)) || !flagSubmenu;
    const checkPermission = !item.permissionName || (item.permissionName && isPermissionEnabled(permissionsItems, item.permissionName));
    if (checkSubmenu && checkPermission) {
      const itemCopy = {
        ...item,
        submenu: submenu
      }
      enabledItems.push(itemCopy);
    }
  });

  return enabledItems;
}
