const initialState = {
  resultsAttractionsList: [],
  resultsCommentsListAttractions: [],
  resultsLoggingsListAttractions: [],
  resultsAuditsListAttractions: [],
  resultsDestinations: [],
  resultsTicket: [],
  preload: false,
  preloadDestination: true,
  preloadCategory: false,
  resultsTicketList: [],
  resultsProvidersList: [],
  resultsCategoryList: [],
  resultsVenueList: [],
  resultsVenues: [],
  resultsVenueAttractionsSearch: [],
  resultsMappingVenueList: [],
  resultsMappingDestinationList: [],
  resultsMappingCategoryList: [],
  resultBookingList: [],
  resultsBooking: [],
  resultBooking: [],
  resultPreBooking: [],
  resultBookingComplete: [],
  resultEditBooking: [],
  resultProductsDestination: []
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "RESPONSE_TICKET":
      newState.resultsTicket = action.value;
      break;
    case "RESPONSE_TICKET_LIST":
      newState.resultsTicketList = action.value;
      break;
    case "RESPONSE_VENUEMAPPING_LIST":
      newState.resultsVenueList = action.value["hydra:member"];
      newState.preload = true;
      break;
    case "RESPONSE_VENUE_LIST":
      newState.resultsMappingVenueList = action.value;
      newState.preload = false;
      break;
    case "VENUE_MAPPING_RESPONSE_LIST":
      newState.resultsVenues = action.value;
      break;
    case "RESPONSE_BOOKING_LIST":
      newState.resultBookingList = action.value;
      newState.preload = false;
      break;
    case "RESPONSE_BOOKING_GET":
      newState.resultsBooking = action.value;
      newState.preload = true;
      break;
    case "RESPONSE_BOOKING":
      newState.resultBooking = action.value;
      newState.preload = true;
      break;
    case "RESPONSE_PREBOOKING":
      newState.resultPreBooking = action.value;
      newState.preload = true;
      break;
    case "RESPONSE_BOOKINGCOMPLETE":
      newState.resultBookingComplete = action.value;
      newState.preload = true;
      break;
    case "RESPONSE_EDIT_BOOKING":
      newState.resultEditBooking = action.value;
      newState.preload = false;
      break;
    case "RESPONSE_PRODUCTDESTINATION_LIST":
      newState.resultProductsDestination = action.value;
      newState.preload = false;
      break;
    case "RESPONSE_DESTINATIONMAPPING_LIST":
      newState.resultsTicketList = action.value;
      newState.preload = false;
      break;
    case "RESPONSE_DESTINATION_MAPPING_LIST":
      newState.resultsMappingDestinationList = action.value;
      newState.preload = false;
      break;
    case "RESPONSE_CATEGORYMAPPING_LIST":
      newState.resultsMappingCategoryList = action.value;
      newState.preload = false;
      break;
    case "RESPONSEDESTINATIONMAPPING_EDIT":
      newState.preload = true;
      break;
    case "RESPONSE_CATEGORYMAPPING_EDIT":
      newState.preload = true;
      break;
    case "DESTINATION_MAPPING_RESPONSE_LIST":
      newState.resultsDestinations = action.value;
      newState.preload = false;
      break;
    case "RESPONSE_PROVIDER_LIST":
      let providerList = [];
      let provider = action.prov["hydra:member"];

      provider.forEach(prov => {
        providerList.push({
          labelId: prov["@id"],
          label: prov.name,
          name: prov.name
        });
      });
      newState.resultsProvidersList = providerList;
      break;
    case "RESPONSE_CATEGORY_LIST":
      newState.resultsCategoryList = action.value;
      newState.preload = false;
      newState.preloadCategory = false;
      break;
    case "ADD_NEW_CATEGORY":
      newState.preloadCategory = true;
      break;
    case "ATTRACTIONS_MAPPING_RESPONSE_LIST":
      newState.resultsAttractionsList = action.value;
      break;
    case "RESPONSE_VENUESMAPPING_EDIT":
      newState.preload = true;
      break;
    default:
      break;
  }

  return newState;
};
export default reducer;
