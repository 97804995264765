import React, { Component } from "react";
import Cookies from "js-cookie";
import Theme from "../../assets/Theme";
import style from "../../config/style.json";
import { Container, Grid, Hidden } from "@material-ui/core";
import Background from "../../assets/images/home-login.jpg";
import Logo from "../../assets/images/logo-white.png";

export default class MainLogin extends Component {
  constructor(props) {
    super(props);
    this.environmentIdentifier = Cookies.get("env")
      ? Cookies.get("env")
      : window.location.href.split("login?env=")[1];

    this.state = {
      hash: "",
      statesLogin: {
        login: true,
        forgot: false,
        reset: false
      }
    };
  }

  render() {
    return (
      <Theme>
        <div
          styles={style}
          className={
            this.environmentIdentifier === "andy"
              ? `wrapper environmentAndy`
              : this.environmentIdentifier === "staging"
              ? `wrapper environmentStaging`
              : `wrapper environmentProduction`
          }
        >
          <main className={`main login`}>
            <Container disableGutters={true} maxWidth={false} className="h-100">
              <Grid container className="h-100">
                <Hidden smDown>
                  <Grid item md={4}>
                    <div
                      className="bg-login h-100 align-items-center"
                      style={{ backgroundImage: `url(${Background})` }}
                    >
                      <img
                        className="logo mx-auto"
                        src={Logo}
                        alt="Travel Curious"
                      />
                    </div>
                  </Grid>
                </Hidden>
                <Grid item md={8} className="align-items-center d-flex">
                  {this.props.children}
                </Grid>
              </Grid>
            </Container>
          </main>
        </div>
      </Theme>
    );
  }
}
