import { combineReducers } from "redux";
import attractionsReducer from "./attractionsReducer";
import destinationsReducer from "./destinationsReducer";
import guidePortalReducer from "./guidePortalReducer";
import toursReducer from "./toursReducer";
import ticketsReducer from "./ticketsReducer";
import financeReducer from "./financeReducer";
import guidesReducer from "./guidesReducer";
import partnersReducer from "./partnersReducer";
import tourBookingsReducer from "./tourBookingsReducer";
import tourDistributionReducer from "./tourDistributionReducer";
import fulfillmentDashboardReducer from "./fulfillmentDashboardReducer";
import multimediaReducer from "./multimediaReducer";

export default combineReducers({
  attractionsReducer,
  destinationsReducer,
  guidePortalReducer,
  toursReducer,
  ticketsReducer,
  financeReducer,
  guidesReducer,
  partnersReducer,
  tourBookingsReducer,
  tourDistributionReducer,
  fulfillmentDashboardReducer,
  multimediaReducer,
});
