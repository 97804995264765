import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export default class Main extends Component {
  render() {
    const cookie = window.localStorage.getItem("headersSetCookie");
    return cookie === null ? (
      <Redirect to={"/login"} />
    ) : (
      <Redirect to={"/dashboard/"} />
    );
  }
}
