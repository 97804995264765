const initialState = {
  resultAttraction: {},
  resultsAttractionsToursList: [],
  resultsLoggingsListTours: [],
  resultsAuditsListTours: [],
  resultsDestinations: [],
  preload: false,
  resultsCommentsListTours: [],
  resultAttractionTours: [],
  resultsTourAttractions: [],
  resultsTourAttractionsSearch: [],
  resultPartnerList: [],
  resultGuideList: [],
  resultGuides:[],
  resultTourError: null,

};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "GUIDES_RESPONSE":
      newState.resultGuides = action.value;
      break;
    case "ATTRACTIONS_TOURS_EDIT":
      newState.preload = false;
      newState.back = true;

      if (action.value[0]['@context'] === '/contexts/Error'){
        newState.resultTourError = action.value[0];
      }else{
        newState.resultTourError = null
      }
      
      

      break;
    case "ATTRACTIONS_TOURS_CREATE":
      newState.preload = false;
      newState.back = true;
      newState.resultTourError = action.value;
      
      break;
    case "LOGGINGS_RESPONSE_LIST_TOURS":
      newState.resultsLoggingsListTours = action.value;
      newState.preload = false;
      break;
    case "COMMENTS_RESPONSE_LIST_TOURS":
      newState.resultsCommentsListTours = action.value;
      newState.preload = false;
      break;
    case "COMMENTS_RESPONSE_POST_TOURS":
      newState.preload = false;
      break;
    case "AUDIT_RESPONSE_LIST_TOURS":
      newState.resultsAuditsListTours = action.value;
      newState.preload = false;
      break;
    case "AUDIT_RESPONSE_POST_TOURS":
      newState.preload = false;
      break;
    case "ATTRACTION_TOURS_RESPONSE":
      newState.resultAttractionTours = action.value;
      newState.preload = false;
      break;
    case "ATTRACTIONS_TOURS_RESPONSE_GET":
      newState.resultsAttractionsToursList = action.value;
      newState.preload = false;
      break;
    case "DESTINATION_RESPONSE_LIST":
      newState.resultsDestinations = action.value;
      break;
    case "TOUR_ATTRACTIONS_RESPONSE_SEARCH":
      let tourAttraccionSearch = [];
      if (
        action &&
        action.value &&
        action.value["hydra:member"] !== undefined
      ) {
        action.value["hydra:member"].forEach(attraction => {
          tourAttraccionSearch.push({
            id: attraction["@id"],
            name: attraction.base.name,
            description: "",
            destination: attraction.geo.destination,
            status: attraction.base.status
          });
        });
      }
      newState.resultsTourAttractionsSearch = tourAttraccionSearch;
      break;
    case "PARTNERS_RESPONSE_LIST":
      newState.resultPartnerList = action.value;
      break;
    case "GUIDES_RESPONSE_LIST":
      newState.resultGuideList = action.value;
      break;
    default:
      break;
  }

  return newState;
};

export default reducer;
