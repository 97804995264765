import React, { Component } from "react";
import Cookies from "js-cookie";
import {
  Grid,
  Typography,
  FormControlLabel,
  Button,
  Link,
  Checkbox,
  Container,
  CircularProgress
} from "@material-ui/core";
import Password from "../../common/fields/Password";
import Email from "../../common/fields/Email";
import Numbers from "../../common/fields/Numbers";
import { getLogin } from "../../../services/LoginService";
import { Redirect } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";

export default class Login extends Component {
  constructor(props) {
    super(props);
    window.localStorage.setItem("user", "");
    window.localStorage.setItem("headersSetCookie", "");
    sessionStorage.setItem("siderbarO", true);
    sessionStorage.setItem("ticketProductsIncludeTours", false);

    this.environmentIdentifier = Cookies.get("env")
      ? Cookies.get("env")
      : window.location.href.split("login?env=")[1];

    this.state = {
      credentialsError: false,
      responseLogin: {},
      loginCodeMode: null,
      loginCodeMessage: null,
      totpQRCodeData: null,
      loading: false,
      userImpersonateGuide: '',
      passwordImpersonateGuide: '',
      errors: {
        status: {
          user: false,
          password: false,
          loginCode: false
        },
        text: {
          user: "",
          password: "",
          loginCode: ""
        }
      }
    };
  }

  UNSAFE_componentWillMount = () => {
    let urlHost = (window.location.href.split("username="));
    let urlHostUser = '';
    let urlHostPassword = '';

    if (urlHost.length > 1){
      urlHostUser = (urlHost[1].split("&password="))[0];
      urlHostPassword = (urlHost[1].split("&password="))[1];
      this.setState({userImpersonateGuide : urlHostUser, passwordImpersonateGuide: urlHostPassword});
    }
  }

  send = async () => {
    let form = {};
    let countValid = 0;
    let errors = [];

    if (this.user && this.user !== null) {
      if (this.user.state.defaultValue !== "") {
        countValid++;
        form[this.user.props.name] = this.user.state.defaultValue.toLowerCase();
      }
    }

    if (this.password && this.password !== null) {
      if (this.password.state.defaultValue !== "") {
        countValid++;
        form[
          this.password.props.name
        ] = this.password.state.defaultValue;
      }
    }

    if (this.state.loginCodeMode !== null && this.loginCode.state.defaultValue !== ""){
      countValid++;
      form['loginCode'] = this.loginCode.state.defaultValue;
    }
    if (this.state.loginCodeMode !== null){
    errors = {
      status: {
        user:
          this.user &&
          this.user.state.defaultValue !== "" &&
          this.user.state.errorText === ""
            ? false
            : true,
        password:
          this.password &&
          this.password.state.defaultValue !== "" &&
          this.password.state.errorText === ""
            ? false
            : true,
          loginCode:
            this.loginCode &&
            this.loginCode.state.defaultValue !== "" &&
            this.loginCode.state.errorText === ""
            ? false
            : true,

      },
      text: {
        user:
          this.user &&
          this.user.state.defaultValue !== "" &&
          this.user.state.errorText === ""
            ? ""
            : "Enter a valid email / user",
        password:
          this.password &&
          this.password.state.defaultValue !== "" &&
          this.password.state.errorText === ""
            ? ""
            : "Enter a valid password",

      loginCode:
          this.loginCode &&
          this.loginCode.state.defaultValue !== "" &&
          this.loginCode.state.errorText === ""
            ? ""
            : "Enter the login code"
      }
    };
  }else{
    errors = {
      status: {
        user:
          this.user &&
          this.user.state.defaultValue !== "" &&
          this.user.state.errorText === ""
            ? false
            : true,
        password:
          this.password &&
          this.password.state.defaultValue !== "" &&
          this.password.state.errorText === ""
            ? false
            : true
      },
      text: {
        user:
          this.user &&
          this.user.state.defaultValue !== "" &&
          this.user.state.errorText === ""
            ? ""
            : "Enter a valid email / user",
        password:
          this.password &&
          this.password.state.defaultValue !== "" &&
          this.password.state.errorText === ""
            ? ""
            : "Enter a valid password"
      }
    };

  }

    this.setState({ errors: errors });
    window.localStorage.setItem(
      "environmentIdentifier",
      this.environmentIdentifier
    );
    let totalcount = (this.state.loginCodeMode !== null) ? 3 : 2;

    if (totalcount === countValid) {

      this.setState({loading: true})

      await getLogin(form)
        .then(res => {
          this.setState({loading: false})

          let responseLogin = false;
          let credentialsError = false;
          let loginCodeMode = null;
          let loginMessage = null;
          let totpQRCodeData = null;

          if (res.userAuthenticated === true) {
            window.localStorage.setItem("user", res.location);
            window.localStorage.setItem("headersSetCookie", res.headersSetCookie);
            sessionStorage.setItem("siderbarO", true);
            sessionStorage.setItem("ticketProductsIncludeTours", false);
            responseLogin = res;

          } else if (res.error === true ) {

              responseLogin = false;
              credentialsError = true;

              if (res.loginMethod !== undefined) {

                if (res.loginMethod === 'email')  {

                  loginMessage = 'A login code was sent to your email address. Please enter the received valued and click Sign In';
                  loginCodeMode = 'email';

                } else if (res.loginMethod === 'totp')  {

                  loginMessage = 'Please use Google Authenticator (or a compatible App) in your phone to generate a 6-digits access code and enter it below';
                  loginCodeMode = 'totp';

                }
              }

          } else if (res.loginInProcess === true) {
            if (res.loginMethod === 'email')  {
              loginMessage = 'A login code was sent to your email address. Please enter the received valued and click Sign In';
              loginCodeMode = 'email';
              totpQRCodeData = null;
            } else if (res.loginMethod === 'totp')  {
              loginCodeMode = 'totp';
              totpQRCodeData = null;
              if (res.totpQRCode !== undefined) {

                loginMessage = 'Please use Google Authenticator (or a compatible App) in your phone to scan this QR code, generate a 6-digits code and enter it below';
                totpQRCodeData = res.totpQRCode;
              } else {
                loginMessage = 'Please use Google Authenticator (or a compatible App) in your phone to generate a 6-digits access code and enter it below';
              }

            }
          }

          this.setState({
              responseLogin: responseLogin,
              credentialsError: credentialsError,
              loginCodeMessage: loginMessage,
              loginCodeMode: loginCodeMode,
              totpQRCodeData: totpQRCodeData
          });

        })
        .catch(err => {

          this.setState({
            responseLogin: false,
            credentialsError: true,
            loginCodeMessage: null,
            loginCodeMode: null,
            totpQRCodeData: null
          });
        });
    } else {

      window.localStorage.setItem("user", "");
      window.localStorage.setItem("headersSetCookie", "");
      sessionStorage.setItem("siderbarO", false);
      sessionStorage.setItem("ticketProductsIncludeTours", false);
    }
  };

  onKeyPress = e => {

    if (e.key === "Enter") {
      this.send();
    }
  };

  render() {
    return this.state.responseLogin !== false &&
      Object.keys(this.state.responseLogin).length > 0 ? (
        <Redirect to={window.location.href.split("redirect=")[1] !== undefined ? window.location.href.split("redirect=")[1] : "/dashboard/"} exact />
    ) : (
      <Container maxWidth="md" className="mx-auto">
        <div className="login_container">
          <div className="form-header">
            <Typography component="h1" variant="h4" className="title">
              Welcome back,
            </Typography>
            <Typography component="h2" variant="h5" className="subtitle">
              Please sign into your account.
            </Typography>
          </div>

          {(this.state.credentialsError === true && this.state.loginCodeMode === null) && (
            <Alert severity="error" className="mb-4">
              <AlertTitle>Invalid credentials</AlertTitle>
              The username or password you entered are incorrect. Please double-check and try again.
            </Alert>
          )}

          <form className="form" noValidate>
            <Grid container spacing={1}>
              <Grid item md={7}>
                <Email
                  ref={element => {
                    this.user = element;
                  }}
                  id="email"
                  label="Email"
                  name="email"
                  placeholder="Email here..."
                  defaultValue={this.state.userImpersonateGuide !== '' ? this.state.userImpersonateGuide: ''}
                  disabled={false}
                  error={this.state.errors.status.user}
                  errorText={this.state.errors.text.user}
                  classNameCustom={"textField"}
                  autoComplete="email"
                  required={true}
                  onKeyPress={this.onKeyPress}
                />
              </Grid>
              <Grid item md={5}>
                <Password
                  ref={element => {
                    this.password = element;
                  }}
                  id="password"
                  label="Password"
                  name="password"
                  placeholder="Password here..."
                  defaultValue={this.state.passwordImpersonateGuide !== '' ? this.state.passwordImpersonateGuide: ''}
                  disabled={false}
                  error={this.state.errors.status.password}
                  errorText={this.state.errors.text.password}
                  classNameCustom={"textField"}
                  autoComplete="current-password"
                  required={true}
                  onKeyPress={this.onKeyPress}
                />
              </Grid>
            </Grid>

            {/* Login code message and prompt */}

            { this.state.loginCodeMode !== null && (
              <>
                <hr style={{ borderColor: "rgba(21, 65, 83, 0)" }} />

                <Typography component="h2" variant="h6" className="subtitle">
                  {this.state.loginCodeMessage}
                </Typography>
                <hr style={{ borderColor: "rgba(21, 65, 83, 0)" }} />
                <Grid container spacing={1}>

                  <Grid item md={4}>
                    <Numbers
                      ref={element => {
                        this.loginCode = element;
                      }}
                      id="loginCode"
                      label="Login Code"
                      name="loginCode"
                      placeholder="Enter your login code here..."
                      defaultValue={""}
                      disabled={false}
                      error={this.state.errors.status.loginCode}
                      errorText={this.state.errors.text.loginCode}
                      classNameCustom={"textField"}
                      required={true}
                      onKeyPress={this.onKeyPress}
                    />
                  </Grid>
                  <Grid item md={4}></Grid>
                  <Grid item md={3}>

                    {this.state.totpQRCodeData !== null && (
                      <img width="200" height="200" src={"data:image/png;base64," + this.state.totpQRCodeData} alt="No provided" />
                    )
                    }
                  </Grid>
                </Grid>
              </>
            )
            }
            {/* QR Code */}

            {(this.state.credentialsError === true && this.state.loginCodeMode !== null) && (
              <Alert severity="error" className="mb-4">
                The login code you entered is incorrect. Please double-check and try again.
              </Alert>
            )}

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Keep me logged in"
            />
            <hr />

            <div className="text-right">
              <Button
                variant="text"
                component={Link}
                href={"/recovery"}
                className="mr-1"
                size="large"
              >
                Forgot password?
              </Button>          
            
              <Button
                variant="contained"
                color="primary"
                disabled={ this.state.loading }
                size="large"
                onClick={() => this.send()}
                startIcon={this.state.loading && <CircularProgress size={22} className="loginProgress" />}
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </Container>
    );
  }
}
