import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const actionsUser = async (type, request, data) => {
  let setCookie = getSetCookie();

  if (type === "list") {
    return getList(request, setCookie);
  }

  if (type === "listFilter") {
    return getListFilter(request, setCookie);
  }

  if (type === "delete") return deleteUser(data["@id"], setCookie);

  if (type === "edit") return updateUser(request, data, setCookie);

  if (type === "create") return createUser(request, setCookie);
};

const getListFilter = async (endpoint, setCookie) => {
  const headers = {
    Authorization: `Bearer ${setCookie}`
  };
  const url = apiUrl + endpoint;

  const response = await axios
    .get(url, { headers: headers })
    .then(res => {
      let data = [];

      if (res.data["hydra:description"]) {
        data["error"] = res.data["hydra:description"];
      } else {
        data["activePagination"] = false;
        if (res.data["hydra:totalItems"] > 30) {
          let lastPage = res.data["hydra:view"]["hydra:last"].split("=&page=");
          let firstPage = res.data["hydra:view"]["hydra:first"].split("=");

          data["lastPage"] = lastPage[1];
          data["firstPage"] = firstPage[1];
          data["previuosPage"] = res.data["hydra:view"]["hydra:previous"];
          data["nextPage"] = res.data["hydra:view"]["hydra:next"];
          data["activePagination"] = true;
        }
        data["list"] = res.data["hydra:member"];
        data["totalReg"] = res.data["hydra:totalItems"];
        data["pagination"] = res.data["hydra:view"];
      }

      return data;
    })
    .catch(err => {
      return false;
    });
  return response;
};

const getList = async (endpoint, setCookie) => {
  const headers = {
    Authorization: `Bearer ${setCookie}`
  };
  const url = apiUrl + endpoint;

  const response = await axios
    .get(url, { headers: headers })
    .then(res => {
      let data = [];

      if (res.data["hydra:description"]) {
        data["error"] = res.data["hydra:description"];
      } else {
        data["activePagination"] = false;
        if (res.data["hydra:totalItems"] > 30) {
          let lastPage = res.data["hydra:view"]["hydra:last"].split("=");
          let firstPage = res.data["hydra:view"]["hydra:first"].split("=");
          data["lastPage"] = lastPage[1];
          data["firstPage"] = firstPage[1];
          data["previuosPage"] = res.data["hydra:view"]["hydra:previous"];
          data["nextPage"] = res.data["hydra:view"]["hydra:next"];
          data["activePagination"] = true;
        }
        data["list"] = res.data["hydra:member"];
        data["totalReg"] = res.data["hydra:totalItems"];
        data["pagination"] = res.data["hydra:view"];
      }

      return data;
    })
    .catch(err => {
      return false;
    });
  return response;
};

const deleteUser = async (endpoint, setCookie) => {
  const headers = {
    Authorization: `Bearer ${setCookie}`
  };
  const url = apiUrl + endpoint;
  const response = await axios
    .delete(url, { headers: headers })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return false;
    });
  return response;
};

export const getUser = async endpoint => {
  let setCookie = getSetCookie();
  const headers = {
    Authorization: `Bearer ${setCookie}`
  };
  const url = apiUrl + endpoint;
  const response = await axios
    .get(url, { headers: headers })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      if (err.response) {
        return false;
      } else if (err.request) {
        return false;
      } else if (err.message) {
        return false;
      }
      return false;
    });
  return response;
};

const createUser = async (request, setCookie) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${setCookie}`
  };
  const url = apiUrl + "/users";
  const response = await axios
    .post(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      let data = [];
      if (res.data["hydra:description"])
        data["error"] = res.data["hydra:description"];
      else data = "Successfully saved";
      return data;
    })
    .catch(err => {
      if (err.response) {
        let data = [];
        if (err.response.data["hydra:description"])
          data["error"] = err.response.data["hydra:description"];
        else data = false;
        return data;
      } else if (err.request) {
        return false;
      } else if (err.message) {
        return false;
      }
    });
  return response;
};

const updateUser = async (request, data, setCookie) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${setCookie}`
  };

  const url = apiUrl + data["@id"];
  const response = await axios
    .put(url, JSON.stringify(request), { headers: headers })
    .then(res => {
      let data = [];
      if (res.data["hydra:description"])
        data["error"] = res.data["hydra:description"];
      else data["info"] = "Successfully updated";
      data["data"] = res.data;

      return data;
    })
    .catch(err => {
      if (err.response) {
        let data = [];
        if (err.response.data["hydra:description"])
          data["error"] = err.response.data["hydra:description"];
        else data = false;
        return data;
      } else if (err.request) {
        return false;
      } else if (err.message) {
        return false;
      }
    });
  return response;
};

const getSetCookie = () => {
  return window.localStorage.getItem("headersSetCookie");
};
