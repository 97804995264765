import { takeEvery, put, call, all } from "redux-saga/effects";
import apiCall from "../services";

const apiUrl = process.env.REACT_APP_API_URL;

function* attractions(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/attractions?page=" + actions.page
        : apiUrl + "/attractions?" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "ATTACTIONS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "ATTACTIONS_RESPONSE_LIST", value: error });
  }
}

function* attraction(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/attractions/"
        : apiUrl + "/attractions/" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "ATTRACTION_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTION_RESPONSE", value: error });
  }
}

function* add(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };
    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/attractions",
      requestOptions
    );

    yield put({ type: "ATTRACTIONS_RESPONSE_CREATE", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTIONS_RESPONSE_CREATE", value: error });
  }
}

function* edit(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data.form)
    };

    const createComments = actions.data.comments?.create.map(comment => {
      return call(add_Comments, { access: actions.access, data: comment });
    }) || [];

    const editComments = actions.data.comments?.edit.map(comment => {
      return call(edit_Comments, { access: actions.access, commentId: comment.commentId, data: comment });
    }) || [];

    const createAudits = actions.data.audits?.map(audit => {
      return call(addAudit, { access: actions.access, data: audit });
    }) || [];

    const response = yield all([
      call(apiCall, "put", apiUrl + "/attractions/" + actions.id, requestOptions),
      ...createComments,
      ...editComments,
      ...createAudits,
    ])

    yield put({ type: "ATTRACTIONS_RESPONSE_EDIT", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTIONS_RESPONSE_EDIT", value: error });
  }
}

function* addMultimedia(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: actions.data
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/attraction-media/upload",
      requestOptions
    );
    yield put({ type: "ATTRACTION_RESPONSE_MULTIMEDIA", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTION_RESPONSE_MULTIMEDIA", value: error });
  }
}

function* editMultimedia(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };
    const response = yield call(
      apiCall,
      "put",
      apiUrl + "/attraction_media/" + actions.idMultimedia,
      requestOptions
    );
    yield put({ type: "ATTRACTION_RESPONSE_MULTIMEDIA", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTION_RESPONSE_MULTIMEDIA", value: error });
  }
}

function* comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/comments?objectType=attractions&objectId=" + actions.objectId,
      requestOptions
    );

    yield put({ type: "COMMENTS_RESPONSE_LIST_ATTRACTION", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_LIST_ATTRACTION", value: error });
  }
}

function* add_Comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/comments",
      requestOptions
    );

    yield put({ type: "COMMENTS_RESPONSE_POST_ATTRACTION", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_POST_ATTRACTION", value: error });
  }
}

function* edit_Comments(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "put",
      apiUrl + "/comments/" + actions.commentId,
      requestOptions
    );

    yield put({ type: "COMMENTS_RESPONSE_POST_ATTRACTION", value: response });
  } catch (error) {
    yield put({ type: "COMMENTS_RESPONSE_POST_ATTRACTION", value: error });
  }
}

function* audit(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/audits?objectType=" +
        actions.objectType +
        "&objectId=" +
        actions.objectId,
      requestOptions
    );

    yield put({ type: "AUDIT_RESPONSE_LIST_ATTRACTIONS", value: response });
  } catch (error) {
    yield put({ type: "AUDIT_RESPONSE_LIST_ATTRACTIONS", value: error });
  }
}
function* history(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/changelogs?objectType=" +
        actions.objectType +
        "&objectId=" +
        actions.objectId,
      requestOptions
    );

    yield put({ type: "LOGGINGS_RESPONSE_LIST_ATTRACTIONS", value: response });
  } catch (error) {
    yield put({ type: "LOGGINGS_RESPONSE_LIST_ATTRACTIONS", value: error });
  }
}

function* destinations(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url = apiUrl + "/destinations?pagination=false";
    if (actions.status) {
      url += `&status=${actions.status}`;
    }

    const response = yield call(
      apiCall,
      "get",
      url,
      requestOptions
    );

    yield put({ type: "DESTINATION_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "DESTINATION_RESPONSE_LIST", value: error });
  }
}

function* addAudit(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/audits",
      requestOptions
    );

    yield put({ type: "AUDIT_RESPONSE_POST_ATTRACTIONS", value: response });
  } catch (error) {
    yield put({ type: "AUDIT_RESPONSE_POST_ATTRACTIONS", value: error });
  }
}

export function* getAttraction() {
  yield takeEvery("GET_ATTRACTION", attraction);
}
export function* getAttractionsList() {
  yield takeEvery("GET_ATTRACTIONS", attractions);
}

export function* addAttraction() {
  yield takeEvery("ADD_ATTRACTION", add);
}

export function* editAttraction() {
  yield takeEvery("EDIT_ATTRACTION", edit);
}

export function* addMultimediaAttraction() {
  yield takeEvery("ADD_ATTRACTIONS_MULTIMEDIA", addMultimedia);
}

export function* editMultimediaAttraction() {
  yield takeEvery("EDIT_ATTRACTIONS_MULTIMEDIA", editMultimedia);
}

export function* getAttractionsCommentsList() {
  yield takeEvery("GET_ATTRACTIONS_COMMENTS", comments);
}

export function* addAttractionsComments() {
  yield takeEvery("ADD_ATTRACTIONS_COMMENTS", add_Comments);
}

export function* editAttractionsComments() {
  yield takeEvery("EDIT_ATTRACTIONS_COMMENTS", edit_Comments);
}

export function* getAttractionsAuditList() {
  yield takeEvery("GET_ATTRACTIONS_AUDIT_LIST", audit);
}

export function* getDestinationsAttractions() {
  yield takeEvery("GET_DESTINATIONS_ATTRACTIONS", destinations);
}

export function* getAttractionsHistoryList() {
  yield takeEvery("GET_ATTRACTIONS_HISTORY_LIST", history);
}
