import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";
const apiUrl = process.env.REACT_APP_API_URL;

function* ticket(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    let url =
      actions.query === null
        ? apiUrl + "/ticket_products/"
        : apiUrl + "/ticket_products/" + actions.query;
    const response = yield call(apiCall, "get", url, requestOptions);
    yield put({ type: "RESPONSE_TICKET", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_TICKET", value: error });
  }
}

function* edit_Ticket(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "put",
      apiUrl + "/ticket_products/" + actions.id,
      requestOptions
    );
    yield put({ type: "RESPONSE_TICKET", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_TICKET", value: error });
  }
}

function* ticketList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.filters === null
        ? apiUrl + "/ticket_products?page=" + actions.page + "&includeTours=" + actions.includeTours
        : apiUrl + "/ticket_products?" + actions.filters + "&includeTours=" + actions.includeTours;
    const response = yield call(apiCall, "get", url, requestOptions);
    yield put({ type: "RESPONSE_TICKET_LIST", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_TICKET_LIST", value: error });
  }
}

function* get_venueMappingList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.filters === null
        ? apiUrl +
          "/ticket_supplier_venues?supplierId=" +
          actions.provider +
          "&page=" +
          actions.query
        : apiUrl +
          "/ticket_supplier_venues?supplierId=" +
          actions.provider +
          "&page=" +
          actions.query +
          "&" +
          actions.filters;
    const response = yield call(apiCall, "get", url, requestOptions);
    yield put({ type: "RESPONSE_VENUE_LIST", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_VENUE_LIST", value: error });
  }
}

function* edit_VenueMapping(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.form)
    };

    yield call(
      apiCall,
      "put",
      apiUrl + "/ticket_supplier_venues/" + actions.id,
      requestOptions
    );

    yield put({ type: "RESPONSE_VENUESMAPPING_EDIT", value: true });
  } catch (error) {
    yield put({ type: "RESPONSE_VENUESMAPPING_EDIT", value: error });
  }
}

function* get_venueMapping(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/venues?status=" + actions.status + "&pagination=false",
      requestOptions
    );

    yield put({ type: "VENUE_MAPPING_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "VENUE_MAPPING_RESPONSE_LIST", value: error });
  }
}

function* get_destinationMappingList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.filters === null
        ? apiUrl +
          "/ticket_destinations?supplierId=" +
          actions.provider +
          "&page=" +
          actions.query
        : apiUrl +
          "/ticket_destinations?supplierId=" +
          actions.provider +
          "&page=" +
          actions.query +
          "&" +
          actions.filters;

    const responseMapping = yield call(apiCall, "get", url, requestOptions);

    yield put({
      type: "RESPONSE_DESTINATION_MAPPING_LIST",
      value: responseMapping
    });
  } catch (error) {
    yield put({ type: "RESPONSE_DESTINATION_MAPPING_LIST", value: error });
  }
}

function* edit_DestinationMapping(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.form)
    };

    yield call(
      apiCall,
      "put",
      apiUrl + "/ticket_destinations/" + actions.id,
      requestOptions
    );

    yield put({ type: "RESPONSEDESTINATIONMAPPING_EDIT", value: true });
  } catch (error) {
    yield put({ type: "RESPONSEDESTINATIONMAPPING_EDIT", value: error });
  }
}

function* destination(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/destinations?status=" + actions.status + "&pagination=false",
      requestOptions
    );
    yield put({ type: "DESTINATION_MAPPING_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "DESTINATION_MAPPING_RESPONSE_LIST", value: error });
  }
}

function* get_ProvidersList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/ticket_suppliers",
      requestOptions
    );
    yield put({ type: "RESPONSE_PROVIDER_LIST", prov: response });
  } catch (error) {
    yield put({ type: "RESPONSE_PROVIDER_LIST", prov: error });
  }
}

function* get_BookingList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/ticket_bookings?page=" + actions.page + "&" + actions.sort
        : apiUrl + "/ticket_bookings?" + actions.sort + "&" + actions.query;
    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "RESPONSE_BOOKING_LIST", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_BOOKING_LIST", value: error });
  }
}

function* edit_booking(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "put",
      apiUrl + "/ticket_bookings/" + actions.id,
      requestOptions
    );
    yield put({ type: "RESPONSE_EDIT_BOOKING", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_EDIT_BOOKING", value: error });
  }
}

function* get_booking(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/ticket_bookings/" + actions.query,
      requestOptions
    );
    yield put({ type: "RESPONSE_BOOKING_GET", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_BOOKING_GET", value: error });
  }
}

function* categoryMapping(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.filters === null
        ? apiUrl +
          "/ticket_supplier_categories?supplierId=" +
          actions.provider +
          "&page=" +
          actions.query
        : apiUrl +
          "/ticket_supplier_categories?supplierId=" +
          actions.provider +
          "&page=" +
          actions.query +
          "&" +
          actions.filters;
    const responseMapping = yield call(apiCall, "get", url, requestOptions);

    yield put({
      type: "RESPONSE_CATEGORYMAPPING_LIST",
      value: responseMapping
    });
  } catch (error) {
    yield put({ type: "RESPONSE_CATEGORYMAPPING_LIST", value: error });
  }
}

function* edit_CategoryMapping(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.form)
    };

    yield call(
      apiCall,
      "put",
      apiUrl + "/ticket_supplier_categories/" + actions.id,
      requestOptions
    );
    yield put({ type: "RESPONSE_CATEGORYMAPPING_EDIT", value: true });
  } catch (error) {
    yield put({ type: "RESPONSE_CATEGORYMAPPING_EDIT", value: error });
  }
}

function* add_NewCategory(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,

      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/ticket_categories",
      requestOptions
    );
    let form = {};
    let categoryId = response["@id"].split("/ticket_categories/")[1];
    form["categoryId"] = categoryId;

    const requestOptionsNew = {
      headers: headers,

      body: JSON.stringify(form)
    };

    yield call(
      apiCall,
      "put",
      apiUrl + "/ticket_supplier_categories/" + actions.idEdit,
      requestOptionsNew
    );
    yield put({ type: "RESPONSE_CATEGORYMAPPING_EDIT", value: true });
  } catch (error) {
    yield put({ type: "RESPONSE_CATEGORYMAPPING_EDIT", value: error });
  }
}

function* get_CategoryList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/ticket_categories?pagination=false",
      requestOptions
    );
    yield put({ type: "RESPONSE_CATEGORY_LIST", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_CATEGORY_LIST", value: error });
  }
}

function* get_AttractionMapping(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/attractions?pagination=false",
      requestOptions
    );
    yield put({ type: "ATTRACTIONS_MAPPING_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "ATTRACTIONS_MAPPING_RESPONSE_LIST", value: error });
  }
}

function* get_BookingSearchList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/ticket_products?" + actions.filterFields,
      requestOptions
    );
    yield put({ type: "RESPONSE_BOOKING_LIST", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_BOOKING_LIST", value: error });
  }
}
function* get_BookingSearch(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,

      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl +
        "/booking/availability" +
        "?includeTours=" +
        actions.data.includeTours,
      requestOptions
    );

    yield put({ type: "RESPONSE_BOOKING", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_BOOKING", value: error });
  }
}

function* get_Prebooking(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,

      body: JSON.stringify(actions.form)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/booking/start",
      requestOptions
    );
    yield put({ type: "RESPONSE_PREBOOKING", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_PREBOOKING", value: error });
  }
}
function* get_BookingComplete(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,

      body: JSON.stringify(actions.form)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/booking/complete",
      requestOptions
    );
    yield put({ type: "RESPONSE_BOOKINGCOMPLETE", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_BOOKINGCOMPLETE", value: error });
  }
}
function* get_ProductsDestination(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let destinationId =
      actions.destinationId !== null ? actions.destinationId : "";
    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/ticket_products?name=" +
        actions.data +
        "&destinationId=" +
        destinationId +
        "&includeTours=" +
        actions.includeToursCheck,
      requestOptions
    );
    yield put({ type: "RESPONSE_PRODUCTDESTINATION_LIST", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_PRODUCTDESTINATION_LIST", value: error });
  }
}

export function* getTicket() {
  yield takeEvery("GET_TICKET", ticket);
}

export function* getTicketList() {
  yield takeEvery("GET_TICKET_LIST", ticketList);
}

export function* editTicket() {
  yield takeEvery("EDIT_TICKET", edit_Ticket);
}

export function* getVenueMappingList() {
  yield takeEvery("GET_VENUE_MAPPING", get_venueMappingList);
}

export function* editVenueMapping() {
  yield takeEvery("EDIT_VENUE_MAPPING", edit_VenueMapping);
}

export function* getVenuesMapping() {
  yield takeEvery("GET_VENUES_MAPPING", get_venueMapping);
}

export function* getDestinationMappingList() {
  yield takeEvery("GET_DESTINATION_MAPPING", get_destinationMappingList);
}

export function* editDestinationMapping() {
  yield takeEvery("EDIT_DESTINATION_MAPPING", edit_DestinationMapping);
}

export function* getDestinationsMapping() {
  yield takeEvery("GET_DESTINATIONS_MAPPING", destination);
}

export function* getProvidersList() {
  yield takeEvery("GET_PROVIDER_LIST", get_ProvidersList);
}

export function* getCategoryList() {
  yield takeEvery("GET_CATEGORY_LIST", get_CategoryList);
}

export function* getCategoryMappingList() {
  yield takeEvery("GET_CATEGORY_MAPPING", categoryMapping);
}

export function* editCategoryMapping() {
  yield takeEvery("EDIT_CATEGORY_MAPPING", edit_CategoryMapping);
}

export function* getBookingList() {
  yield takeEvery("GET_BOOKING_LIST", get_BookingList);
}

export function* getBooking() {
  yield takeEvery("GET_BOOKING", get_booking);
}

export function* editBooking() {
  yield takeEvery("EDIT_BOOKING", edit_booking);
}

export function* addNewCategory() {
  yield takeEvery("ADD_NEW_CATEGORY_MAPPING", add_NewCategory);
}
export function* getAttractionMapping() {
  yield takeEvery("GET_ATTRACTIONS_MAPPING", get_AttractionMapping);
}

export function* getBookingSearchList() {
  yield takeEvery("GET_BOOKING_SEARCH_LIST", get_BookingSearchList);
}

export function* getBookingSearch() {
  yield takeEvery("GET_BOOKING_SEARCH", get_BookingSearch);
}

export function* getPrebooking() {
  yield takeEvery("GET_PREBOOKING", get_Prebooking);
}

export function* getBookingComplete() {
  yield takeEvery("GET_BOOKING_COMPLETE", get_BookingComplete);
}

export function* getProductsDestination() {
  yield takeEvery("GET_PRODUCTS_DESTINATION", get_ProductsDestination);
}
