import React, { Component } from 'react';
import { Grid, Typography, Button, Container } from '@material-ui/core';
import Password from '../../common/fields/Password';
import { reset } from '../../../services/LoginService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { NavLink as RouterLink } from "react-router-dom";

export default class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseReset: {},
            errors: {
                status: {
                    password: false,
                    passwordConfirm: false
                },
                text: {
                    password: '',
                    passwordConfirm: 'Password does not match'
                }

            }
        }
    }

    send = async () => {

        let form = {};
        let countValid = 0;
        let errors = [];
        form["hash"] = this.props.match.params.hash;

        if (this.password && this.password !== null) {
            if (this.password.state.defaultValue !== '') {
                countValid++;
                form[this.password.props.name] = this.password.state.defaultValue;
            }
        }

        if (this.passwordConfirm && this.passwordConfirm !== null) {
            if (this.passwordConfirm.state.defaultValue !== '' && (this.passwordConfirm.state.defaultValue === this.password.state.defaultValue)) {
                countValid++;
                form[this.passwordConfirm.props.name] = this.passwordConfirm.state.defaultValue;
            }
        }

        errors = {
            status: {
                password: (this.password && this.password.state.defaultValue !== '' && this.password.state.errorText === '') ? false : true,
                passwordConfirm: (this.passwordConfirm && this.passwordConfirm.state.defaultValue !== '' && this.passwordConfirm.state.errorText === '' && (this.passwordConfirm.state.defaultValue === this.password.state.defaultValue)) ? false : true
            },
            text: {
                password: (this.password && this.password.state.defaultValue !== '' && this.password.state.errorText === '') ? '' : "Enter an password",
                passwordConfirm: (this.passwordConfirm && this.passwordConfirm.state.defaultValue !== '' && this.passwordConfirm.state.errorText === '' && (this.passwordConfirm.state.defaultValue === this.password.state.defaultValue)) ? "" : "Incorrect password. Repeat the same password"
            }

        }

        this.setState({ errors: errors });

        if (2 === countValid) {
            await reset(form)
                .then(res => {

                    this.setState({ responseReset: res })
                })
                .catch((err) => {

                    this.setState({ responseReset: false })
                });

        }
    }

    render() {
        return (
            !this.state.responseReset ?
            (<Container maxWidth="md" className="mx-auto">
                <div className="login_container">
                    <Alert severity="error" className="mb-4">
                        This password recovery link has expired or is invalid
                    </Alert>
                </div>
            </Container>

            )
            :
            (this.state.responseReset && Object.keys(this.state.responseReset).length > 0 ?
                (
                    <Container maxWidth="md" className="mx-auto">
                        <div className="login_container">
                            <div className="form-header">
                                <Typography component="h1" variant="h4" className="title">Successful password reset!</Typography>
                            </div>
                            <div className="form-content">

                                <Alert severity="info" className="mb-4">
                                    <AlertTitle>{this.state.responseReset}</AlertTitle>
                                    You can now use your new password to log in to your account!
                                </Alert>

                                <Button
                                    component={RouterLink}
                                    variant="contained"
                                    color="primary"
                                    className="submit"
                                    size="large"
                                    to={{ pathname: `/login` }}

                                >
                                    Sign In
                                </Button>

                            </div>

                        </div>
                    </Container>
                ) : (
                    <Container maxWidth="md" className="mx-auto">
                        <div className="login_container">
                            <div className="form-header">
                                <Typography component="h1" variant="h4" className="title">Restore password</Typography>
                            </div>
                            <form className="form" noValidate>
                                <Grid container spacing={1}>
                                    <Grid item md={6}>
                                        <Password
                                            ref={element => { this.password = element }}
                                            id="password"
                                            label="Password"
                                            name="password"
                                            placeholder="Enter new password"
                                            defaultValue={''}
                                            disabled={false}
                                            error={this.state.errors.status.password}
                                            errorText={this.state.errors.text.password}
                                            classNameCustom={'textField'}
                                            autoComplete="current-password"
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Password
                                            ref={element => { this.passwordConfirm = element }}
                                            id="confirmPassword"
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            placeholder="Confirm new password"
                                            defaultValue={''}
                                            disabled={false}
                                            error={this.state.errors.status.passwordConfirm}
                                            errorText={this.state.errors.text.passwordConfirm}
                                            classNameCustom={'textField'}
                                            autoComplete="current-password"
                                            required={true}
                                        />
                                    </Grid>
                                </Grid>

                                <hr />

                                <div className="text-right">

                                    <Button
                                        variant="text"
                                        onClick={() => this.login()}
                                        className="mr-1"
                                        size="large"
                                    >Sign in to an existing account</Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="submit"
                                        size="large"
                                        onClick={() => this.send()}
                                    >
                                        Update Password
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Container>
                )
            )

        )
    }
}
