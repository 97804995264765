export default function apiCall(method, url,options = null) {

    if(options === null){
        let body = null;
        return fetch(url, {
            method,
            body,
        }).then(response => response.json())
    }else{
        let body = options.body;
        let headers = options.headers;
        return fetch(url, {
            method,
            headers,
            body
        }).then(response => response.json())
    }

}