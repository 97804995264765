const initialState = {
  resultTourBooking: {},
  resultsTourBookingsList: [],
  resultsTourBookingsFile: {},
  resultsTourBookingMessagesList: [],
  resultsTourBookingMessageContent: {},
  resultsSendTourBookingMessage: {},
  resultsToursTourBookingsList: [],
  resultsPartnersTourBookingsList: [],
  resultsTourBookingsCreate: {},
  resultsGuideCandidatesTourBookingsList: [],
  resultsToursTourBookingsListModal:[],
  preload: false,
  reloadMessage: false,
  preloadTourBooking: true,
  resultTourRateData:[],
  back: false,
  resultsPartnersBooking: [],
  resultsLoggingsListBooking: [],
  resultsErrorGuide: '',
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {

    case "LOGGINGS_RESPONSE_LIST_BOOKING":
      newState.resultsLoggingsListBooking = action.value;
      newState.preload = false;
      break;

    case "TOUR_RATE_DATA":
      newState.resultTourRateData = action.value;

      break;

    case "TOUR_BOOKINGS_RESPONSE":
      newState.resultTourBooking = action.value;
      newState.preload = false;
      newState.reloadMessage = false;
      break;
    case "TOUR_BOOKINGS_RESPONSE_LIST":
      newState.resultsTourBookingsList = action.value;
      newState.resultsTourBookingsCreate = {};




      newState.preload = false;
      newState.reloadMessage = false;
      break;

    case "PARTNERS_BOOKING_RESPONSE_LIST":
      newState.resultsPartnersBooking = action.value;
       newState.preload = false;
      break;
    case "TOUR_BOOKINGS_RESPONSE_CREATE":
      newState.resultsTourBookingsCreate = action.value;
      setTimeout(function(){
        newState.resultsTourBookingsCreate = {};
    }, 40);

      newState.preload = false;
      break;
    case "TOUR_BOOKINGS_RESPONSE_EDIT":
      newState.resultsErrorGuide = action.value.errorMessage !== '' ? action.value.errorMessage : '';
      newState.back = action.value.errorMessage !== '' ? false : true;
      newState.preload = false;

      break;
    case "TOUR_BOOKINGS_FILE_RESPONSE":
      newState.resultsTourBookingsFile = action.value;
      newState.preload = false;
      newState.reloadMessage = false;
      break;
    case "TOUR_BOOKINGS_MESSAGES_RESPONSE_LIST":
      newState.preload = false;
      newState.reloadMessage = false;
      newState.resultsTourBookingMessagesList = action.value;
      newState.resultsTourBookingMessageContent = {};
      break;
    case "TOUR_BOOKINGS_MESSAGE_CONTENT_RESPONSE":
      newState.preload = false;
      newState.reloadMessage = true;
      newState.resultsTourBookingMessageContent = action.value;
      break;
    case "SEND_TOUR_BOOKINGS_MESSAGE_RESPONSE":
      newState.preload = true;
      newState.reloadMessage = false;
      newState.resultsSendTourBookingMessage = action.value;
      newState.resultsTourBookingMessageContent = {};
      break;
    case "TOURS_TOUR_BOOKINGS_RESPONSE_LIST":
       newState.resultTourRateData = [];
      if (action.typeList === 'create'){
        newState.preload = true;
        newState.resultsToursTourBookingsList = action.value;
      } else {
        newState.resultsToursTourBookingsListModal = action.value;
      }
      break;
    case "PARTNERS_TOUR_BOOKINGS_RESPONSE_LIST":
      newState.preload = true;
      newState.resultsPartnersTourBookingsList = action.value;
      break;
    case "GUIDE_CANDIDATES_TOUR_BOOKINGS_RESPONSE_LIST":
      newState.preload = false;
      newState.resultsGuideCandidatesTourBookingsList = action.value;
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
