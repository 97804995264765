import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";
import { saveAs } from "file-saver";

const apiUrl = process.env.REACT_APP_API_URL;

function* partners(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/partners?visible=true&page=" + actions.page
        : apiUrl + "/partners?visible=true&" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "PARTNERS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "PARTNERS_RESPONSE_LIST", value: error });
  }
}

function* partner(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/partners/"
        : apiUrl + "/partners/" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "PARTNER_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "PARTNER_RESPONSE", value: error });
  }
}

function* add(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };
    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/partners",
      requestOptions
    );

    yield put({ type: "PARTNERS_RESPONSE_CREATE", value: response });
  } catch (error) {
    yield put({ type: "PARTNERS_RESPONSE_CREATE", value: error });
  }
}

function* edit(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "put",
      apiUrl + "/partners/" + actions.id,
      requestOptions
    );

    yield put({ type: "PARTNERS_RESPONSE_EDIT", value: response });
  } catch (error) {
    yield put({ type: "PARTNERS_RESPONSE_EDIT", value: error });
  }
}

function* getDestinationTreeSaga(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/destinations-tree",
      requestOptions
    );

    yield put({ type: "DESTINATION_TREE_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "DESTINATIONS_TREE_RESPONSE_LIST", value: error });
  }
}

function* getToursByDestinationSaga(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/tours?pagination=false&destinationId=" + actions.destinationId,
      requestOptions
    );

    yield put({ type: "TOURS_DESTINATION_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "TOURS_DESTINATION_RESPONSE_LIST", value: error });
  }
}

function* download(actions) {
  try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actions.access}`
      };

      const requestOptions = {
        headers: headers
      };

      const response = yield fetch(
        apiUrl + "/tour-distribution-sheets?" + actions.query,
        requestOptions
      ).then(response => response.blob());

      saveAs(response, actions.name);
  } catch (error) {}
}

function* add_Multimedia(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: actions.data
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/images",
      requestOptions
    );

    yield put({ type: "RESPONSE_MULTIMEDIA", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_MULTIMEDIA", value: error });
  }
}

function* downloadPartnerTourListSaga(actions) {
  try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actions.access}`
      };

      const requestOptions = {
        headers: headers
      };

      const response = yield fetch(
        apiUrl + "/partner/export?partnerId=" + actions.partnerId + "&reportType=" + actions.mode,
        requestOptions
      ).then(response => response.blob());

      saveAs(response, actions.fileName);
  } catch (error) {}
}

function* getUsersPartnerSaga(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    let url = apiUrl + "/autocomplete/users?name=" + actions.data;

    const response = yield call(apiCall, "get", url, requestOptions);
    yield put({ type: "PARTNER_USERS_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "PARTNER_USERS_RESPONSE", value: error });
  }
}

function* history(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/changelogs?objectType=" +
        actions.objectType +
        "&objectId=" +
        actions.objectId,
      requestOptions
    );


    yield put({ type: "LOGGINGS_RESPONSE_LIST_PARTNERS", value: response });
  } catch (error) {
    yield put({ type: "LOGGINGS_RESPONSE_LIST_PARTNERS", value: error });
  }
}

export function* getPartner() {
  yield takeEvery("GET_PARTNER", partner);
}

export function* getPartnersList() {
  yield takeEvery("GET_PARTNERS", partners);
}

export function* addPartner() {
  yield takeEvery("ADD_PARTNER", add);
}

export function* editPartner() {
  yield takeEvery("EDIT_PARTNER", edit);
}

export function* getDestinationTree() {
  yield takeEvery("GET_DESTINATION_TREE", getDestinationTreeSaga);
}

export function* getToursByDestination() {
  yield takeEvery("GET_TOURS_DESTINATION", getToursByDestinationSaga);
}

export function* downloadDistributionSheetPartner() {
  yield takeEvery("DOWNLOAD_SHEET_PARTNER", download);
}

export function* addMultimediaPartner() {
  yield takeEvery("ADD_MULTIMEDIA_PARTNER", add_Multimedia);
}

export function* downloadPartnerTourList() {
  yield takeEvery("DOWNLOAD_PARTNER_TOUR_LIST", downloadPartnerTourListSaga);
}

export function* getUsersPartner() {
  yield takeEvery("GET_USERS_PARTNER", getUsersPartnerSaga);
}

export function* getPartnersHistoryList() {
  yield takeEvery("GET_PARTNERS_HISTORY_LIST", history);
}