import React, { Component } from "react";
import { Button, MenuItem, Menu } from "@material-ui/core";
import { forgot } from "./../../../services/LoginService";
import { NavLink as RouterLink } from "react-router-dom";

export default class MenuDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleCloseLogOut = () => {
    window.localStorage.clear();

    this.setState({ anchorEl: null });
  };

  resetPassword = async () => {
    let form = {};
    form["email"] = this.props.user;

    let response = await forgot(form);

    this.props.callbackForgot(response);
    this.setState({ anchorEl: null });
  };

  render() {
    return (
      <div>
        <Button
          startIcon={this.props.buttonStartIcon}
          endIcon={this.props.buttonEndIcon}
          color="inherit"
          className="button_lowecase"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {this.props.buttonText}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            onClick={this.handleCloseLogOut}
            component={RouterLink}
            to={{ pathname: `/login` }}
          >
            Logout
          </MenuItem>
          <MenuItem onClick={this.resetPassword}>Reset Password</MenuItem>
        </Menu>
      </div>
    );
  }
}
