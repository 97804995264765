import React from "react";
import {
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import "./fonts/AktivGrotesk.css";

const Theme = ({ children }) => {
  const styles = children.props.styles;

  const theme = createTheme({
    typography: {
      fontFamily: styles.fonts,
      fontSize: styles.body.fontSize,
      backgroundColor: styles.body.background,
    },
    palette: {
      primary: { main: styles.colors.primary },
      secondary: { main: styles.colors.secondary },
      white: { main: "#FFFFFF" },
      textColor: { main: styles.textColor },
      text: { primary: styles.textColor },
    },
    props: {
      MuiTable: {
        component: "div",
      },
      MuiTableRow: {
        component: "div",
      },
      MuiTableHead: {
        component: "div",
      },
      MuiTableBody: {
        component: "div",
      },
      MuiTableCell: {
        component: "div",
      },
    },

    overrides: {
        MuiSwitch: {
          root: {
            '&$disabled': {
            },
          },
          switchBase: {
            '&$checked': {
            },
          },
          track: {
            '&$checked': {
              backgroundColor: 'red', // Color de fondo cuando está marcado
            },
          },
          thumb: {
            '&$checked': {
              backgroundColor: 'red', // Color de fondo cuando está marcado
            },
          },
        },
      MuiButton: {
        root: { textTransform: "none", fontFamily: styles.body.fontFamily },
        contained: { color: styles.colors.primary, backgroundColor: "#f7f7f7" },
        containedPrimary: {
          backgroundColor: styles.colors.accentColor,
          "&:hover": { backgroundColor: styles.colors.accentColorHover },
        },
        containedSecondary: {
          backgroundColor: styles.colors.secondaryButton,
          color: "white",
          "&:hover": { backgroundColor: styles.colors.secondaryButtonHover },
        },
        outlined: { borderColor: styles.colors.primary },
        outlinedSecondary: {
          borderColor: styles.colors.secondaryDark,
          color: styles.colors.secondaryDark,
        },
      },
      MuiAvatar: {
        root: {
          MuiIcon: { root: { color: "white" } },
        },
        colorDefault: {
          backgroundColor: "#154153",
          color: "white",
        },
      },
      MuiTypography: {
        h1: {
          color: styles.headingsColor,
          fontFamily: styles.fontsHeadings,
          letterSpacing: "-1px",
          fontSize: "2rem",
        },
        h2: {
          color: styles.headingsColor,
          fontFamily: styles.fontsHeadings,
          letterSpacing: "-1px",
          fontSize: "1.4rem",
        },
        h3: {
          color: styles.headingsColor,
          fontFamily: styles.fontsHeadings,
          letterSpacing: "-1px",
          fontSize: "1.2rem",
        },
        h4: {
          color: styles.headingsColor,
          fontFamily: styles.fontsHeadings,
          letterSpacing: "-1px",
          fontSize: "1.1rem",
          fontWeight: 500,
        },
        h5: {
          color: styles.headingsColor,
          fontFamily: styles.fontsHeadings,
          letterSpacing: "-0.5px",
          fontSize: "1rem",
        },
        h6: {
          color: styles.headingsColor,
          fontFamily: styles.fontsHeadings,
          letterSpacing: "normal",
          lineHeight: "1.2",
          fontSize: 16,
        },
        body1: { fontFamily: styles.fonts },
      },
      MuiPaper: { rounded: { borderRadius: 12 } },
      MuiTableHead: {
        root: { backgroundColor: styles.tableHeadBgColor },
      },

      MuiTableCell: {
        root: { padding: "12px 5px", fontFamily: styles.tableHeadFontFamily },
        head: {
          fontWeight: 600,
          fontSize: styles.tableHeadSize,
          padding: "8px 5px",
        },
      },
      MuiCardActions: {
        root: { padding: 16 },
      },
      MuiChip: {
        root: {
          color: styles.chipColor,
          backgroundColor: styles.chipBgColor,
        },
      },
      MuiListItemIcon: {
        root: {
          color: styles.colors.primary,
        },
      },
      MuiListItem: { button: { color: styles.colors.primary } },
      MuiTabs: {
        root: {
          color: styles.colors.primary,
          marginBottom: 0,
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
      },
      MuiTab: {
        root: {
          color: styles.colors.primary,
          fontFamily: styles.body.fontFamily,
        },
        textColorPrimary: { color: styles.colors.primary },
      },
      MuiAlert: {
        standardWarning: {
          color: styles.standardWarningColor,
          backgroundColor: styles.standardWarningBg,
          border: "1px solid",
          borderColor: styles.standardWarningIcon,
        },
        filledInfo: {
          color: styles.standardInfoColor,
          backgroundColor: styles.standardInfoBg,
        },
        standardInfo: {
          color: styles.standardInfoColor,
          backgroundColor: styles.standardInfoBg,
        },

        standardError: {
          color: styles.standardErrorColor,
          backgroundColor: styles.standardErrorBg,
          border: "1px solid",
          borderColor: styles.standardErrorIcon,
        },
      },
      MuiAutocomplete: {
        option: { borderBottom: "1px solid rgba(0, 0, 0, 0.1)" },
      },
      MuiBackdrop: {
        root: { backgroundColor: "rgb(228 228 228 / 50%)" },
      },
    },
  });

  const drawerWidth = 210;

  const GlobalCSS = withStyles({
    "@global": {
      body: {
        margin: 0,
        padding: 0,
        backgroundColor: styles.body.background,
        color: styles.body.color,
        fontFamily: styles.body.fontFamily,
      },
      ".MuiAlert-standardWarning": {
        "& .MuiAlert-icon": {
          color: styles.standardWarningIcon,
          alignItems: "center",
        },
      },
      ".MuiAlert-standardInfo": {
        "& .MuiAlert-icon": {
          color: styles.standardInfoIcon,
          alignItems: "center",
        },
      },
      ".MuiAlert-filledInfo": {
        "& .MuiAlert-icon": {
          color: styles.standardInfoIcon,
          alignItems: "center",
        },
      },
      ".MuiButton-label": {
        pointerEvents: "none",
      },
      ".custom-phone-number .react-tel-input": {
        paddingTop: 22,
        position: "relative",

        "& .special-label": {
          color: styles.inputsLabelColor,
          transform: "none",
          whiteSpace: "nowrap",
          fontSize: styles.inputsLabelFontSize,
          fontWeight: styles.inputsLabelFontWeight,
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "100%",
          lineHeight: 1.2,
          left: 0,
          top: 0,
          padding: 0,
          fontFamily: styles.body.fontFamily,
        },
        "& .form-control": {
          fontFamily: styles.body.fontFamily,
          backgroundColor: styles.inputBgColor,
          color: styles.inputColor,
          borderColor: styles.inputBorderColor,
          width: "100%",
          "&:hover": {
            borderColor: "#7c87c7",
          },
          "&:disabled": {
            cursor: "default",
            "&:hover": {
              borderColor: "#cacaca",
            },
          },
        },
        "& .flag-dropdown": {
          top: "25px",
          height: "51px"
        },
        "& .selected-flag .arrow": {
          pointerEvents: "none",
        },
      },
      ".MuiFormControl-root.radio": {
        paddingTop: 12,
      },

      ".Select-SimpleSelectCurrentPayout": {
        paddingTop: '0!important',
      },

      ".MuiFormControl-root": {
        textAlign: "left",
        paddingTop: 22,
        marginBottom: 12,
        "& .MuiFormHelperText-root": {
          margin: "5px 0",
        },

        [theme.breakpoints.down("sm")]: { marginBottom: 12 },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          color: styles.inputsLabelColor,
          transform: "none",
          whiteSpace: "nowrap",
          fontSize: styles.inputsLabelFontSize,
          fontWeight: styles.inputsLabelFontWeight,
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "100%",
          lineHeight: 1.2,
          fontFamily: styles.body.fontFamily,
        },
        "& fieldset": {
          top: 0,
          "& legend": { display: "none" },
        },
        "& .MuiFormLabel-root": {
          color: styles.inputsLabelColor,
          transform: "none",
          whiteSpace: "nowrap",
          fontSize: styles.inputsLabelFontSize,
          fontWeight: styles.inputsLabelFontWeight,
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "100%",
          lineHeight: 1.2,
          fontFamily: styles.body.fontFamily,

          "&.Mui-focused": {},
        },
      },

      ".switchesLabel": {
        color: styles.inputsLabelColor,
        transform: "none",
        whiteSpace: "nowrap",
        fontSize: styles.inputsLabelFontSize,
        fontWeight: styles.inputsLabelFontWeight,
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
        lineHeight: 1.2,
        fontFamily: styles.body.fontFamily,
      },
      ".titleLabel": {
        color: styles.inputsLabelColor,
        transform: "none",
        whiteSpace: "nowrap",
        fontSize: styles.inputsLabelFontSize,
        fontWeight: styles.inputsLabelFontWeight,
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
        lineHeight: 1.2,
        fontFamily: styles.body.fontFamily,
      },
      ".titleLabelEdit": {
        paddingBottom: "15px",
      },

      ".w-md-editor-content": {
        backgroundColor: styles.inputBgColor,
        color: styles.inputColor,
      },
      ".w-md-editor-preview": {
        backgroundColor: styles.inputBgColorDisabled,
        color: styles.inputColor,
        "& p": {
          fontFamily: styles.body.fontFamily,
        },
      },
      ".MuiInputBase-root": {
        backgroundColor: styles.inputBgColor,
        color: styles.inputColor,

        "&.Mui-disabled": {
          color: styles.inputColor,
          border: "none",
          padding: "0!important",

          "& .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
          "& .MuiSelect-outlined": { borderColor: "transparent" },
          "& textarea": {
            border: "1px solid",
            padding: 12,
            borderColor: styles.textAreaColor,
            borderRadius: 4,
          },
          "& input": {
            padding: "10px !important",
            border: "1px solid",
            borderColor: styles.inputBorderColorDisabled,
            borderRadius: 4,
          },
        },
        "& input": {
          "&::placeholder": {
            fontStyle: styles.placeholderStyle,
            fontSize: styles.placeholderFontSize,
            color: styles.placeholderColor,
            opacity: 1,
          },
        },

        "& textarea": {
          "&::placeholder": {
            fontStyle: styles.placeholderStyle,
            fontSize: styles.placeholderFontSize,
            color: styles.placeholderColor,
            opacity: 1,
          },
        },
      },
      ".MuiOutlinedInput-root": {
        "&:not(.Mui-disabled)": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: styles.inputBorderColor,
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#7c87c7",
            },
          },
        },
      },

      ".MuiFormControl-root Select-SimpleSelectCurrentPayout": {
        paddingTop: "0px !important",
        
      },

      ".Select-SimpleSelect": {
        "& .MuiPaper-root": {
          maxHeight: "340px",
        },
      },
      ".Select-MultipleSelect": {
        "& .MuiInputLabel-outlined": {
          transform: "translate(14px, 42px) scale(1);",
        },
        "&.SelectSmall": {
          "& .MuiInputLabel-outlined": {
            transform: "translate(0px, 40px) scale(1);",
          },
        },
        "&.MuiInput-underline:before": {
          border: "none",
        },
        "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
          border: "none",
        },
        "& .MuiPaper-root": {
          maxHeight: "340px",
        },
        "& .MuiSelect-selectMenu": {
          borderColor: "rgba(0, 0, 0, 0.23)",
          border: "1px solid",
          paddingLeft: "14px",
          paddingTop: "18.5px",
          paddingBottom: "18.5px",
        },
        "& .MuiInputLabel-shrink": {
          transform: "none",
        },
        "& .MuiInput-formControl": {
          marginTop: 0,
        },
      },
      ".SelectSmall": {
        "& .MuiSelect-selectMenu": {
          paddingTop: 10.5,
          paddingBottom: 10.5,
        },
      },
      ".MuiAlert-outlinedWarning": { backgroundColor: "white" },
      ".wrapper": { display: "flex" },
      ".appBar": {
        zIndex: theme.zIndex.drawer + 1,
      },
      ".appBarShift": {},
      ".drawerPaper": {
        height:"calc(100vh - 64px)",
        overflowY:"auto",
        overflowX:"hidden",
        marginTop:"64px",
        direction: "rtl",
        scrollbarWidth:"thin",

        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        boxShadow: "0px 1px 3px 0px #bdbdbd",
        border: "none",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      ".drawerPaperClose": {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(7),
        },
      },
      ".menuButton": {
        marginRight: 36,
      },
      ".menuButtonHidden": {
        display: "none",
      },
      ".menuIsologo": {
        marginRight: 22,
        marginLeft: 8,
      },
      ".menuIsologoHidden": {
        display: "none",
      },
      ".LayoutContainer": {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      ".paperDrawer": {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
      },
      ".app_bar_spacer": theme.mixins.toolbar,
      ".MainLayout_wrapper": {
        display: "flex",
        "& .toolbar_Appbar": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
        },
        "& .toolbarDrawer": {
          alignItems: "center",
          justifyContent: "flex-end",
          ...theme.mixins.toolbar,
        },
        "& .toolbarDrawerSidebar": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "0 8px",
          ...theme.mixins.toolbar,
        },
        "& .toolbarDrawerButton": {
          color: styles.colors.primary,
          position: "absolute",
          top: 8,
          right: -25,
          backgroundColor: "#f0f0f0",
          "& .MuiIconButton-label": {
            position: "relative",
            left: "-11px",
          },
        },
        "& .toolbarLogo": {
          maxHeight: "35px",
          marginLeft: 0,
          marginRight: "auto",
          display: "none",
        },
        "& .toolbarLogoShow": {
          display: "block",
        },
        "& .main_admin": {
          flexGrow: 1,
          height: "100vh",
          overflow: "hidden",
        },
        "& .title_Dashboard": {
          flexGrow: 1,
          marginLeft: 16,
        },
      },
      ".main": { flexGrow: 1, height: "100vh", overflow: "auto" },
      ".login": {
        backgroundColor: "white",
        textAlign: "left",

        "& .title": {
          marginBottom: 0,
        },

        "& .form-header": {
          marginBottom: theme.spacing(4),
        },
      },
      ".login_container": {
        padding: theme.spacing(4),
      },
      ".bg-login": {
        backgroundColor: styles.colors.primary,
        backgroundPosition: "center",
        backgroundSize: "cover",
        "& .logo": {
          display: "block",
          position: "relative",
          top: "80px",
          maxWidth: "250px",
        },
      },

      ".paper": {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
      },
      ".container": {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      ".mainLayout_container": {
        paddingTop: theme.spacing(2.5),
        overflowX: "hidden",
        height: "calc(100% - 64px)",
        padding: "0!important",
      },

      ".content_section": {
        backgroundColor: "rgba(255,255,255,.5)",
      },
      ".filters_section_container": {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(3),
        padding: 0,
      },
      ".filters_section_left": { flexGrow: 1 },
      ".filters_section_actions": {
        marginRight: 0,
      },
      ".filter_tablecell": {
        paddingBottom: "18px !important",

        "& .MuiFormControl-root": {
          paddingTop: 0,
          marginBottom: 0,
        },
        "& .MuiFormLabel-root": {
          display: "none",
        },
      },
      ".filter_tablecell_open": {
        paddingBottom: "0px !important",
        

        "& .MuiFormControl-root": {
          paddingTop: 0,
          marginBottom: 0,
        },
        "& .MuiFormLabel-root": {
          display: "none",
        },
      },
      ".header_section_container": {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0),
      },
      ".header_detail_dashboard": {
        display: "flex",
        alignItems: "start",
        marginBottom: theme.spacing(2),
        padding: 0,
        position: "relative",
      },
      ".header_detail_dashboard_guide": {
        display: "flex",
        alignItems: "start",
        marginBottom: theme.spacing(2),
        padding: 0,
        position: "relative",
      },
      ".header_detail_dashboard_avatar_title": {
        flexGrow: 1,
      },
      ".header_detail_dashboard_actions": {
        marginBottom: "-12px",
        marginTop: "-9px",
      },
      ".header_detail_dashboard_avatar": {
        flex: "0 0 auto",
        marginRight: 16,
      },
      ".header_detail_dashboard_title": {
        flex: "1 1 auto",
        "& .section": {
          textTransform: "uppercase",
          color: styles.textColor,
          display: "block",
          fontSize: 14,
          fontWeight: 600,
        },
        "& .title": {
          fontSize: 22,
          fontWeight: 500,
        },
      },
      ".tabPanels_container": {
        marginTop: 20,
      },
      ".tabPanels_container_nomargin": {
        marginTop: 0,
      },
      ".container_Dashboard": {
        paddingTop: 24,
      },
      ".content_detail_dashboard": {
        paddingTop: 20,
        paddingRight: 8,
        paddingLeft: 8,
        paddingBottom: 90,

        "&.ViewTicketBookingProcess": {
          paddingBottom: 24,
        },
      },
      ".content_detail_dashboard_guide": {
        paddingTop: 0,
        paddingRight: 24,
        paddingLeft: 24,
        paddingBottom: 90,
      },

      ".content_detail_dashboard_Mobile": {
        paddingTop: 5,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 70,

        "&.ViewTicketBookingProcess": {
          paddingBottom: 24,
        },
      },
      ".environmentAndy": {
        borderTop: "4px solid #FF0000",
        borderBottom: 0,
      },
      ".environmentStaging": {
        border: "4px solid #FF8800",
        borderBottom: 0,
      },
      ".environmentProduction": {
        border: "0",
      },

      ".content-center": {
        display: "flex",
        flexDirection: "column",
      },
      ".MuiDialogTitle-root": {
        padding: "16px 12px",
        backgroundColor: styles.cardHeaderBgColor,
        "& .MuiTypography-root": {
          color: styles.cardHeaderColor,
        },
      },
      ".MuiDialogActions-root": {
        padding: "12px",
        backgroundColor: styles.cardHeaderBgColor,
        "& .MuiTypography-root": {
          color: styles.cardHeaderColor,
        },
      },
      ".card-footer": {
        backgroundColor: styles.cardHeaderBgColor,
        "& .MuiTypography-root": {
          color: styles.cardHeaderColor,
        },
      },
      ".card-footer-inner": {
        padding: "8px 0",
        "& .MuiButton-root": {
          marginRight: 12,
        },
      },
      ".MuiDialogContent-root": {
        minHeight: 100,
        padding: "24px !important",

        "& .collapse_content_container": {
          paddingTop: 0,
        },
      },
      ".card_button": { marginRight: 8 },
      ".button_white_outlined.MuiButton-outlined": {
        borderColor: "white",
        color: "white",
      },
      ".detail_dashboard": {},
      "a.MuiTypography-colorInherit": { color: styles.linkColor },
      ".BreadcrumbsDashboard": {
        display: "flex",
        width: "100%",
        marginBottom: 0,
        "& a ": { color: styles.textColor, textTransform: "capitalize" },
        "& a:hover ": { color: styles.linkColorHover },
        textTransform: "capitalize",
        "& .view": {
          textTransform: "none",
        },
      },
      ".tabTitle": {
        "& .MuiTab-wrapper": {
          flexDirection: "row",
          "& span": { display: "inline-block" },
          "& .MuiIcon-root": { marginRight: 10 },
        },
      },
      ".toolbarTab": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        display: "flex",

        "& .toolbarTab_buttons": {
          marginRight: 0,
        },
      },
      ".toolbar_tab_title": {
        flexGrow: 1,
        textTransform: "uppercase",
      },

      ".cardDocumentTableRow": {
        position: "relative",
      },
      ".cardPaymentTableRow": {
        position: "relative",
      },

      ".cardDocument-Name": {
        paddingLeft: "100px",
      },

      ".MuiTableCell-root:first-child:not(.filter_tablecell_open):not(.group_row)": {
        paddingLeft: "16px!important",
      },
      ".MuiTableCell-root:last-child:not(.filter_tablecell_open):not(.group_row)": {
        paddingRight: "16px!important",
      },

      ".MuiTableCell-root:first-child:not(.filter_tablecell):not(.group_row)": {
        paddingLeft: "16px!important",
      },
      ".MuiTableCell-root:last-child:not(.filter_tablecell):not(.group_row)": {
        paddingRight: "16px!important",
      },
      ".table-row-total .MuiTableRow-root:last-child .MuiTableCell-root": {
        border: "none",
      },
      ".group_row": {
        "& .collpase_row": {
          border: "none !important",
          background: "white !important"
        }
      },
      ".border-bottom .MuiTableCell-root": {
        borderBottom: "2px solid #c7c7c7",
      },
      ".tableList_table": {
        "& .MuiFormControl-root": {
          paddingTop: 12,
        },
      },
      ".tableList_tableButton": {
        "& .MuiFormControl-root": {
          paddingTop: 16,
        }},
      ".dataGrid": {
        marginBottom: 15
      },
      ".tableList": {
        width: "100%",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        "& .tableList_table": {
          minWidth: 750,

          "& .MuiTableRow-root": {
            "&:hover": { textDecoration: "none" },
            "& .MuiTableCell-root:firts-child": {
              width: "40%",
            },
          },

          "& .clickableHeader": {
            cursor: "pointer",
            width: "fit-content",
            margin: "auto",
          },

          "& .clickableHeader:hover": {
            opacity: "0.75"
          }
        },
      },
      ".tableListCollapse": {
        width: "100%",
        marginBottom: theme.spacing(2),
        "& .tableList_table": {
          minWidth: 600,
          "& .MuiTableRow-root": {
            "&:hover": { textDecoration: "none" },
          },
        },
      },
      ".visuallyHidden": {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
      ".container_chips": {
        display: "flex",
        justifyContent: "start",
        flexWrap: "wrap",
        "& > *": {
          margin: theme.spacing(0.5),
        },
      },
      ".chips_roles": {
        "& .MuiChip-label": {
          fontSize: 11,
        },
      },
      ".buttons_actions": {
        "& > *": {
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
        },
      },
      ".MenuHeader_Avatar": {
        color: "#FFFFFF",
        backgroundColor: "rgba(255,255,255,.2)",
        overflow: "visible",
      },
      ".MenuHeader_Badge": {
        "& .MuiBadge-badge": { backgroundColor: "orange" },
      },
      ".badgeError": { backgroundColor: "red", color: "white" },
      ".badgeAlert": { backgroundColor: "orange", color: "white" },
      ".button_lowecase": {
        "& .MuiTypography-root": { textTransform: "none" },
      },
      ".MenuLogin": {
        display: "flex",
      },
      ".Detail_Action_ViewHeader": {
        backgroundColor: "#f5f5f5",
      },
      ".Detail_Action_ViewContainer": {
        padding: theme.spacing(2),
        "& .MuiTableHead-root": {
          backgroundColor: "#f2f6f7",
        },
        "& .MuiTableCell-head": {
          border: "none",
          padding: "8px 8px",
        },
        "& .MuiTableCell-root": {
          fontFamily: "inherit",
        },
      },
      ".DashboardCardContracted": {
        "& .MuiCardHeader-action": { margin: 0 },
        "& .icon": {
          padding: 8,
          display: "block",
          width: 40,
          height: 40,
          borderRadius: "50%",
        },
        "&:hover": {
          "& .icon": {
            backgroundColor: "#f0f0f0",
          },
        },
      },
      ".chipLastLogin": {
        float: "right",
      },
      ".dialogTitle_root": { margin: 0, padding: theme.spacing(2) },
      ".dialogTitle_closeButton": {
        position: "absolute",
        right: theme.spacing(0.5),
        top: theme.spacing(0.5),
        color: theme.palette.grey[500],
      },

      ".dialogTitle_center": {
        position: "absolute",
        right: "80px",
        top: theme.spacing(1.5),
        color: theme.palette.grey[500],
      },

      ".dialogActions_root": {
        margin: 0,
        padding: theme.spacing(1),
        backgroundColor: styles.cardHeaderBgColor,
        "& .MuiTypography-root": {
          color: styles.cardHeaderColor,
        },
      },
      ".dialogContent_root": {
        padding: theme.spacing(2),
        minHeight: 0
      },
      ".buttonDelete": {
        backgroundColor: "#ec4139",
        color: "#fff",
        margin: "8px",
        "&:hover": {
          backgroundColor: "#ff3b31",
        },
      },
      ".dashboardCardTabs-root": {
        flexGrow: 1,
        width: "100%",
      },
      ".sidebarnav-list": {
        width: "100%",
        backgroundColor: theme.palette.background.paper,

        "& .MuiListItem-button": {
          paddingTop: 4,
          paddingBottom: 4
        }
      },
      ".nested": {
        "& .Mui-selected": {
          borderLeft: "2px solid",
          borderLeftColor: styles.colors.secondary,
        },
        "& .MuiListItem-root": {
          paddingLeft: theme.spacing(2),
        },
      },
      ".tabPanel_content": {
        padding: theme.spacing(2),
        minHeight: 400,
      },
      ".cardView_container": {
        marginLeft: 0,
      },
      ".large": {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      ".MuiCardActions-root": {
        backgroundColor: styles.cardHeaderBgColor,
      },
      ".MuiCardHeader-root": {
        backgroundColor: styles.cardHeaderBgColor,
        "&.dark": {
          backgroundColor: styles.cardHeaderBgDarkColor,
          "& .MuiCardHeader-content": { color: styles.cardHeaderDarkColor },
          "& .MuiCardHeader-title": { color: styles.cardHeaderDarkColor },
        },
      },
      ".MuiCardHeader-title": { fontSize: 18 },
      ".MuiCardHeader-action": { margin: 0 },
      ".MuiChip-root": {
        "& .MuiIcon-root": { color: styles.chipIconColor },
        "& .MuiChip-deleteIcon": { color: styles.chipIconColor },
      },
      ".MuiChip-clickable": {
        "&:hover": { backgroundColor: styles.chipBgColorActive },
        "&:focus": { backgroundColor: styles.chipBgColorActive },
      },
      ".toolbarTab_title ": {
        fontSize: 16,
      },
      ".cardView_Tours": {
        "& .icon_added": {
          position: "absolute",
          backgroundColor: "white",
          top: 0,
          left: 0,
          height: 24,
          width: 24,
          borderRadius: "50%",
          boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
          textAlign: "center",
          color: "green",
          zIndex: 10,
        },
        "& .tour": {
          position: "relative",
          overflow: "visible",
          borderBottom: "1px solid #d2d2d2",
          marginBottom: theme.spacing(1.5),
          paddingBottom: theme.spacing(1),
          borderRadius: 0,
          "&:hover": {
            backgroundColor: "#f8fbfc",
          },
        },
        "& .no-tours-available": {
          minHeight: 100,
          "& .MuiAlert-icon": {
            fontSize: 27,
          },
        },
        "& .title_card_tour": {
          position: "relative",
          lineHeight: 1.2,
          fontSize: 18,
          "& .MuiChip-root": {
            marginLeft: theme.spacing(1.5),
            position: "absolute",
            right: 0,
            top: 0,
          },
        },

        "& .MuiListItem-root": {
          paddingTop: 0,
          paddingBottom: 0,
          alignItems: "start",
          marginBottom: 8,
        },
        "& .MuiListItemText-root": {
          marginTop: 0,
          marginBottom: 0,
        },
        "& .MuiListItemSecondaryAction-root": {
          top: "40%",
          right: 0,
        },
        "& .listItemTour": {
          "& .MuiAvatar-root": {
            width: 45,
            height: 45,
          },
          "& .MuiListItemText-primary": {
            color: styles.headingsColor,
            fontWeight: 600,
            fontSize: 13,
            letterSpacing: "normal",
            lineHeight: "1.2em",
            marginBottom: theme.spacing(0.5),
          },
          "& .MuiListItemText-secondary": {
            fontSize: 12,
            letterSpacing: "normal",
            lineHeight: 1.2,
          },
          "& .MuiListItemText-root ": {
            borderBottom: "1px solid #d2d2d2",
            paddingBottom: theme.spacing(1),
          },
          "& .btn-container": {
            marginTop: 0,
            marginRight: -16,
          },
        },
        "& .tabPanel_content": {
          padding: 0,
        },

        "& .col_avatar": {
          "& .MuiAvatar-root": {
            width: 60,
            height: 60,
          },
          [theme.breakpoints.up("md")]: {
            maxWidth: 80,
            flexBasis: 80,
          },
        },
        "& .col_description": {
          [theme.breakpoints.up("md")]: {
            maxWidth: "calc(75% - 160px)",
            flexBasis: "calc(75% - 160px)",
          },
        },
        "& .col_labels": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.up("md")]: {
            maxWidth: "25%",
            flexBasis: "25%",
          },
          "& .MuiChip-root": {
            marginLeft: 16,
          },
          "& span": { verticalAlign: "middle" },
          "& .MuiIcon-root": { fontSize: "1.2rem" },
        },
        "& .col_labelsMobile": {
          display: "block",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.up("md")]: {
            maxWidth: "25%",
            flexBasis: "25%",
          },
          "& .MuiChip-root": {
            marginLeft: 10,
          },
          "& span": { verticalAlign: "middle" },
          "& .MuiIcon-root": { fontSize: "1.2rem" },
        },
        "& .col_actions": {
          textAlign: "right",
          [theme.breakpoints.up("md")]: {
            maxWidth: 80,
            flexBasis: 80,
          },
        },
      },
      ".collapse_content_container": {
        paddingTop: theme.spacing(1.5),
        "& .title": {
          color: styles.headingsColor,
          fontWeight: 600,
        },
      },
      ".collapse_link": {
        padding: 0,
        textDecoration: "none",
        cursor: "pointer",
      },
      ".collapse": {
        "& .expand": {
          transform: "rotate(0deg)",
          marginLeft: "auto",
          transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
          }),
        },
        "& .expandOpen": {
          transform: "rotate(180deg)",
        },
      },
      ".MuiCardContent-root": {
        paddingBottom: "16px !important",
      },
      ".MuiList-root": {
        "& .MuiListItem-container:last-child": {
          "& .MuiListItem-root": {
            marginBottom: 0,
          },
        },
      },
      ".accordion_paper": {
        padding: 0,
        marginBottom: theme.spacing(1.5),
      },
      ".custom_accordion": {
        margin: "0!important",
        backgroundColor: "transparent",
        padding: 0,
        boxShadow: "none",
        borderBottom: "1px solid #dbdffb",
        "&:before": { display: "none" },
        "& .MuiIconButton-edgeEnd": {
          marginRight: 0,
        },
        "& .MuiAccordionSummary-root": {
          padding: "0 8px",
          backgroundColor: "#f1f8f9",
          minHeight: "inherit",
        },
        "& .MuiAccordionSummary-content": { margin: 0 },
        "& .title_accordion": {
          margin: 0,
          textTransform: "uppercase",
          fontSize: 12,
        },
        "& .MuiAccordionDetails-root": {
          padding: 0,
          display: "block",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "inherit",
        },
        "& .tour": {
          padding: "8px 8px 8px 12px",
          marginBottom: 0,
        },

        "& .MuiIconButton-root": {
          padding: 8,
        },
      },
      ".partners_accordion": {
        "& .MuiAccordionSummary-content": {
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center"
        }
      },
      ".chips": {
        "& .chip:hover": {
          backgroundColor: "#f7f4ff",
        },
        "& .chip.selected": {
          backgroundColor: "#f7f4ff",
        },
      },
      ".isDragging": {
        background: styles.draggableBgColor,
        borderLeft: "3px solid",
        borderColor: styles.draggableColor,
      },
      ".tableDragDrop": {
        "& .wrapper-add-button": {
          margin: 0,
        },
      },
      ".filters": {
        display: "flex",
        justifyContent: "start",
        flexWrap: "wrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
        "& .title_filters": {
          padding: theme.spacing(1),
          textTransform: "uppercase",
          fontSize: 14,
          lineHeight: "30px",
        },
        "& .title_filters_mobile": {
          marginRight: "30px",
          padding: theme.spacing(1),
          textTransform: "uppercase",
          fontSize: 12,
          lineHeight: "30px",
        },
        "& .autocomplete_container": {
          flexGrow: 1,
        },

        "& .button_filter": {
          padding: 4,
        },
        "& .MuiFormControl-root": {
          marginBottom: 0,
          paddingTop: 0,
        },
        "& .MuiFormLabel-root": {
          display: "none",
        },
        "& .MuiInputBase-root": {
          padding: theme.spacing(0.5),
        },
      },
      ".list-item-autocomplete": {},
      ".list-items-cell": {
        padding: "0 4px 0 0",
        flexGrow: 0,
        overflow: "hidden",
        fontSize: 14,

        "& .wrapper-add-button": { width: 35, margin: 1 },
        "& .add-button": { width: 35, height: 35 },
        "& .text": { display: "block" },
      },

      ".list-items-icon": {
        padding: "0 0 0 0",
      },

      "#popover-advanced-search": {},

      ".autocomplete_custom_options": {
        "& .MuiAutocomplete-option": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
      },
      ".tours_attractions": {
        padding: "1rem",
        border: "1px solid #ccc",
        borderRadius: 4,
        marginTop: 5,

        "& .filter_tours_attractions": {
          marginBottom: 0,

          "& .title_filters": {
            marginRight: 8,
          },
        },
        "& .table_tours_attractions": {
          marginTop: 8
        },
      },
      ".autocomplete_combo_container": {
        maxWidth: 500,
        width: "100%",
        position: "relative",
      },

      ".filter_tours_chips": {
        "& .chip": {
          margin: 6,
          "& .MuiIcon-root": { color: styles.colors.primary },
        },
        "& .chip.selected": {
          "& .MuiIcon-root": { color: styles.colors.primary },
        },

        "& .chips": { maxWidth: "80%" },
        "& .chips.search-open": {
          maxWidth: "57%",
        },
      },
      ".filter_tours_chips_Guide": {
        "& .chip": {
          margin: 6,
          "& .MuiIcon-root": { color: styles.colors.primary },
        },
        "& .chip.selected": {
          "& .MuiIcon-root": { color: styles.colors.primary },
        },

        "& .chips": { maxWidth: "65%" },
        "& .chips.search-open": {
          maxWidth: "57%",
        },
      },

      ".label-type-tour": {
        backgroundColor: styles.chipBgColor,
        color: styles.chipColor,
      },

      ".label-counter": {
        position: "relative",
        paddingRight: 20,
        "& .MuiBadge-badge": {
          backgroundColor: styles.chipBgColor,
          color: styles.chipColor,
          right: 0,
          top: 13,
        },
      },
      ".my-tours-location": {
        marginBottom: theme.spacing(1),
        textTransform: "uppercase",
        fontSize: 12,
      },
      ".list-item-mytours": {
        marginBottom: theme.spacing(1),
        "& .btn-container": { marginRight: -16 },
      },
      ".listItemTour": {
        "& .MuiListItemText-primary": { margin: "0!important" },
      },
      ".btn-container": {
        marginRight: "-10px",
        marginLeft: "auto",
        display: "inline-block",
      },
      ".wrapper-add-button": {
        margin: theme.spacing(1),
        position: "relative",
        width: 35,
      },
      ".fabProgress": {
        color: styles.colors.primary,
        position: "absolute",
        top: -2,
        left: -2,
        zIndex: 1,
      },
      ".loginProgress": {
        color: "#06C2C9",
        zIndex: 1
      },

      ".wrapperButton": {
        position: "relative",
        display: "inline-block"
      },

      ".add-button": {
        boxShadow: styles.boxShadow,
        backgroundColor: styles.colors.accentColor,
        color: "white",
        "&:hover": { backgroundColor: styles.colors.accentColorHover },
      },
      ".remove-button": {
        border: "2px solid",
        borderColor: styles.colors.red,
        color: styles.colors.red,
        width: 35,
        height: 35,
      },
      ".tour-details-title": {
        marginBottom: theme.spacing(2),
      },
      ".card_mytours": {
        position: "sticky",
        top: 80,
        bottom: 20,
        zIndex: 5,
        "& .MuiCardContent-root": {
          maxHeight: "calc(100vh - 180px)",
          overflowY: "auto",
          overflowX: "hidden",
        },
      },
      ".empty-list": {
        textAlign: "center",
        padding: 20,
        "& span": {
          verticalAlign: "top",
        },
        "& .MuiAvatar-root": {
          margin: "auto",
          marginBottom: 16,
          "&.large": {
            width: 80,
            height: 80,
          },
        },

        "&.no-documents": {
          padding: 16,
        },
        "& .MuiTypography-body1": {
          fontWeight: "600",
          marginBottom: 8,
          fontSize: "12px",
        },
        "& .MuiButton-root": {
          marginTop: 20,
        },
      },
      ".closeButton": {
        position: "absolute",
        right: theme.spacing(0.5),
        top: theme.spacing(0.5),
        padding: 8,
      },
      ".closeDelete": {
        position: "absolute",
        right: theme.spacing(5.5),
        top: theme.spacing(0.5),
        padding: 8,
        color: styles.iconDeleted,
      },
      ".card_contracted": {
        "& .MuiCardHeader-root": {
          backgroundColor: "white",
          minHeight: 84,
        },
      },
      ".sticky-appbar": {
        position: "sticky",
        top: 0,
        bottom: 20,
        zIndex: 5,
        backgroundColor: styles.body.background,
        padding: "0 4px 0",
        marginLeft: -4,
        paddingTop: 20,
      },
      ".sticky-appbar-mobile": {
        position: "sticky",
        top: 0,
        bottom: 0,
        zIndex: 5,
        backgroundColor: styles.body.background,
        padding: "0 4px 0",
        marginLeft: -4,
        paddingTop: 0,
        marginBottom: 10
      },
      ".tours-tabs-app-bar": {
        borderRadius: "4px 4px 0 0",
        backgroundColor: "white",
        overflow: "hidden",
        borderBottom: "1px solid #f7f4ff",
        "& .MuiTab-root": {
          padding: theme.spacing(4),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          position: "relative",
          backgroundColor: "white",
          transition: styles.transitionButton,
          "& .MuiTypography-root": {
            textTransform: "none",
          },
          "& .fabProgress": {
            left: 8,
            top: 18,
          },
          "&.addingRemoving": {
            backgroundColor: "#f7f4ff",
            "& .label-counter .MuiBadge-badge": {
              backgroundColor: styles.colors.accentColor,
              color: "white",
            },
          },
          "&.addedRemoved": {
            backgroundColor: "inherit",
            "& .label-counter .MuiBadge-badge": {
              backgroundColor: styles.chipBgColor,
              color: styles.chipColor,
            },
          },
        },
      },
      ".tours-tabs": {
        overflow: "hidden",
        borderRadius: "0 0 12px 12px",
      },
      ".disabled": {
        backgroundColor: "#f0f0f0",
      },
      ".flat-card": { border: "none", boxShadow: "none" },
      ".cities-chips": { marginBottom: 16, textAlign: "left", width: "100%" },
      ".city-chip": {
        marginRight: 8,
        marginBottom: 4,
        backgroundColor: "#f7f4ff",
        "& .MuiIcon-root": { color: styles.colors.primary },
      },
      ".attractions-chips": { marginBottom: 16 },
      ".attraction-chip": { marginRight: 8, marginBottom: 4 },
      ".notes": {
        marginTop: 12,
      },
      ".vertical-tabs": {
        flexGrow: 1,

        display: "flex",
        height: 224,
        "& .tabs": {
          borderRight: `1px solid ${theme.palette.divider}`,
          width: "100%",
          maxWidth: 150,
        },
        "& .MuiTab-root": {
          padding: 0,
        },
        "& .MuiTab-wrapper": {
          alignItems: "start",
          fontSize: 12,
          position: "relative",
        },
        "& .tab-panel-notes": {
          padding: 12,
        },
        "& .title": {
          marginBottom: 12,
        },
        "& .title-note": {
          marginBottom: 12,
        },
      },
      ".avatar-profile": {
        width: 80,
        height: 80,
        cursor: "pointer",
        margin: "auto",
        backgroundColor: "#e7eaff",
        color: styles.colors.primary,
        marginBottom: 12,
        "&:hover": {
          boxShadow: styles.boxShadowButtonHover,
        },
        "& .MuiIcon-root": {
          fontSize: 30,
        },
      },
      ".button-upload": {
        backgroundColor: "#F2F5FC",
        color: "#6a77c5",
        boxShadow: "none",
        padding: "8px 12px",
        fontSize: 12,
        textTransform: "uppercase",
        margin: "auto",
        "&:hover": {
          backgroundColor: "#e7eaff",
          boxShadow: styles.boxShadowButtonHover,
        },
      },
      ".TabPanel.Tours": {
        marginTop: "0!important",
      },
      ".dialog-upload": {
        "& .MuiDropzoneArea-text": {
          fontSize: 20,
        },

        "& .MuiDialog-paperWidthSm": {
          width: "100%",
        },

        "& .MuiTypography-subtitle1": {
          marginBottom: 8,
        },

        "& .MuiDialogContent-root": {
          overflowX: "hidden",
        },

        "& .MuiDropzonePreviewList-imageContainer": {
          padding: "16px !important",
          width: "fit-content !important",
          maxWidth: "fit-content",
          flexBasis: "auto",
        },

        "& .MuiGrid-spacing-xs-8": {
          width: "100%",
          margin: 0,
        },

        "& .MuiDropzoneArea-root": {
          minHeight: 0,
          height: "fit-content",
          marginBottom: 12,
          paddingBottom: 12
        },

        "& .custom-overlay__caption": {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          position: "absolute",
          bottom: "0",
          width: "100%",
          color: "white",
          padding: "2px",
          fontSize: "90%",
          overflowWrap: "anywhere"
        },

        "& .filterGalleryField": {
          paddingTop: 0
        },

        "& .pointer": {
          cursor: "pointer"
        }
      },

      ".dialog-details-list": {
        "& #dialog-upload-title": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        },

        "& .MuiDropzoneArea-text": {
          fontSize: 20,
        },

        "& .MuiDialog-paperWidthSm": {
          width: "100%",
          maxWidth: "90%",
          minHeight: "40%",
        },
        "& .MuiDialog-paperWidthMd": {
          width: "100%",
          maxWidth: "1000px",
          minHeight: "40%",
        },

        "& .MuiTypography-subtitle1": {
          marginBottom: 8,
        },

        "& .MuiDialogContent-root": {
          overflowX: "hidden",
        },
      },

      ".documents_table_row": {
        "& .MuiTableCell-root": {
          padding: "16px 12px",
          "&.cell_name_icon": {
            paddingLeft: "100px!important",
            position: "relative",
          },
        },

        "& .name_icon": {
          position: "absolute",
          left: 8,
          top: 0,
          bottom: 0,
          margin: "auto",
          height: 36,
        },
      },
      ".label_title": {
        color: styles.inputsLabelColor,
        fontSize: styles.inputsLabelFontSize,
        fontWeight: styles.inputsLabelFontWeight,
        lineHeight: 1.2,
        fontFamily: styles.body.fontFamily,
        marginBottom: 16,
      },
      ".label_title_checkbox": {
        color: styles.inputsLabelColor,
        fontSize: styles.inputsLabelFontSize,
        fontWeight: styles.inputsLabelFontWeight,
        lineHeight: 1.2,
        fontFamily: styles.body.fontFamily,
        
      },
      ".selectable_chips": {
        "& .selectable_chips_container": { display: "flex" },

        "& .chips": {
          marginBottom: 16,
          "& .chip": {
            marginRight: 8,
            marginBottom: 8,
          },
        },
        "& .MuiChip-root .MuiIcon-root": { color: styles.chipIconColorActive },
        "& .button_filter": {
          width: "22%",
        },
        "& .autocomplete_container": {
          display: "flex",
          alignItems: "center",
          "& .MuiAutocomplete-root": {
            width: "30%",
            marginRight: 8,
          },
          "& .MuiButton-root": {
            maxHeight: 36,
          },
          "& .button_filter": {
            width: "30%",
            marginTop: 8,
          },
        },
      },
      ".MuiTypography-root.leyend-form": {
        fontSize: 18,
        fontFamily: styles.fontsHeadings,
      },
      ".tabsDestinationsDetail": {
        "& .MuiTab-root": {
          minWidth: 130,
        },
      },
      ".actions-buttons": {
        marginTop: 16,
        "& .MuiButton-root": { marginRight: 12 },
      },
      ".caption-table": {
        fontSize: 14,
        fontFamily: styles.fontsHeadings,
      },
      ".label-summary": {
        backgroundColor: styles.chipBgColor,
        color: styles.chipColor,
        textAlign: "center",
        borderRadius: 16,
        display: "inline-block",
        padding: "4px 12px",
        fontSize: 12,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        border: "1px solid",
        borderColor: styles.chipColor,
        marginRight: 16,
      },

      ".label-cancelation": {
        display: "block",
        fontSize: "12px",
        marginTop: "5px",

        color: "#666",
        width: "100%",
      },

      ".tittle-summary": {
        marginTop: "-8px",
      },

      ".label-tag": {
        backgroundColor: styles.chipBgColor,
        color: styles.chipColor,
        textAlign: "center",
        borderRadius: 16,
        display: "inline-block",
        padding: "4px 12px",
        fontSize: 12,
        maxWidth: 120,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        "&.statusPublished": {
          backgroundColor: styles.tagColorPublished,
          color: "white",
        },
        "&.statusPUBLISHED": {
          backgroundColor: styles.tagColorPublished,
          color: "white",
        },
        "&.statusGUIDECONFIRMATION": {
          backgroundColor: styles.tagColorPublished,
          color: "white",
        },
        "&.statusCUSTOMERCONFIRMATION": {
          backgroundColor: styles.tagColorPublished,
          color: "white",
        },
        "&.statusACTIVE": {
          backgroundColor: styles.tagColorPublished,
          color: "white",
        },
        "&.statusDRAFT": {
          backgroundColor: styles.tagColor,
        },
        "&.review": {
          backgroundColor: "rgb(255 165 0 / 40%)",
          color: "black",
        },

        "&.statusAPPROVED": {
          backgroundColor: styles.tagColorPublished,
          color: "white",
        },
        "&.statusCONFIRMED": {
          backgroundColor: styles.tagColorPublished,
          color: "white",
        },

        "&.statusOTHER": {
          backgroundColor: styles.tagColor,
        },
        "&.statusPENDING": {
          backgroundColor: "#ffeaea",
          color: "#ff4646",
        },
        "&.statusCANCELLED": {
          backgroundColor: "#ffeaea",
          color: "#ff4646",
        },
      },
      ".breadcrumbs_destinations_list": {
        "& .MuiBreadcrumbs-separator": {
          opacity: ".4",
        },
        "& .MuiBreadcrumbs-li": {
          "& .MuiLink-root": {
            opacity: ".4",
            minWidth: 80,
            fontSize: 12,
            display: "inline-block",
            "&:hover": {
              opacity: ".8",
              color: styles.linkColorHover,
            },
          },
          "&:last-child": {
            "& .MuiLink-root": {
              opacity: "1",
              fontSize: 15,
              "&:hover": {
                opacity: "1",
              },
            },
          },
          "&.MuiBreadcrumbs-separator:last-child": {
            color: styles.linkColorHover,
          },
        },
      },
      ".table-collapsible": {
        border: "none",
        backgroundColor: "#fbfbfb",
        width: "100%",
      },

      ".tableListCollapse.MuiGrid-container": {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box",
        alignItems: "center",
        "& .MuiTypography-body1": { fontFamily: "inherit" },
        "&:not(:last-child)": {
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        },
        "& .MuiGrid-item": {
          margin: "0",
          boxSizing: "border-box",
          display: "block",
          border: "none",
          "& span": { verticalAlign: "middle" },
        },
        "& .MuiGrid-grid-xs-0-5": {
          flexGrow: 0,
          maxWidth: "4.165%",
          flexBasis: "4.165%",
        },
        "& .MuiGrid-grid-xs-1": {
          flexGrow: 0,
          maxWidth: "8.33%",
          flexBasis: "8.33%",
        },

        "& .MuiGrid-grid-xs-1-5": {
          flexGrow: 0,
          maxWidth: "12.49%",
          flexBasis: "12.49%",
        },
        "& .MuiGrid-grid-xs-2": {
          flexGrow: 0,
          maxWidth: "16.66%",
          flexBasis: "16.66%",
        },
        "& .MuiGrid-grid-xs-2-5": {
          flexGrow: 0,
          maxWidth: "20.81%",
          flexBasis: "20.81%",
        },
        "& .MuiGrid-grid-xs-3": {
          flexGrow: 0,
          maxWidth: "25%",
          flexBasis: "25%",
        },
        "& .MuiGrid-grid-xs-3-5": {
          flexGrow: 0,
          maxWidth: "29.16%",
          flexBasis: "29.16%",
        },
        "& .MuiGrid-grid-xs-4": {
          flexGrow: 0,
          maxWidth: "33.33%",
          flexBasis: "33.33%",
        },
        "& .MuiGrid-grid-xs-4-5": {
          flexGrow: 0,
          maxWidth: "37.5%",
          flexBasis: "37.5%",
        },
        "& .MuiGrid-grid-xs-5": {
          flexGrow: 0,
          maxWidth: "41.66%",
          flexBasis: "41.66%",
        },
        "& .MuiGrid-grid-xs-5-5": {
          flexGrow: 0,
          maxWidth: "45.83%",
          flexBasis: "45.83%",
        },
        "& .MuiGrid-grid-xs-6": {
          flexGrow: 0,
          maxWidth: "50%",
          flexBasis: "50%",
        },
        "& .MuiGrid-grid-xs-6-5": {
          flexGrow: 0,
          maxWidth: "54.16%",
          flexBasis: "54.16%",
        },
        "& .MuiGrid-grid-xs-7": {
          flexGrow: 0,
          maxWidth: "58.33%",
          flexBasis: "58.33%",
        },
        "& .MuiGrid-grid-xs-7-5": {
          flexGrow: 0,
          maxWidth: "62.5%",
          flexBasis: "62.5%",
        },
        "& .MuiGrid-grid-xs-8": {
          flexGrow: 0,
          maxWidth: "66.66%",
          flexBasis: "66.66%",
        },
        "& .MuiGrid-grid-xs-9": {
          flexGrow: 0,
          maxWidth: "75%",
          flexBasis: "75%",
        },
        "& .MuiGrid-grid-xs-10": {
          flexGrow: 0,
          maxWidth: "83.33%",
          flexBasis: "83.33%",
        },
        "& .MuiGrid-grid-xs-11": {
          flexGrow: 0,
          maxWidth: "91.66%",
          flexBasis: "91.66%",
        },
        "& .MuiGrid-grid-xs-15": {
          flexGrow: 0,
          maxWidth: "15%",
          flexBasis: "15%",
        },
        "& .MuiGrid-grid-xs-20": {
          flexGrow: 0,
          maxWidth: "20%",
          flexBasis: "20%",
        },
        "& .MuiGrid-grid-xs-60": {
          flexGrow: 0,
          maxWidth: "60%",
          flexBasis: "60%",
        },
        "& .MuiGrid-grid-xs-70": {
          flexGrow: 0,
          maxWidth: "70%",
          flexBasis: "70%",
        },
        "& .MuiGrid-grid-xs-100": {
          flexGrow: 0,
          maxWidth: "100%",
          flexBasis: "100%",
        },
      },

      ".MuiGrid-container": {
      "& .MuiGrid-item": {
        margin: "0",
        boxSizing: "border-box",
        display: "block",
        border: "none",
        "& span": { verticalAlign: "middle" },
      },
      "& .MuiGrid-grid-xs-0-5": {
        flexGrow: 0,
        maxWidth: "4.165%",
        flexBasis: "4.165%",
      },
      "& .MuiGrid-grid-xs-1": {
        flexGrow: 0,
        maxWidth: "8.33%",
        flexBasis: "8.33%",
      },

      "& .MuiGrid-grid-xs-1-5": {
        flexGrow: 0,
        maxWidth: "12.49%",
        flexBasis: "12.49%",
      },
      "& .MuiGrid-grid-xs-2": {
        flexGrow: 0,
        maxWidth: "16.66%",
        flexBasis: "16.66%",
      },
      "& .MuiGrid-grid-xs-2-5": {
        flexGrow: 0,
        maxWidth: "20.81%",
        flexBasis: "20.81%",
      },
      "& .MuiGrid-grid-xs-3": {
        flexGrow: 0,
        maxWidth: "25%",
        flexBasis: "25%",
      },
      "& .MuiGrid-grid-xs-3-5": {
        flexGrow: 0,
        maxWidth: "29.16%",
        flexBasis: "29.16%",
      },
      "& .MuiGrid-grid-xs-4": {
        flexGrow: 0,
        maxWidth: "33.33%",
        flexBasis: "33.33%",
      },
      "& .MuiGrid-grid-xs-4-5": {
        flexGrow: 0,
        maxWidth: "37.5%",
        flexBasis: "37.5%",
      },
      "& .MuiGrid-grid-xs-5": {
        flexGrow: 0,
        maxWidth: "41.66%",
        flexBasis: "41.66%",
      },
      "& .MuiGrid-grid-xs-5-5": {
        flexGrow: 0,
        maxWidth: "45.83%",
        flexBasis: "45.83%",
      },
      "& .MuiGrid-grid-xs-6": {
        flexGrow: 0,
        maxWidth: "50%",
        flexBasis: "50%",
      },
      "& .MuiGrid-grid-xs-6-5": {
        flexGrow: 0,
        maxWidth: "54.16%",
        flexBasis: "54.16%",
      },
      "& .MuiGrid-grid-xs-7": {
        flexGrow: 0,
        maxWidth: "58.33%",
        flexBasis: "58.33%",
      },
      "& .MuiGrid-grid-xs-7-5": {
        flexGrow: 0,
        maxWidth: "62.5%",
        flexBasis: "62.5%",
      },
      "& .MuiGrid-grid-xs-8": {
        flexGrow: 0,
        maxWidth: "66.66%",
        flexBasis: "66.66%",
      },
      "& .MuiGrid-grid-xs-9": {
        flexGrow: 0,
        maxWidth: "75%",
        flexBasis: "75%",
      },
      "& .MuiGrid-grid-xs-10": {
        flexGrow: 0,
        maxWidth: "83.33%",
        flexBasis: "83.33%",
      },
      "& .MuiGrid-grid-xs-11": {
        flexGrow: 0,
        maxWidth: "91.66%",
        flexBasis: "91.66%",
      },
      "& .MuiGrid-grid-xs-15": {
        flexGrow: 0,
        maxWidth: "15%",
        flexBasis: "15%",
      },
      "& .MuiGrid-grid-xs-20": {
        flexGrow: 0,
        maxWidth: "20%",
        flexBasis: "20%",
      },
      "& .MuiGrid-grid-xs-60": {
        flexGrow: 0,
        maxWidth: "60%",
        flexBasis: "60%",
      },
      "& .MuiGrid-grid-xs-70": {
        flexGrow: 0,
        maxWidth: "70%",
        flexBasis: "70%",
      },
      "& .MuiGrid-grid-xs-100": {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },


      ".MuiTableRow-root": {
        "&:not(:last-child)": {
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        },
      },
      ".MuiTableRow-root.MuiGrid-container": {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box",
        alignItems: "center",
        "& .MuiTypography-body1": { fontFamily: "inherit" },

        "& .MuiGrid-item": {
          margin: "0",
          boxSizing: "border-box",
          display: "block",
          border: "none",
          "& span": { verticalAlign: "middle" },
        },
        "& .MuiGrid-grid-xs-0-5": {
          flexGrow: 0,
          maxWidth: "4.165%",
          flexBasis: "4.165%",
        },
        "& .MuiGrid-grid-xs-1": {
          flexGrow: 0,
          maxWidth: "8.33%",
          flexBasis: "8.33%",
        },
        "& .MuiGrid-grid-xs-1-5": {
          flexGrow: 0,
          maxWidth: "12.49%",
          flexBasis: "12.49%",
        },
        "& .MuiGrid-grid-xs-2": {
          flexGrow: 0,
          maxWidth: "16.66%",
          flexBasis: "16.66%",
        },
        "& .MuiGrid-grid-xs-2-5": {
          flexGrow: 0,
          maxWidth: "20.81%",
          flexBasis: "20.81%",
        },
        "& .MuiGrid-grid-xs-3": {
          flexGrow: 0,
          maxWidth: "25%",
          flexBasis: "25%",
        },
        "& .MuiGrid-grid-xs-3-5": {
          flexGrow: 0,
          maxWidth: "29.16%",
          flexBasis: "29.16%",
        },
        "& .MuiGrid-grid-xs-4": {
          flexGrow: 0,
          maxWidth: "33.33%",
          flexBasis: "33.33%",
        },
        "& .MuiGrid-grid-xs-4-5": {
          flexGrow: 0,
          maxWidth: "37.5%",
          flexBasis: "37.5%",
        },
        "& .MuiGrid-grid-xs-5": {
          flexGrow: 0,
          maxWidth: "41.66%",
          flexBasis: "41.66%",
        },
        "& .MuiGrid-grid-xs-5-5": {
          flexGrow: 0,
          maxWidth: "45.83%",
          flexBasis: "45.83%",
        },
        "& .MuiGrid-grid-xs-6": {
          flexGrow: 0,
          maxWidth: "50%",
          flexBasis: "50%",
        },
        "& .MuiGrid-grid-xs-6-5": {
          flexGrow: 0,
          maxWidth: "54.16%",
          flexBasis: "54.16%",
        },
        "& .MuiGrid-grid-xs-7": {
          flexGrow: 0,
          maxWidth: "58.33%",
          flexBasis: "58.33%",
        },
        "& .MuiGrid-grid-xs-7-5": {
          flexGrow: 0,
          maxWidth: "62.5%",
          flexBasis: "62.5%",
        },
        "& .MuiGrid-grid-xs-8": {
          flexGrow: 0,
          maxWidth: "66.66%",
          flexBasis: "66.66%",
        },
        "& .MuiGrid-grid-xs-9": {
          flexGrow: 0,
          maxWidth: "75%",
          flexBasis: "75%",
        },
        "& .MuiGrid-grid-xs-10": {
          flexGrow: 0,
          maxWidth: "83.33%",
          flexBasis: "83.33%",
        },
        "& .MuiGrid-grid-xs-11": {
          flexGrow: 0,
          maxWidth: "91.66%",
          flexBasis: "91.66%",
        },
        "& .MuiGrid-grid-xs-15": {
          flexGrow: 0,
          maxWidth: "15%",
          flexBasis: "15%",
        },
        "& .MuiGrid-grid-xs-20": {
          flexGrow: 0,
          maxWidth: "20%",
          flexBasis: "20%",
        },
        "& .MuiGrid-grid-xs-60": {
          flexGrow: 0,
          maxWidth: "60%",
          flexBasis: "60%",
        },
        "& .MuiGrid-grid-xs-70": {
          flexGrow: 0,
          maxWidth: "70%",
          flexBasis: "70%",
        },
      },
      ".MuiGrid-root.MuiGrid-container.custom-grid": {
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true.MuiGrid-grid-xs-0-5":
          { flexGrow: 0, maxWidth: "4.16%", flexBasis: "4.16%" },
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true.MuiGrid-grid-xs-1-5":
          { flexGrow: 0, maxWidth: "12.49%", flexBasis: "12.49%" },
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true.MuiGrid-grid-xs-2-5":
          { flexGrow: 0, maxWidth: "20.80%", flexBasis: "20.80%" },
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true.MuiGrid-grid-xs-3-5":
          { flexGrow: 0, maxWidth: "29.16%", flexBasis: "29.16%" },
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true.MuiGrid-grid-xs-1-46":
          { flexGrow: 0, maxWidth: "10.84%", flexBasis: "10.84%" },
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true.MuiGrid-grid-xs-1-16":
          { flexGrow: 0, maxWidth: "14%", flexBasis: "14%" },
        "& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true.MuiGrid-grid-xs-8-5":
          { flexGrow: 0, maxWidth: "70.83%", flexBasis: "70.83%" },
      },
      ".comment_block": {
        marginBottom: 16,
        "& .comment_title": {
          marginBottom: 8,
          display: "inline-block",
          marginRight: 8,
        },
        "& .comment_name": { fontWeight: "600", marginRight: 12 },
        "& .comment_email": { marginRight: 12 },
        "& .comment_date": { fontSize: 14 },
        "& .comment_field_message": { paddingTop: 0 },
        "& .comment_message": { marginBottom: 8 },
        "& .comment_actions": {
          "& .MuiButton-root": { marginRight: 12 },
        },
        "& .buttonEdit": {
          "& .MuiButton-startIcon": { marginRight: 4 },
          "& .MuiIcon-root": { fontSize: 16 },
        },
      },
      ".tabTitle-Recycle": {
        color: styles.colors.red,
      },
      ".statusDELETED": {
        color: styles.colors.red,
        backgroundColor: "rgba(255, 70, 70, .1)",
      },

      ".json": {
        paddingTop: 8,
        paddingBottom: 8,
      },
      ".preload-linear-progress": {
        width: "100%",
        height: 10,
        marginBottom: 5,
        "& > * + *": {
          marginTop: theme.spacing(2),
        },
      },
      ".preload-linear-progress-Mobile": {
        width: "100%",
        height: 5,
        marginBottom: 0,
        "& > * + *": {
          marginTop: theme.spacing(2),
        },
      },
      ".preload-circular-progress": {
        margin: 0,
        position: "relative",
        textAlign: "center",
        "& .fabProgress": {
          top: 6,
          left: 0,
          right: 0,
          zIndex: 1,
          margin: "auto",
        },
      },
      ".wrapper-icon-preloader": {
        margin: theme.spacing(1),
        position: "relative",
        "& .fabProgress": {},
      },
      ".popover": {
        pointerEvents: "none",
        "& .paper": {
          padding: theme.spacing(1),
          maxWidth: 250,
        },
        "& .popover-text": {
          fontSize: 12,
        },
      },
      ".tabsAttractionsDetail": {
        "& .MuiTab-root": {
          minWidth: 125,
        },
      },
      ".tabsDetailMobile": {
        "& .MuiTab-root": {
          minWidth: 0,
        },
      },
      ".verified": {
        color: styles.colors.secondary,
      },
      ".table-collapse": {
        "& > *": {
          borderBottom: "unset",
        },
        "& .MuiTypography-root": {
          fontFamily: "inherit",
        },
      },
      ".MuiTab-textColorInherit": {
        opacity: 0.9,
      },
      ".label-price ": {
        padding: "4px 8px",
        backgroundColor: "#e5e8e9",
        borderRadius: 8,
      },
      ".btns_cart": {
        marginTop: 22,
      },
      ".btn_search": {
        height: 40,
        marginTop: 21,
      },
      ".TextFieldTextArea": {
        fontSize: "5px",
      },
      ".selectRole": {
        minwidth: "200px",

        "MuiListItem-gutters": {
          height: 10,
        },
      },

      ".customTextField": {
        position: "relative",
        width: "100%",

        "& .label": {
          color: styles.inputsLabelColor,
          fontSize: styles.inputsLabelFontSize,
          fontWeight: styles.inputsLabelFontWeight,
          width: "100%",
          lineHeight: 1.2,
          fontFamily: styles.body.fontFamily,
          top: 0,
          left: 0,
          position: "absolute",
        },
        "& .input": {
          padding: "0 8px",
          border: "1px solid ",
          width: "100%",
          backgroundColor: styles.inputBgColor,
          color: styles.inputColor,
          borderColor: "#086f73",
          borderRadius: 4,
          height: 40,
          overflow: "visible",
          "&:hover": { borderColor: "#7c87c7" },
          "& p": { marginTop: 8 },
        },
      },
      ".popover-cart": {
        width: "300px",
        fontSize: 12,
      },
      ".popover-ages": {
        width: "220px",
      },
      ".btn-popover-ages": {
        position: "absolute",
        right: 0,
        bottom: 6,
      },
      ".scheduleInput-container": {
        "& .MuiFormControl-root": { marginBottom: 0 },
        "& .SelectSmall": {
          "& .MuiSelect-selectMenu": {
            paddingTop: 9.5,
            paddingBottom: 8.5,
          },
        },
      },
      ".search-summary": {
        fontSize: 12,
        "& span": {
          marginRight: 8,
          display: "inline-block",
        },
      },
      ".summary-cart": {
        width: "auto",
        position: "absolute",
        right: 0,
        top: 0,
        paddingTop: 0,
        marginTop: "-4px",
        cursor: "pointer",
        display: "inline-block",
        "&.cardComponentSearch": {
          top: -54,
        },

        "& .input": {
          position: "relative",
          paddingLeft: 40,
        },
        "& .text": { paddingRight: 40 },
        "& .icon": {
          position: "absolute",
          left: 5,
          top: 7,
        },
        "& .btn-popover-summary-cart": {
          position: "absolute",
          top: 3,
          right: 3,
        },
      },
      ".break-line-grid": {
        marginBottom: "-14px",
        marginTop: "-14px",
      },
      ".title-group": {
        color: styles.headingsColor,
        fontWeight: "bold",
      },
      ".datepicker": {
        width: "100%",
      },
      a: {
        color: styles.linkColor,
      },
      ".select-quantity": {
        paddingTop: 0,
        marginBottom: 0,
      },

      ".inline-input": {
        position: "relative",
        display: "flex",
        alignItems: "center",

        "& .label": {
          color: styles.inputsLabelColor,
          fontSize: styles.inputsLabelFontSize,
          fontWeight: styles.inputsLabelFontWeight,
          fontFamily: styles.body.fontFamily,
        },
        "& .input": {
          display: "inline-block",
          marginLeft: 10,
          width: 140,
        },
        "& .MuiFormControl-root": {
          paddingTop: 0,
          marginBottom: 0,
        },
      },
      ".rate-item": {
        padding: "8px 12px",
        backgroundColor: styles.cardBgColor,
        marginBottom: 8,

        "& .MuiTypography-body2": {
          fontSize: 13,
        },
      },
      ".finance-item": {
        padding: "8px 12px",
        backgroundColor: "#f9fbfb",
        marginBottom: 8,

        "& .MuiTypography-body2": {
          fontSize: 13,
        },
      },
      ".btn-book-ticket": {
        height: 40,
      },
      ".total-price": {
        "& .price": { fontSize: 20 },
      },
      ".preload-modal": {
        textAlign: "center",
        "& .fabProgress": {
          margin: "20px auto",
          right: 0,
          position: "relative",
        },
      },
      ".detail_dashboard.TicketBookingProcess": {
        height: "calc(100vh - 64px)",
        position: "relative",
        overflow: "hidden",
        "& .content_detail_dashboard": {
          display: "flex",
          flexFlow: "row wrap",
          flexDirection: "column",
          paddingBottom: 24,
        },
        "& .top": {
          width: "100%",
        },
        "& .content-flex": {
          flex: 1,
          width: "100%",
          flexGrow: 1,
        },
        "& .cardTableContainer": {
          marginBottom: 0,
        },
      },
      ".flex-vh-container": {
        height: "80vh",
        position: "relative",
      },
      ".flex-vh-container-inner": {
        display: "flex",
        flexFlow: "row wrap",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },

      ".flex-vh-container-inner-top": {
        width: "100%",
      },
      ".flex-vh-container-inner-content-flex": {
        flex: 1,
        width: "100%",
      },
      ".table-sticky-container": {
        height: "100%",
        position: "relative",
      },
      ".table-sticky-header": {
        display: "flex",
        flexFlow: "row wrap",
        flexDirection: "column",
      },
      ".table-head-sticky": {
        height: 48,
        paddingRight: 20,
        backgroundColor: "#fafafa",
      },
      ".table-body-sticky": {
        flex: 1,
        overflowY: "auto",
        overflowX: "hidden",
        paddingBottom: 100,
      },
      ".list-item-autocomplete-booking-search": {
        "& .text": { fontSize: 13 },
      },
      ".btn-handleCollapse": {
        padding: 0,
        fontSize: 13,
        marginLeft: 10,
        textTransform: "uppercase",
        position: "relative",
        display: "inline-block",
        "&.show-less": {
          paddingLeft: 20,
          "& .MuiIcon-root": {
            left: -2,
          },
        },
        "& .MuiIcon-root": {
          position: "absolute",
          top: -2,
          fontSize: 20,
        },
      },
      ".link": {
        color: styles.linkColor,
        cursor: "pointer",
      },
      ".header-ticket-types": {
        padding: "0 0 8px 0",
        display: "flex",
      },
      ".selectSchelude .MuiSelect-selectMenu": {
        paddingTop: 7.5,
        paddingBottom: 7.5,
        fontSize: 14,
      },
      ".FilterTableListBookingSearch": {
        "& .MuiFormControl-root": { marginBottom: 0 },
      },
      ".grid-table-head": {
        backgroundColor: "#fafafa",
        "& .MuiGrid-item": {
          padding: "8px 5px",
          fontFamily: styles.tableHeadFontFamily,
          fontWeight: 600,
          fontSize: styles.tableHeadSize,
        },
      },

      ".grid-table-row": {
        "&:hover": { backgroundColor: "#fafafa" },
        "& .MuiGrid-item": {
          padding: "8px 5px",
          fontFamily: styles.tableHeadFontFamily,
        },
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      },
      ".grid-item-sort": {
        cursor: "pointer",
      },
      ".iconFilterSorter": {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: 18,
        marginTop: -4,
        cursor: "pointer",
      },

      ".booking-failed": {
        fontStyle: "italic",
        fontSize: 14,
        marginTop: 20,
      },

      ".label-search": {
        fontSize: "12px",
        marginTop: "5px",
        color: "#666",
        paddingRight: 30,
      },

      ".badge-alert": {
        paddingRight: 10,
      },

      ".error_people": {
        color: "#f44336",
        margin: "5px 0",
        fontSize: "0.75rem",
      },

      ".markdown-field": {
        padding: styles.inputsPadding,
        backgroundColor: "transparent",
        border: "1px solid",
        borderColor: styles.textAreaColor,
        color: styles.inputColor,
        borderRadius: styles.inputsBorderRadius,

        "& p": {
          fontFamily: styles.body.fontFamily,
        },
      },

      ".w-md-editor-toolbar li button[data-name='image']": {
        display: "none",
      },

      ".view-tickets-show .container": {
        paddingBottom: "10px",
      },

      ".error_tickets": {
        color: "#f44336",
        margin: "5px 0",
        fontSize: "0.75rem",
      },

      ".icon-button-chips": {
        fontSize: 13,
        position: "relative",
        top: 2,
      },

      ".icon-button-covid": {
        color: "#ff4646",
      },
      ".label-safety": {
        backgroundColor: styles.chipBgColor,
        color: styles.chipColor,
        textAlign: "center",
        borderRadius: 16,
        display: "inline-block",
        padding: "4px 12px",
        fontSize: 12,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        border: "1px solid",
        borderColor: "#ff4646",
        marginRight: 16,
      },
      ".MuiFormHelperText-root.Mui-error": {
        color: styles.textErrorColor,
        fontSize: "0.75rem",
        marginTop: "2px",
      },
      ".iconBookingStar": {
        color: styles.chipColor,
        fontSize: 15,
      },
      ".iconDeleted": {
        color: styles.iconDeleted,
        cursor: "pointer",
      },
      ".errorPricing": {
        color: styles.textErrorColor,
        fontSize: "0.75rem",
      },
      ".textsFieldsInline": {
        "& .MuiFormControl-root": {
          display: "flex",
          WebkitFlexDirection: "row",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          alignContent: "flex-start",
        },
        "& .MuiFormLabel-root": {
          position: "relative",
          maxWidth: "max-content",
          paddingRight: 10,
        },
        "& .MuiIconButton-root": {
          padding: 8,
        },
        "& .MuiOutlinedInput-adornedStart": {
          paddingLeft: 0,
          minWidth: 55,
        },
        "& .MuiInputAdornment-positionStart": {
          marginRight: 0,
        },
        "& .MuiFormControl-root fieldset": {
          fontSize: "90%",
        },
      },
      ".openingTimesItem": {
        marginBottom: 12,
        "& .MuiChip-root": {
          marginRight: 4,
        },
      },
      ".availability": {
        "& .vertical-tabs": {
          height: "inherit",
          "& .tabs": { maxWidth: 130 },
          "& .tabPanels_container": {
            marginTop: 0,
            width: "100%",
          },
        },
        "& .tab-panel-availability": {
          padding: "0 0 0 12px",
        },
        "& .MuiCardHeader-root": {
          minHeight: "52.5px"
        }
      },
      ".button-filter-pricing": {
        marginRight: 8,
      },
      ".MuiTabScrollButton-root": {
        "& .MuiSvgIcon-root": {
          border: "1px solid",
          borderColor: styles.colors.secondary,
          borderRadius: "50%",
        },
      },
      ".addPricing": {
        marginRight: 18,
      },

      ".bg-tablecell-green": {
        backgroundColor: "#F1FFE9",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12) !important",
      },
      ".bg-tablecell-yellow": {
        backgroundColor: "#FFF9E9",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12) !important",
      },

      ".bg-tablecell-red": {
        backgroundColor: "#FFE9E9",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12) !important",
      },

      ".bg-tablecell-gray": {
        borderLeft: "1px solid rgba(0, 0, 0, 0.12) !important",
      },

      ".card_border": {
        marginBottom: 30,
        border: "1px solid rgba(0, 0, 0, 0.12) !important",
      },

      ".text_align_right": {
        textAlign: "right",
      },

      ".form-control": {
        textAlign: "left",
        paddingTop: 29,
        position: "relative",
        display: "inline-flex",
        flexDirection: "column",
        verticalAlign: "top",
        width: "100%",
        "& .input-label": {
          position: "absolute",
          top: 0,
          left: 0,
          color: styles.inputsLabelColor,
          transform: "none",
          whiteSpace: "nowrap",
          fontSize: styles.inputsLabelFontSize,
          fontWeight: styles.inputsLabelFontWeight,
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "100%",
          lineHeight: 1.2,
          fontFamily: styles.body.fontFamily,
        },
      },
      ".iconTrueCont": { position: "relative" },
      ".iconTrue": { color: styles.chipColor, fontSize: 18 },
      ".iconFalse": { color: styles.tagColor, fontSize: 18 },

      ".iconAlert": {
        color: styles.colors.red,
        fontSize: 22,
        position: "relative",
        top: 8,
      },
      ".link-button:hover": {
        textDecoration: "none",
      },

      ".btonNeedsReview": {
        padding: "5px 15px 5px 15px",
      },
      ".btonSmall": {
        padding: "2px 3px 3px 2px",
        fontSize: "11px",
        width: "80px",
      },

      ".iconNeedsReview": {
        color: styles.chipColor,
        fontSize: 22,
      },

      ".fontSmall": {
        fontSize: 13,
      },

      ".days-of-week": {
        marginBottom: 8,

        "& .MuiChip-root": {
          marginRight: 8,
          padding: 4,
        },
        "& .MuiChip-label": { textTransform: "uppercase", fontSize: 16 },
      },

      ".pickers": {
        display: "inline-flex",
        marginTop: 3,

        "& .datepicker": {
          display: "inline-flex",
          width: "inherit",
          marginRight: 8,
          paddingTop: 0,
          marginBottom: 0,
          maxWidth: 235
        },
      },
      ".cardView_Tour": {
        "& .MuiGrid-container": {
          justifyContent: "space-between",
          alignItems: "center"
        },
        "& .MuiIconButton-root": {
          padding: "0 10px 0 13px"
        }
      },
      ".dateFromTimesItem": {
        position: "relative",
        "& .iconDelete": {
          position: "absolute",
          right: 5,
          top: '25px',
          bottom: 0,
          width: 40,
          height: 40,
          margin: "auto",
        },
      },

      ".tab-panel-availability": {
        "& .MuiCardContent-root": {
          position: "relative",

          "& .availabilityList-item": {
            width: "100%"
          }
        },
      },

      ".availabilityList-item": {
        borderLeft: "2px solid",
        borderColor: styles.colors.secondary,
        borderRadius: "12px !important",
        marginBottom: "16px",

        "& .MuiAccordionSummary-root": {
          minHeight: 61
        },

        "& .datepicker": {
          padding: 0,
          margin: "0 20px 0 0",
        },

        "& .actionButtons": {
          padding: "11px 0 10px 0"
        }
      },

      ".copyTimeModal": {
        "& .MuiChip-root": {
          margin: "4px 8px 4px 0",
          padding: 4,
          width: 95
        },

        "& .MuiChip-label": {
          textTransform: "uppercase",
          fontSize: 16
        },
      },

      ".availabilityList-item:last-child": {
        marginBottom: "4px !important"
      },

      ".availabilityList-item.MuiAccordion-root:before": {
        backgroundColor: "transparent"
      },

      ".hidden-tab": { display: "none" },

      ".iconToolip": {
        color: styles.chipColor,
        fontSize: 15,
      },
      ".MuiOutlinedInput-input.Mui-disabled": {
        textOverflow: "ellipsis",
      },

      ".PrivateNotchedOutline": {
        backgroundColor: "white",
        border: "1px solid",
        borderColor: styles.inputBorderColor,
        color: styles.inputColor,
      },

      ".multipleSelectContainer": {
        position: "relative",
      },
      ".multipleSelectInput": {
        position: "absolute",
        top: -40,
      },

      ".MuiAutocomplete-input": {
        "&::placeholder": {
          color: "#154153!important",
          fontSize: "16px!important",
          fontStyle: "normal!important",
        },
      },
      ".autocomplete-input": {
        "& .MuiInputBase-root": {
          padding: "0px 0px 0px 6px !important",

          "& .MuiInputBase-input": {
            width: 0,
            minWidth: 30,
            flexGrow: 1
          }
        }
      },
      ".formControlLabelText": {
        fontSize: "12px",
      },
      ".label-tag-Expernses": {
        backgroundColor: styles.chipBgColor,
        color: styles.chipColor,
        textAlign: "center",
        borderRadius: 16,
        display: "inline-block",
        padding: "4px 12px",
        fontSize: 12,
        maxWidth: 190,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        "&.status1": {
          backgroundColor: "#06C2C9",
          color: "white",
        },

        "&.status2": {
          backgroundColor: styles.tagColor,
        },
        "&.status3": {
          backgroundColor: "rgb(255 165 0 / 40%)",
          color: "black",
        },

        "&.statusOTHER": {
          backgroundColor: styles.tagColor,
        },
      },
      ".MuiTab-root": {
        maxWidth: "fit-content !important",

        "&.Mui-selected": {
          borderBottom: "2px solid",
          borderBottomColor: styles.colors.secondary,
        },
      },
      ".select-booking": {
        paddingTop: 0,
        marginBottom: 0,
      },
      ".select-booking-pay": {
        paddingTop: 0,
        marginBottom: 0,
        width: "230px",
      },
      ".dialog-ExensePreview": {
        width: "500px",
      },

      ".dialog_request_payouts": {
        "& .MuiTableCell-sizeSmall": {
          padding: "4px!important",
        },
      },

      ".paymentMethods": {
        paddingTop: "10px",
        justifyContent: "end",
        alignItems: "center",
      },
      ".table_dense": {
        "& .MuiTableCell-root": {
          padding: "4px 5px",
        },
      },
      ".row-active": {
        backgroundColor: styles.tableRowBgSelected,
        borderBottom: "none!important",
        borderLeft: "2px solid",
        borderLeftColor: styles.tableRowColorSelected,
      },
      ".MuiTableBody-root": {
        "& .MuiCollapse-root": {
          backgroundColor: styles.tableRowBgSelected,
          borderLeft: "2px solid",
          borderLeftColor: styles.tableRowColorSelected,
        },
      },

      ".MuiFocurrentSelect": {
        textAlign: "left",
        paddingTop: 50,
        marginBottom: 50,
      },
      ".list-condensed": {
        "& .MuiListItemText-dense": {
          margin: 0,
        },
      },
      ".MuiTableRow-hover": {
        "&.MuiTableRow-root.MuiGrid-container:hover": {
          backgroundColor: styles.tableRowBgSelected,
        },
      },

      ".iconFailed": {
        position: "absolute",
        top: "-10px",
        bottom: 0,
        fontSize: "15px",
        margin: "auto",
        color: "red",
      },
      ".boxIconFailed": {
        position: "relative",
      },
      ".statusGuideFinance": {
        backgroundColor: styles.chipBgColor,
        color: styles.chipColor,
        textAlign: "center",
        borderRadius: 16,
        display: "inline-block",
        padding: "4px 12px",
        fontSize: 12,
        maxWidth: 190,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        "&.lightViolet": {
          backgroundColor: styles.chipBgColor,
          color: styles.chipColor,
        },
        "&.violet": {
          backgroundColor: styles.tagColorPublished,
          color: "white",
        },
        "&.blue": {
          backgroundColor: "#154153",
          color: "white",
        },
        "&.red": {
          backgroundColor: "#ffeaea",
          color: "#ff4646",
        },
        "&.yellow": {
          backgroundColor: "rgb(255 165 0 / 40%)",
          color: "black",
        },
        "&.green": {
          backgroundColor: "#06C2C9",
          color: "white",
        },
        "&.gray": {
          backgroundColor: "#f7f7f7",
          color: "#154153",
        },
      },

      ".chipDate": {
        backgroundColor: styles.chipBgColor,
        color: styles.chipColor,
        textAlign: "center",
        borderRadius: 16,
        display: "inline-block",
        padding: "4px 12px",
        fontSize: 12,
        maxWidth: 310,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        "&.green": {
          backgroundColor: "#06C2C9",
          color: "white",
        },
      },
      ".modalLabel": {
        color: styles.inputsLabelColor,
        transform: "none",
        whiteSpace: "nowrap",
        fontSize: styles.inputsLabelFontSize,
        fontWeight: styles.inputsLabelFontWeight,
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
        lineHeight: 1.2,
        fontFamily: styles.body.fontFamily,
        paddingTop: 5,
        marginBottom: 5,
        "& .MuiFormHelperText-root": {
          margin: "5px 0",
        },
      },
      ".modalInput": {
        transform: "none",
        whiteSpace: "nowrap",
        fontSize: styles.inputsLabelFontWeight,
        fontWeight: styles.inputsLabelFontWeight,
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
        lineHeight: 1.2,
        fontFamily: styles.body.fontFamily,
        paddingTop: 5,
        marginBottom: 5,
        "& .MuiFormHelperText-root": {
          margin: "5px 0",
        },
      },
      ".titleInput": {
        color: "#086f73",
        fontSize: "12px",
        fontFamily: "Aktiv Grotesk R",
        fontWeight: "normal",
      },
      ".title": {
        color: "#086f73",
        fontSize: "12px",
        fontFamily: "Aktiv Grotesk R",
        fontWeight: "bold",
        paddingBottom: '10px'
      },
      ".labelTour": {
        color: "#086f73",
        fontSize: "14px",
        fontFamily: "Aktiv Grotesk R",
        fontWeight: "normal",
        paddingTop: "10px",
        marginBottom: "10px",
      },

      ".iconSizeMedium": {
        marginRight: "0px",
      },
      ".MuiButton-startIcon": {
        marginRight: "5px",
      },
      ".MuiBadge-anchorOriginTopRightRectangle": {
        top: "6px",
        right: "80px",
      },
      ".chipProfile": {
        margin: "16px",
      },
      ".MuiFormControl-marginNormal": {
        marginTop: "0px!important",
      },
      ".MuiSwitch-colorSecondary.Mui-checked": {
        color: "green!important",
      },
      ".MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#154153",
      },


      ".iconButton": {
        cursor: "pointer",
        color: "#424242",
        fontSize: "25px",
      },
      ".iconButtonSearch": {
        cursor: "pointer",
        color: "#154153",
        fontSize: "30px",
      },

      ".card-header-dowload": {
        "& .MuiCardHeader-title": { fontSize: "14px" },
      },

      ".includeToursConteiner": {
        position: "relative",
      },
      ".includeToursCheck": {
        position: "absolute",
        top: -55,
        right: 160,
      },
      ".stringDisabledBorder": {
        border: "2px solid",
        paddingLeft: 10,
        paddingTop:0,
        borderColor: "#FFE9E9",
        borderRadius: 4,
      },
      ".stringDisabled": {
        paddingLeft: 0,
        paddingTop:0,
        fontSize: "10px",
        borderColor: "rgba(21, 65, 83, 0)" ,
        border: 0
      },
      ".avatarPartner:hover": {
        cursor: "pointer",
        
      },
      ".avatarPartner": {     
        
        maxHeight: '150px',
      },

      ".avatarPartnerShow": {     
        maxHeight: '150px',
      },

      ".avatarPartnerList": {
        maxWidth: '100px',
        maxHeight: '50px'
      },

      ".productScopeTitle": {
        color: "#086f73",
        marginTop: 0
      },
      ".toursCard": {
        width: '680px',
       justifyContent: "space-between",
        padding: "2005px 50px",
        borderRadius: "4px",
        marginBottom: "10px",
        height: "50px",
        alignItems: "center",
        flexWrap: "nowrap",

        "& .MuiGrid-root": {
          display: "flex",
          alignItems: "center",

          "& .MuiCheckbox-root": {
            padding: "0 5px"
          }
        },

        "& .MuiGrid-root:nth-child(-n + 1)": {
          maxWidth: "calc(100% - 70px)",

          "& span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }
        },

        "& .label-tag": {
          marginLeft: 10,
          overflow: "initial"
        }
      },
      ".customFormControl": {
        padding: "0px",
        margin: "0px"
      },
      ".productScopeContainer": {
        padding: "10px",
        border: "1px solid #d5d5d5",
        borderRadius: "4px",
        height: "50vh",
        flexFlow: "column",
        width: '100%', 

        "& .destinationsTreeView": {
          width: "100%",
          overflow: "auto",

          "& .MuiTreeItem-content": {
            minHeight: 30,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          }
        },

        "& .productScopeFilter": {
          paddingTop:"0"
        },

        "& .MuiFormGroup-row": {
          justifyContent: "space-between"
        },

        "& .toursTable": {
          border: "1px solid #d5d5d5",
          borderRadius: "4px",
          padding: "10px 10px 0",
          overflow: "auto",
          height: "calc(100% - 95px)",

          "& .tourCard": {
            width: '680px',
           justifyContent: "space-between",
            padding: "25px 50px",
            borderRadius: "4px",
            marginBottom: "10px",
            height: "50px",
            alignItems: "center",
            flexWrap: "nowrap",

            "& .MuiGrid-root": {
              display: "flex",
              alignItems: "center",

              "& .MuiCheckbox-root": {
                padding: "0 5px"
              }
            },

            "& .MuiGrid-root:nth-child(-n + 1)": {
              maxWidth: "calc(100% - 70px)",

              "& span": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }
            },

            "& .label-tag": {
              marginLeft: 10,
              overflow: "initial"
            }
          }
        }
      },
      ".viewEmailModal": {
        "& .MuiDialog-paper": {
          minWidth: "40vw"
        }
      },
      ".progressBar": {
        justifyContent: "space-around",

        "& .status": {
          width: "20%",
          textAlign: "center",
          padding: "3px 0",
          marginTop: 3,
          border: "1px solid lightgray",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        },

        "& .status.statusSelected": {
          backgroundColor: styles.colors.secondary,
          color: "white"
        }
      },
      ".guideAssignment": {
        "& .assignmentInfo": {
          "& .title": {
            fontSize: 16,
            fontWeight: "bold"
          },
        },

        "& .subHeader": {
          alignItems: "center",

          "& .MuiFormControl-root": {
            padding: 0,
            margin: 0
          }
        },

        "& .MuiTableContainer-root": {
          maxHeight: "400px",

          "& .MuiTableHead-root": {
            position: "sticky",
            top: 0,
            zIndex: 2
          }
        }
      }
    },


  })(() => null);

  return (
    <ThemeProvider theme={theme}>
      <GlobalCSS />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
