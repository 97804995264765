import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";
import { saveAs } from "file-saver";

const apiUrl = process.env.REACT_APP_API_URL;

function* get_FinanceList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers
    };

    let filtersrUrl = apiUrl;

    if ((actions.page !== null)){
      filtersrUrl += "/bookings-expense-report?page=" + actions.page;
    }
    if(actions.url !== null){
      filtersrUrl += "/bookings-expense-report?" + actions.url ;
    }

    const response = yield call(
      apiCall,
      "GET",
      filtersrUrl,
      requestOptions
    );

    yield put({ type: "FINANCES_EXPENSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "FINANCES_EXPENSE_LIST", value: error });
  }
}

function* add_FinanceItem(actions) { 
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    yield call(
      apiCall,
      "PUT",
      apiUrl + "/booking-expenses/" + actions.itemId,
      requestOptions
    );

    yield put({ type: "EXPENSE_ITEM_UPDATED", value: "" });
  } catch (error) {
    yield put({ type: "EXPENSE_ITEM_UPDATED", value: error });
  }
}

function* revision_Item(actions) { 
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers
    };

    yield call(
      apiCall,
      "POST",
      apiUrl +
        "/bookings-expense-report/" +
        actions.expenseReportId +
        "/review",
      requestOptions
    );
  } catch (error) {

  }
}

function* get_PayoutRunTotal(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers
    };

    let response = [];
    response = yield call(
      apiCall,
      "GET",
      apiUrl + "/bookings-payout-run?status=OPEN",
      requestOptions
    );

    yield put({ type: "PAYOUT_RUN_TOTAL", value: response });
  } catch (error) {
    yield put({ type: "PAYOUT_RUN_TOTAL", value: error });
  }
}

function* get_PayoutRunList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers
    };

    const page = actions.page || 1;
    const url =
      actions.query === null
        ? apiUrl + `/bookings-payout-run-requests?payoutRunId=${actions.id}&included=true&page=${page}`
        : apiUrl + "/bookings-payout-run-requests?" + actions.query;
    
    
      const response = yield call(
      apiCall,
      "GET",
      url,
      requestOptions
    );

    yield put({ type: "PAYOUT_RUN_LIST", value: response });
  } catch (error) {
    yield put({ type: "PAYOUT_RUN_LIST", value: error });
  }
}

function* get_PayoutRunExcludedList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers
    };

    const page = actions.page || 1;
    const url =
      actions.query === null
        ? apiUrl + `/bookings-payout-run-requests?payoutRunId=${actions.id}&included=false&page=${page}`
        : apiUrl + "/bookings-payout-run-requests?" + actions.query;
    const response = yield call(
      apiCall,
      "GET",
      url,
      requestOptions
    );

    yield put({ type: "PAYOUT_RUN_EXCLUDED_LIST", value: response });
  } catch (error) {
    yield put({ type: "PAYOUT_RUN_EXCLUDED_LIST", value: error });
  }
}

function* change_Date(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.date)
    };
    yield call(
      apiCall,
      "PUT",
      apiUrl + "/bookings-payout-run/" + actions.id,
      requestOptions
    );
  } catch (error) {

  }
}

function* execute_PayoutRun(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

   const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/bookings-payout-run/" + actions.payoutRunId + "/execute",
      requestOptions
    );

    yield put({ type: "EXECUTE_STATE", value: response });

  } catch (error) {
    yield put({ type: "EXECUTE_STATE", value: error });
  }
}
function* get_bookingsPayoutRunList(actions) {
  try {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/bookings-payout-run?page=" + actions.page
        : apiUrl + "//bookings-payout-run?" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "BOOKING_PAYOUT_RUN_LIST", value: response });
  } catch (error) {
    yield put({ type: "BOOKING_PAYOUT_RUN_LIST", value: error });
  }
}

function* get_bookingsPayoutRunDetail(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "GET",
      apiUrl +
        "/bookings-payout-run-requests?payoutRunId=" +
        actions.payoutRunId +
        "&included=true&pagination=false",
      requestOptions
    );

    yield put({ type: "BOOKING_PAYOUT_RUN_DETAIL", value: response });
  } catch (error) {
    yield put({ type: "BOOKING_PAYOUT_RUN_DETAIL", value: error });
  }
}

function* retry_bookingsPayoutRun(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "post",
      apiUrl + "/bookings-payout-run/" + actions.bookingId + "/reschedule/" + actions.payoutRunId,
      requestOptions
    );

    yield put({ type: "RETRY_BOOKING_PAYOUT_RUN", value: response });
  } catch (error) {
    yield put({ type: "RETRY_BOOKING_PAYOUT_RUN", value: error });
  }
}

function* get_DownloadSummary(actions) {

  try {
    const headers = {
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield fetch(
      apiUrl +  "/bookings-payout-run/" +
      actions.payoutRunId + '/export/summary',
      requestOptions
    ).then(response => response.blob());

    saveAs(response, 'payout_run_export_summary.csv');
  } catch (error) {

  }
}

function* get_DownloadFullList(actions) {

  try {
    const headers = {
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield fetch(
      apiUrl + "/bookings-payout-run/" +
      actions.payoutRunId + '/export/full',
      requestOptions
    ).then(response => response.blob());

    saveAs(response, 'payout_run_export_full.csv');
  } catch (error) {

  }
}

function* put_PayoutRunExcludedList(actions) {

  try {
    let bodyRequest = {};
    bodyRequest['included'] = actions.included

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.cookie}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(bodyRequest)
    };

    let requestCode =  (actions.shortCode !== null
      ? "/bookings-payout-run/"+ actions.payoutRunId +"/update?guideEmail="+ actions.guideMail + "&shortCode=" + actions.shortCode
      : "/bookings-payout-run/"+ actions.payoutRunId +"/update?guideEmail="+ actions.guideMail
    )

    const response = yield call(
      apiCall,
      "PUT",
      apiUrl +  requestCode ,
      requestOptions
    );
    yield put({ type: "RELOAD_LISTING", value: response });
  } catch (error) {
    yield put({ type: "RELOAD_LISTING", value: error });
  }
}


function* download_PayoutRunFile(actions) {

  try {
    const headers = {
      Authorization: `Bearer ${actions.cookie}`
    };

    const requestOptions = {
      headers: headers
    };
  
    const response = yield fetch(
      apiUrl +  "/bookings-payout-run/" +
      actions.id + '/export/' + actions.mode,
      requestOptions
    ).then(response => response.blob());

 
    saveAs(response, 'payout_run_' + actions.id + '_export_' + actions.mode + '.csv');
  } catch (error) {

  }
}


export function* getFinanceList() {
  yield takeEvery("GET_FINANCE_LIST", get_FinanceList);
}

export function* addFinanceItem() {
  yield takeEvery("ADD_FINANCE_ITEM", add_FinanceItem);
}

export function* revisionItem() {
  yield takeEvery("REVISIONITEM", revision_Item);
}

export function* getPayoutRunTotal() {
  yield takeEvery("GET_PAYOUT_RUN_TOTAL", get_PayoutRunTotal);
}

export function* getPayoutRunList() {
  yield takeEvery("GET_PAYOUT_RUN_LIST", get_PayoutRunList);
}

export function* changeDate() {
  yield takeEvery("CHANGE_DATE", change_Date);
}

export function* executePayoutRun() {
  yield takeEvery("EXECUTE_PAYOUT_RUN", execute_PayoutRun);
}

export function* getbookingsPayoutRunList() {
  yield takeEvery("GET_BOOKINGPAYOUTRUN_LIST", get_bookingsPayoutRunList);
}

export function* getbookingsPayoutRunDetail() {
  yield takeEvery("GET_BOOKINGPAYOUTRUN_DETAIL", get_bookingsPayoutRunDetail);
}

export function* retrybookingPayoutRun() {
  yield takeEvery("RETRY_BOOKINGPAYOUTRUN", retry_bookingsPayoutRun);
}

export function* getDownloadSummary() {
  yield takeEvery("GET_DOWNLOAD_SUMMARY", get_DownloadSummary);
}
export function* getDownloadFullList() {
  yield takeEvery("GET_DOWNLOAD_FULL_LIST", get_DownloadFullList);
}

export function* getPayoutRunExcludedList() {
  yield takeEvery("GET_PAYOUT_RUN_EXCLUDED_LIST", get_PayoutRunExcludedList);
}

export function* putPayoutRunExcludedList() {
  yield takeEvery("PUT_PAYOUT_RUN_EXCLUDED_LIST", put_PayoutRunExcludedList);
}

export function* downloadPayoutRunFile() {
  yield takeEvery("DOWNLOAD_PAYOUTRUN_FILE", download_PayoutRunFile);
}




