import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export const getLogin = async (request) => {
	const headers = {
		'Content-Type': "application/json"
	};
	const url = apiUrl + "/authenticate-login";
	const response = await axios.post(url, JSON.stringify(request), { headers: headers })
		.then(res => {

			if (res.headers.authorization !== undefined) {
				let data = [];
				data["userAuthenticated"] = true;
				data["location"]          = res.headers.location;
				data["headersSetCookie"]  = res.headers.authorization;
				return data;
			}

			return res.data;
		})
		.catch((err) => {
			let data = [];
			data["userAuthenticated"] 	= false;
			data["error"] 				= true;
			data["loginInProcess"] 		= false;
			data["errorCode"]			= "API General Error";
			return data;
		});

	return response;
}

export const forgot = async (request) => {
	const headers = {
		'Content-Type': "application/json"
	};
	const url = apiUrl + "/recover-password";
	const response = await axios.post(url, JSON.stringify(request), { headers: headers })
		.then(res => {

			return res.data;
		})
		.catch((err) => {

			return false;
		});
	return response;
}

export const reset = async (request) => {
	const headers = {
		'Content-Type': "application/json"
	};
	const url = apiUrl + "/update-password";
	const response = await axios.post(url, JSON.stringify(request), { headers: headers })
		.then(res => {

			return res.data;
		})
		.catch((err) => {

			return false;
		});
	return response;
}

