const initialState = {
  resultAttraction: {},
  resultsAttractionsList: [],
  resultsCommentsListAttractions: [],
  resultsLoggingsListAttractions: [],
  resultsAuditsListAttractions: [],
  resultsDestinations: [],
  resultsAvailabilityListAttractions: [],
  preload: false,
  resultAttractionsError: null,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "LOGGINGS_RESPONSE_LIST_ATTRACTIONS":
      newState.resultsLoggingsListAttractions = action.value;
      newState.preload = false;
      break;
    case "AUDIT_RESPONSE_LIST_ATTRACTIONS":
      newState.resultsAuditsListAttractions = action.value;
      newState.preload = false;
      break;
    case "AUDIT_RESPONSE_POST_ATTRACTIONS":
      newState.preload = false;
      break;
    case "COMMENTS_RESPONSE_LIST_ATTRACTION":
      newState.resultsCommentsListAttractions = action.value;
      newState.preload = false;
      break;
    case "COMMENTS_RESPONSE_POST_ATTRACTION":
      newState.preload = false;
      break;
    case "ATTRACTION_RESPONSE":
      newState.resultAttraction = action.value;
      newState.preload = false;
      break;
    case "ATTACTIONS_RESPONSE_LIST":
      newState.resultsAttractionsList = action.value;
      newState.preload = false;
      break;
    case "ATTRACTIONS_RESPONSE_CREATE":
      newState.preload = false;
      newState.back = true;
      break;
    case "ATTRACTIONS_RESPONSE_EDIT":
      newState.preload = false;
      newState.back = true;

      if (action.value[0]['@context'] === '/contexts/Error'){
        newState.resultAttractionsError = action.value[0];
      }else{
          newState.resultAttractionsError = null
      }


      
      break;
    case "DESTINATION_RESPONSE_LIST":
      newState.resultsDestinations = action.value;
      break;
    case "AVAILABILITY_RESPONSE_LIST_ATTRACTION":
      newState.resultsAvailabilityListAttractions = action.value;
      newState.preload = false;
      break;
    default:
      break;
  }
  return newState;
};
export default reducer;
