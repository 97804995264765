const initialState = {
  resultsFinanceExpenseList: [],
  resultsFinancePayoutRunTotal: [],
  preload: false,
  reloadExpensesList: false,
  payoutRunTotalStatus: false,
  resultsCurrentPayoutRunList: [],
  resultsBookingPayoutRunList: [],
  resultsBookingPayoutRunDetail: [],
  resultExecute: null,
  resultsPayoutRunExcludedList:[],
  preloadPayoutRun: false,
  preloadExecute: false,
  preloadReload: false,
  preloadList: false,
  updateData: false,
  payoutRunRequestsLoaded: false,
  resultRetryPayoutRun: null,
  reloadAlert: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "PRELOAD":
      newState.preloadList = true;
      newState.reloadAlert = false;
      break;

    case "BOOKING_PAYOUT_RUN_DETAIL":
      newState.resultsBookingPayoutRunDetail = action.value;
      newState.preload = true;
      newState.reloadAlert = false;
      break;

    case "RETRY_BOOKING_PAYOUT_RUN":
      newState.resultRetryPayoutRun = action.value;
      newState.reloadAlert = true;
      newState.preload = false;
      break;

    case "BOOKING_PAYOUT_RUN_LIST":
      newState.resultsBookingPayoutRunList = action.value;
      newState.preload = true;
      newState.reloadAlert = false;
      break;

    case "FINANCES_EXPENSE_LIST":
      newState.resultsFinanceExpenseList = action.value;
      newState.reloadExpensesList = false;
      newState.preload = true;
      newState.preloadList = false;
      newState.reloadAlert = false;
      break;

    case "EXECUTE_STATE":
      newState.resultExecute = action.value;
      newState.preloadExecute = true;
      newState.reloadAlert = false;
      break;

    case "PAYOUT_RUN_TOTAL":
      newState.resultsFinancePayoutRunTotal = action.value;
      newState.preload = true;
      newState.payoutRunTotalStatus = true;
      newState.payoutRunRequestsLoaded = false;
      newState.reloadAlert = false;
      break;

    case "PAYOUT_RUN_LIST":
      newState.resultsCurrentPayoutRunList = action.value;
      newState.preload = true;
      newState.preloadPayoutRun = true;
      newState.payoutRunRequestsLoaded = true;
      newState.reloadAlert = false;
      break;

    case "PAYOUT_RUN_EXCLUDED_LIST":
      newState.resultsPayoutRunExcludedList = action.value;
      newState.preload = true;
      newState.payoutRunTotalStatus = false;
      newState.preloadExecute = false;
      newState.payoutRunRequestsLoaded = true;
      newState.reloadAlert = false;
      newState.preloadReload = false;
      break;

    case "RELOAD_LISTING":
      newState.preloadExecute = true;
      newState.payoutRunRequestsLoaded = false;
      newState.reloadAlert = false;
      break;

    case "EXPENSE_ITEM_UPDATED":
      newState.reloadExpensesList = true;
      break;
    default:
      break;
  }

  return newState;
};

export default reducer;
