import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";
const apiUrl = process.env.REACT_APP_API_URL;

function* fulfillmentDashboardList(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/tour-bookings-tasks?page=" + actions.page
        : apiUrl + "/tour-bookings-tasks?" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "FULFILLMENT_DASHBOARD_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "FULFILLMENT_DASHBOARD_RESPONSE_LIST", value: error });
  }
}

export function* getFulfillmentDashboardList() {
  yield takeEvery("GET_FULFILLMENT_DASHBOARD_LIST", fulfillmentDashboardList);
}
