import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";
import { saveAs } from "file-saver";

const apiUrl = process.env.REACT_APP_API_URL;

function* tourBookings(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/tour-bookings?partnerType=NotBCHT&page=" + actions.page
        : apiUrl + "/tour-bookings?" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "TOUR_BOOKINGS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "TOUR_BOOKINGS_RESPONSE_LIST", value: error });
  }
}

function* tourBooking(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers
    };

    let url =
      actions.query === null
        ? apiUrl + "/tour-bookings/"
        : apiUrl + "/tour-bookings/" + actions.query;

    const response = yield call(apiCall, "get", url, requestOptions);

    yield put({ type: "TOUR_BOOKINGS_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "TOUR_BOOKINGS_RESPONSE", value: error });
  }
}

function* add(actions) {
  try {
    const headers = {
       "Content-Type": "application/json",
       Authorization: `Bearer ${actions.access}`
      };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/tours-bookings-create",
      requestOptions
    );

    /*
    const response = {
      "status": true,
      "bookingId": 19,
      "message": "An error occurred"
    }*/

    yield put({ type: "TOUR_BOOKINGS_RESPONSE_CREATE", value: response });
  } catch (error) {
    yield put({ type: "TOUR_BOOKINGS_RESPONSE_CREATE", value: error });
  }
}

function* edit(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "put",
      apiUrl + "/tour-bookings/" + actions.id,
      requestOptions
    );
    yield put({ type: "TOUR_BOOKINGS_RESPONSE_EDIT", value: response });
  } catch (error) {
    yield put({ type: "TOUR_BOOKINGS_RESPONSE_EDIT", value: error });
  }
}

function* upload(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: actions.data
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/tour-bookings/attachment",
      requestOptions
    );

    yield put({ type: "TOUR_BOOKINGS_FILE_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "TOUR_BOOKINGS_FILE_RESPONSE", value: error });
  }
}

function* download(actions) {
  try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${actions.access}`
      };

      const requestOptions = {
        headers: headers
      };

      const response = yield fetch(
        apiUrl + "/bookings/" + actions.tourBookingId + "/tickets/" + actions.ticketId,
        requestOptions
      ).then(response => response.blob());

      saveAs(response, actions.name);
  } catch (error) {}
}

function* download_bookings(actions) {

  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield fetch(
      apiUrl +  "/tour-bookings-export?" + actions.query.join('&'),

      requestOptions
    ).then(response => response.blob());

    saveAs(response, actions.nameDocument + '.xlsx');
  } catch (error) {

  }
}

function* getMessages(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/tour-bookings-messages/" + actions.id,
      requestOptions
    );

    yield put({ type: "TOUR_BOOKINGS_MESSAGES_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "TOUR_BOOKINGS_MESSAGES_RESPONSE_LIST", value: error });
  }
}

function* getMessageContent(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "post",
      apiUrl + "/tour-bookings-message-content",
      requestOptions
    );

    yield put({ type: "TOUR_BOOKINGS_MESSAGE_CONTENT_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "TOUR_BOOKINGS_MESSAGE_CONTENT_RESPONSE", value: error });
  }
}

function* sendMessage(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };
    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "post",
      apiUrl + "/tour-bookings-message-send",
      requestOptions
    );

    yield put({ type: "SEND_TOUR_BOOKINGS_MESSAGE_RESPONSE", value: response });
  } catch (error) {
    yield put({ type: "SEND_TOUR_BOOKINGS_MESSAGE_RESPONSE", value: error });
  }
}

function* getTours(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const url =
      actions.query === null
        ? apiUrl + "/tours"
        : apiUrl + "/tours?" + actions.query+'&partnerId='+actions.partnerId;

    const response = yield call(
      apiCall,
      "get",
      url,
      requestOptions
    );

    yield put({ type: "TOURS_TOUR_BOOKINGS_RESPONSE_LIST", value: response, typeList: actions.typeList });
  } catch (error) {
    yield put({ type: "TOURS_TOUR_BOOKINGS_RESPONSE_LIST", value: error, typeList: actions.typeList });
  }
}

function* getPartners(actions) {
  try {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/autocomplete/partners",
      requestOptions
    );
    
    

    yield put({ type: "PARTNERS_TOUR_BOOKINGS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "PARTNERS_TOUR_BOOKINGS_RESPONSE_LIST", value: error });
  }
}

function* getCandidates(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/tour-bookings-candidates/" + actions.bookingId,
      requestOptions
    );

    yield put({ type: "GUIDE_CANDIDATES_TOUR_BOOKINGS_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "GUIDE_CANDIDATES_TOUR_BOOKINGS_RESPONSE_LIST", value: error });
  }
}

function* get_ToursRate(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

   let serviceUrl = apiUrl + "/tours-rate?partnerId=" + actions.data.partnerId
   + "&tourId=" + actions.data.tourId
   + "&adults=" + actions.data.adults
   + "&children=" + actions.data.children
   + "&date=" + actions.data.date
   + "&time=" + actions.data.time;

   if (actions.data.currency !== undefined) {
    serviceUrl = serviceUrl + "&currency=" + actions.data.currency;
   } else {
    serviceUrl = serviceUrl + "&quoteUsingCityCurrency=true"
   }

   const response = yield call(
      apiCall,
      "get",
      serviceUrl,
      requestOptions
    );

    yield put({ type: "TOUR_RATE_DATA", value: response });
  } catch (error) {
    yield put({ type: "TOUR_RATE_DATA", value: error });
  }
}

function* history(actions) {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };
    const response = yield call(
      apiCall,
      "get",
      apiUrl +
        "/changelogs?objectType=" +
        actions.objectType +
        "&objectId=" +
        actions.objectId,
      requestOptions
    );

    yield put({ type: "LOGGINGS_RESPONSE_LIST_BOOKING", value: response });
  } catch (error) {
    yield put({ type: "LOGGINGS_RESPONSE_LIST_BOOKING", value: error });
  }
}


function* partners(actions) {
  try {
   
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers
    };

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/autocomplete/partners",
      requestOptions
    );
    

    yield put({ type: "PARTNERS_BOOKING_RESPONSE_LIST", value: response });
  } catch (error) {
    yield put({ type: "PARTNERS_BOOKING_RESPONSE_LIST", value: error });
  }
}

export function* getTourBooking() {
  yield takeEvery("GET_TOUR_BOOKING", tourBooking);
}

export function* getTourBookingsList() {
  yield takeEvery("GET_TOUR_BOOKINGS", tourBookings);
}
export function* getPartnersbooking() {
  yield takeEvery("GET_PARTNERS_BOOKING", partners);
}


export function* addTourBooking() {
  yield takeEvery("ADD_TOUR_BOOKING", add);
}

export function* editTourBooking() {
  yield takeEvery("EDIT_TOUR_BOOKING", edit);
}

export function* uploadFile() {
  yield takeEvery("UPLOAD_FILE_TOUR_BOOKING", upload);
}

export function* downloadFile() {
  yield takeEvery("DOWNLOAD_FILE_TOUR_BOOKING", download);
}

export function* downloadBookings() {
  yield takeEvery("DOWNLOAD_BOOKINGS", download_bookings);
}

export function* getTourBookingMessages() {
  yield takeEvery("GET_TOUR_BOOKING_MESSAGES", getMessages);
}

export function* getTourBookingMessageContent() {
  yield takeEvery("GET_TOUR_BOOKING_MESSAGE_CONTENT", getMessageContent);
}

export function* sendTourBookingMessage() {
  yield takeEvery("SEND_TOUR_BOOKING_MESSAGE", sendMessage);
}

export function* getToursTourBooking() {
  yield takeEvery("GET_TOURS_TOUR_BOOKING", getTours);
}

export function* getPartnerTourBooking() {
  yield takeEvery("GET_PARTNER_TOUR_BOOKING", getPartners);
}

export function* getTourBookingsCandidates() {
  yield takeEvery("GET_TOUR_BOOKINGS_CANDIDATES", getCandidates);
}

export function* getToursRate() {
  yield takeEvery("GET_TOUR_RATE", get_ToursRate);
}

export function* getBookingHistoryList() {
  yield takeEvery("GET_BOOKING_HISTORY_LIST", history);
}




