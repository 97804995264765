import { takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services";

const apiUrl = process.env.REACT_APP_API_URL;

const galleryResponse = [
  {
      "id": 1,
      "url": "https://res.cloudinary.com/touriocity/image/upload/v1604506018/tu0o6eopitfmwjw60n65.jpg",
      "filetype": "image/jpeg",
      "width": 1589,
      "height": 612,
      "creditText": "Owner",
      "creditUrl": "https://www.owner.com",
  },
  {
      "id": 2,
      "url": "https://res.cloudinary.com/touriocity/image/upload/v1604505857/wwkvc1llqw7dwmbsv1yy.jpg",
      "filetype": "image/jpeg",
      "width": 910,
      "height": 512,
      "creditText": "Owner",
      "creditUrl": "https://www.owner.com",
  },
  {
      "id": 3,
      "url": "https://res.cloudinary.com/touriocity/image/upload/v1456503270/dznyxepam1mh1w2grc2e.jpg",
      "filetype": "image/jpeg",
      "width": 1500,
      "height": 410,
      "creditText": "Owner",
      "creditUrl": "https://www.owner.com",
  }
]

function* get_Gallery(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = { headers: headers };

    const filter = actions.filter ? `?url=${actions.filter}` : "";

    const response = yield call(
      apiCall,
      "get",
      apiUrl + "/gallery" + filter,
      requestOptions
    );

    yield put({ type: "RESPONSE_GALLERY_LIST", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_GALLERY_LIST", value: [] });
  }
}

function* add_Multimedia_Gallery(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: actions.data
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/gallery",
      requestOptions
    );

    yield put({ type: "RESPONSE_ADD_MULTIMEDIA_GALLERY", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_ADD_MULTIMEDIA_GALLERY", value: error });
  }
}

function* add_Multimedia(actions) {
  try {
    const headers = {
      Authorization: `Bearer ${actions.access}`
    };

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(actions.data)
    };

    const response = yield call(
      apiCall,
      "POST",
      apiUrl + "/gallery/variant",
      requestOptions
    );

    yield put({ type: "RESPONSE_MULTIMEDIA", value: response });
  } catch (error) {
    yield put({ type: "RESPONSE_MULTIMEDIA", value: error });
  }
}

export function* getGallery() {
  yield takeEvery("GET_GALLERY", get_Gallery);
}

export function* addMultimediaGallery() {
  yield takeEvery("ADD_MULTIMEDIA_GALLERY", add_Multimedia_Gallery);
}

export function* addMultimedia() {
  yield takeEvery("ADD_MULTIMEDIA", add_Multimedia);
}