import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Dashboard from "./components/views/admin/Dashboard";
import DashboardCardExpanded from "./components/views/admin/DashboardCardExpanded";
import DashboardCardViews from "./components/views/admin/DashboardCardViews";

import Main from "./components/layouts/Main";
import MainAdmin from "./components/layouts/MainAdmin";
import MainLogin from "./components/layouts/MainLogin";

import Login from "./components/views/login/Login";
import Forgot from "./components/views/login/Forgot";
import Reset from "./components/views/login/Reset";

class Routing extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" component={Main} exact />
          <Route path="/dashboard/">
            <MainAdmin>
              <Switch>
                <Route path="/dashboard/" exact component={Dashboard} />
                <Route
                  path="/dashboard/:card"
                  exact
                  component={DashboardCardExpanded}
                />
                <Route
                  path="/dashboard/:card/:views/:id"
                  exact
                  component={DashboardCardViews}
                />
                <Route
                  path="/dashboard/:card/:views"
                  exact
                  component={DashboardCardViews}
                />
              </Switch>
            </MainAdmin>
          </Route>
          <Route>
            <MainLogin>
              <Switch>
                <Route path="/login" component={Login} exact />
                <Route path="/recovery" component={Forgot} exact />
                <Route
                  path={"/change-password/:hash"}
                  component={Reset}
                  exact
                />
              </Switch>
            </MainLogin>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Routing;
